import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { Slider, SliderValue } from "@nextui-org/react";
import React from "react";

interface RealTimeSliderProps {
  value: SliderValue;
  setValue: (newValue: SliderValue) => void;
  title: string;
  min: number;
  max: number;
  step?: number;
}

const RealTimeSlider = ({ value, setValue, title, min, max, step = 0.01 }: RealTimeSliderProps) => {
  return (
    <H1_FlexRow flex="0 0 auto">
      <H1_FlexRow flex="0 0 87px">
        <H1_TextMiddle color="white">{title}</H1_TextMiddle>
      </H1_FlexRow>
      <H1_FlexRow flex="0 0 240px">
        <Slider maxValue={max} minValue={min} step={step} value={value} onChange={setValue} />
      </H1_FlexRow>
      <H1_FlexRow flex="0 0 62px" justify="flex-end">
        <H1_TextMiddle color="white">{value}</H1_TextMiddle>
      </H1_FlexRow>
    </H1_FlexRow>
  );
};

export default RealTimeSlider;
