import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { realTimeActions } from "app/store/slices/realtime.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { LiveKitRoom as LiveKitRoomContainer, RoomAudioRenderer } from "@livekit/components-react";
import PlaygroundRealTimeActiveRoom from "app/pages/PlayGround/realTime/PlaygroundRealTimeActiveRoom";

const PlaygroundRealTime = ({ useBeta }: { useBeta: boolean }) => {
  const dispatch = useAppDispatch();

  const getSessionStatus = useAppSelector((state) => state.realTime.getSessionStatus);
  const token = useAppSelector((state) => state.realTime.token);
  const livekitUrl = useAppSelector((state) => state.realTime.livekitUrl);

  useEffect(() => {
    dispatch(
      realTimeActions.getSessionRequest({
        useBeta,
        participantName: "Myself",
        roomName: "HourOne-" + Date.now()
      })
    );
  }, []);
  useEffect(() => {
    if (getSessionStatus === fetchingStatus.succeeded) {
      dispatch(realTimeActions.updateGetSessionStatusToIdle());
    }
    if (getSessionStatus === fetchingStatus.failed) {
      dispatch(realTimeActions.updateGetSessionStatusToIdle());
    }
  }, [getSessionStatus]);

  return (
    <H1_FlexColumn flex="1 0 auto">
      {getSessionStatus === fetchingStatus.loading && <CircleLoader />}
      {!!token && (
        /* @ts-ignore - weird error */
        <LiveKitRoomContainer
          token={token as string}
          serverUrl={livekitUrl as string}
          connectOptions={{ autoSubscribe: true }}
        >
          <PlaygroundRealTimeActiveRoom useBeta={useBeta} />
          <RoomAudioRenderer />
        </LiveKitRoomContainer>
      )}
    </H1_FlexColumn>
  );
};

export default PlaygroundRealTime;
