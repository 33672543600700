import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ChannelsTabs, LiveStream } from "app/types";
import { SwiperSlide } from "swiper/react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { getTHumbnail } from "app/pages/CGN/utils";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { ThemeMode } from "app/utils/theme";
import { useAppSelector } from "app/hooks";
import useSmallScreen from "app/hooks/useSmallScreen";

const ThumbnailImage = styled.img`
  border-radius: 15px;
  width: calc(180px * 16 / 9);
  height: 175px;
  transition: all 0.3s ease-in-out;
  filter: brightness(90%);
  &:hover {
    cursor: pointer;
    filter: brightness(120%);
  }
`;

const ViewersRow = styled(H1_FlexRow)`
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #3b3b3bcc;
`;

const LiveFlexRow = styled(H1_FlexRow)`
  border-radius: 14px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  top: 10px;
  left: 10px;
  padding: 0 10px;
  opacity: 0.7;
`;

const RedIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f31260;
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  padding-bottom: 5px;
  height: 200px;
  margin: 0 30px;
  .swiper-slide {
    width: calc(180px * 16 / 9);
  }
  .swiper-button-prev,
  .swiper-button-next {
    justify-content: center;
    top: 94px;
  }
  && .swiper-button-prev {
    left: 30px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }

  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }
`;

const CategoryHeaderMiddle = styled(H1_TextMiddle)`
  font-weight: 500;
  color: #e5e5e5;
`;

const AnimatedFlexColumn = styled(H1_FlexColumn)<{ isHidden?: boolean }>`
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: ${(props) => (props.isHidden ? "translateY(230px)" : "translateY(0)")};
`;

const SwiperContainerFlexColumn = styled(H1_FlexColumn)`
  position: relative;
  &:hover {
    ${ViewersRow} {
      opacity: 1;
      z-index: 2;
    }
  }
`;

interface CGNStreamsRowProps {
  title: string;
  name: ChannelsTabs;
  onPlayVideo: (channel: ChannelsTabs, id: string) => void;
  isHidden?: boolean;
}
const CGNStreamsRow = ({ isHidden, title, name, onPlayVideo }: CGNStreamsRowProps) => {
  const { isSmallScreen } = useSmallScreen();
  const theme = useTheme();
  const channels = useAppSelector((state) => state.cgn.channels);
  const streamersVod = useAppSelector((state) => state.cgn.streamersVod);
  const streams = useMemo(() => [...channels, ...streamersVod], [channels, streamersVod]);

  const filteredStreams = useMemo(
    () => streams.filter((stream) => stream.name === name),
    [name, streams]
  );

  return (
    <AnimatedFlexColumn flex="0 0 auto" isHidden={isHidden}>
      <H1_FlexColumn flex="0 0 auto" padding="20px 0">
        <H1_FlexRow position="relative" margin="0 0 20px 30px">
          <CategoryHeaderMiddle fontSize={isSmallScreen ? "18px" : "1.4vw"}>
            {title}
          </CategoryHeaderMiddle>
        </H1_FlexRow>
        <H1_FlexRow position="relative" padding="0 20px 0 0" width="100%" flex="1 0 auto">
          <StyledSwiper
            allowTouchMove={false}
            navigation
            speed={700}
            spaceBetween={28}
            slidesPerView="auto"
            slidesPerGroup={isSmallScreen ? 1 : 4}
            lazy
          >
            {filteredStreams.map((stream: LiveStream) => (
              <SwiperSlide key={stream.id || stream.playback_id}>
                <SwiperContainerFlexColumn gap="5px">
                  <ConditionalRender condition={!!stream.thumbnail}>
                    <ThumbnailImage
                      src={stream.thumbnail}
                      onClick={() => onPlayVideo(stream.name as ChannelsTabs, stream.id as string)}
                    ></ThumbnailImage>
                  </ConditionalRender>
                  <ConditionalRender condition={!stream.thumbnail}>
                    <H1_FlexRow position="relative">
                      <ThumbnailImage
                        onClick={() =>
                          onPlayVideo(stream.name as ChannelsTabs, stream.id as string)
                        }
                        src={getTHumbnail(stream)}
                      />
                      <LiveFlexRow height="28px" position="absolute" gap="10px" align="center">
                        <RedIcon />
                        <H1_TextSmall fontSize="14px" color="white">
                          LIVE
                        </H1_TextSmall>
                      </LiveFlexRow>
                    </H1_FlexRow>
                  </ConditionalRender>
                  <ViewersRow>
                    <ConditionalRender condition={!!stream?.viewers}>
                      <H1_TextSmall color="white">{stream?.viewers} viewers</H1_TextSmall>
                    </ConditionalRender>
                  </ViewersRow>
                  <H1_TextSmall color={theme.gray11}>{stream?.title}</H1_TextSmall>
                </SwiperContainerFlexColumn>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </H1_FlexRow>
      </H1_FlexColumn>
    </AnimatedFlexColumn>
  );
};

export default CGNStreamsRow;
