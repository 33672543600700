import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextLargeHeadline,
  H1_TextMiddle
} from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { homePageMessages } from "app/pages/HomePage/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import { fetchingStatus } from "app/utils/helpers";
import React from "react";
import { FeatureFlag, FlatRecipe, Location, UseCaseSegmentType, WorkspaceType } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import { getAllWebcamCharacters } from "app/store/selectorsV2/character.selectors";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { useFlags } from "launchdarkly-react-client-sdk";
import useIsFirstVideoCreated from "app/hooks/useIsFirstVideoCreated";
import HomePageTopSectionSales from "app/pages/HomePage/HomePageTopSectionSales";
import HomePageWorkflowSection from "app/pages/HomePage/HomePageWorkflowSection";
import HomePageTopSectionDevelopers from "app/pages/HomePage/HomePageTopSectionDevelopers";
import HomePageTopSectionTraining from "app/pages/HomePage/HomePageTopSectionTraining";
import HomePageTopSectionFTU from "app/pages/HomePage/HomePageTopSectionFTU";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { Button } from "@nextui-org/react";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import uiActions from "app/store/actions/ui.actions";
import { draftsActions } from "app/store/slices/drafts.slice";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { useNavigate } from "react-router-dom";

const IconicGradientSectionFlexColumn = styled(H1_FlexColumn)`
  min-width: fit-content;
  max-height: 250px;
  background: linear-gradient(236deg, #1c1c1c 0%, #5b5b91 100%);
  border-radius: 10px;
  img {
    flex: 0 0 auto;
    height: 190px;
    max-width: calc(190px * 16 / 9);
    border-radius: 10px;
  }
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      font-size: 16px;
    }
  }
`;

const ContainerFlexRow = styled(H1_FlexRow)`
  border-radius: 14px;
  min-height: 262px;
`;
const BannerFlexColumn = styled(H1_FlexColumn)`
  border-radius: 14px;
  span {
    position: relative;
    z-index: 2;
    max-width: 525px;
  }
`;

const GetStartedButton = styled(Button)`
  width: 160px;
  border-radius: 14px;
  border: 1px #575757;
  position: absolute;
  bottom: 20px;
  background: linear-gradient(
    98deg,
    rgba(255, 255, 255, 0.16) -12.72%,
    rgba(255, 255, 255, 0.03) 100.26%
  );
  color: white;
`;

const GradientBackground2 = styled(H1_FlexRow)`
  --tw-gradient-from: rgb(13 13 13 / 1);
  --tw-gradient-to: rgb(13 13 13 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(0 0 0 / 0);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  position: absolute;
  height: 262px;
  top: 0px;
  left: -33px;
  @media (max-width: 1513px) {
    display: none;
  }
`;
const GradientBackground = styled(H1_FlexRow)`
  width: calc(100% - 500px);
  background-image: linear-gradient(90deg, #0d0d0d 19.99%, hsla(0, 0%, 5%, 0) 52.67%),
    linear-gradient(90deg, #0d0d0d 32.84%, hsla(0, 0%, 5%, 0) 60.63%);
  position: absolute;
  height: 262px;
  top: 0px;
  left: -33px;
  @media (max-width: 1513px) {
    display: none;
  }
`;

const Video = styled.video`
  object-fit: cover;
  position: absolute;
  width: 100%;
  max-width: 1200px;
  height: auto;
  top: 0;
  right: 0;
  transform: rotateY(180deg);
  object-position: center -50px;
`;

const MaxWidthTextHeadline = styled(H1_TextHeadline)`
  max-width: 245px;
`;

const StyledButton = styled(Button)`
  border-radius: 40px;
  width: 132px;
  color: white;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0.14) 1.15%,
    rgba(255, 255, 255, 0.14) 98.91%
  );
`;

const SalesWomanImageContainer = styled(H1_FlexRow)`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 7px;
  transition: all 0.3s ease-in-out;
  img {
    flex-shrink: 0;
    width: 162px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.gray3}44;
  }
`;

const GradientSectionFlexColumn = styled(H1_FlexColumn)`
  min-width: fit-content;
  max-height: 250px;
  background: #ffc580;
  border-radius: 10px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      font-size: 16px;
    }
  }
`;

const RedGradientSectionFlexColumn = styled(GradientSectionFlexColumn)`
  background: #ff7d7d;
`;

const MaxWidthTextLargeHeadline = styled(H1_TextLargeHeadline)`
  max-width: 80%;
`;

const PositionButton = styled(Button)`
  background-color: white;
  margin-top: 13px;
  padding: 2px 30px;
  width: min-content;
  border-radius: 60px;
  & {
    height: 47px;
  }
`;

const SOURCE = "banner";

const HomePageTopBanners = () => {
  const { formatMessage } = useIntl();
  const flags = useFlags();
  const isFirstVideoCreated = useIsFirstVideoCreated();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const preferences = useAppSelector((state) => state.user.preferences);
  const usageSegment = preferences.usage_segment;
  const allCharacters = useAppSelector(charactersGlobalSelectors.selectAll);
  const webcamCharacters = useAppSelector(getAllWebcamCharacters);
  const flattenRecipes = useAppSelector(
    recipesSelectors.getFlattenRecipesCustomsFirst
  ) as FlatRecipe[];
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);

  const isUsageMediaEntertainment = usageSegment === UseCaseSegmentType.MediaEntertainment;
  const isUsageMarketing = usageSegment === UseCaseSegmentType.Marketing;
  const isUsageSales = usageSegment === UseCaseSegmentType.Sales;
  const isUsageDevelopers = usageSegment === UseCaseSegmentType.News;
  const isUsageTraining = usageSegment === UseCaseSegmentType.Training;
  const isNoCharacters = allCharacters.length === 0;
  const isIconicWorkspace =
    currentWorkspace?.metadata?.type === WorkspaceType.Iconic && flags[FeatureFlag.iconic];
  const isCGNAvailable = flags[FeatureFlag.cgn];

  const onClickCreateCharacterAction = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.createCharacter
      })
    );
    openModal(ModalName.createVirtualTwin);
  };

  const onClickLive = () => {
    navigate(Location.Live);
  };

  const onCreateNewIconicVideo = () => {
    if (flattenRecipes.length > 0) {
      const recipe = flattenRecipes[0];
      dispatch(
        analyticsEvents.newVideo({
          source: SOURCE,
          type: NewVideoType.iconic
        })
      );
      const draft = {
        recipe_id: recipe?.id,
        title: recipe?.title,
        description: ""
      };
      dispatch(uiActions.setFtuVideoPreview(false));
      dispatch(
        draftsActions.createDraftRequest({
          draft
        })
      );
    }
  };

  return (
    <ContainerFlexRow
      overflow="hidden"
      padding="0 50px"
      width="100%"
      gap="30px"
      height="262px"
      align="center"
      margin="0 0 30px 0"
    >
      <ConditionalRender condition={isNoCharacters && isUsageSales}>
        <StyledSkeletonButton active block $width="100%" $height="250px" $borderRadius="10px" />
      </ConditionalRender>
      <ConditionalRender
        condition={
          isUsageSales && (!webcamCharacters || !webcamCharacters.length) && !isNoCharacters
        }
      >
        <RedGradientSectionFlexColumn
          position="relative"
          padding="30px 30px 40px"
          width="100%"
          overflow="hidden"
          justify="space-between"
          flex="1"
          data-auto-id="home-page-top-section-container"
        >
          <SalesWomanImageContainer justify="center">
            <img
              src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/sales-image.webp"
              alt=""
            />
          </SalesWomanImageContainer>
          <H1_FlexColumn>
            <MaxWidthTextLargeHeadline
              whiteSpace="break-spaces"
              fontWeight={700}
              color={theme.gray1}
            >
              {formatMessage(homePageMessages.largeHeadingCreateYourAvatar)}
            </MaxWidthTextLargeHeadline>
            <H1_TextMiddle whiteSpace="break-spaces" color={theme.gray1}>
              {formatMessage(homePageMessages.smallHeadingCreateYourAvatar)}
            </H1_TextMiddle>
          </H1_FlexColumn>
          <H1_FlexRow align="flex-end" justify="space-between">
            <PositionButton type="secondary" onClick={onClickCreateCharacterAction}>
              {formatMessage(homePageMessages.buttonCreateYourAvatar)}
            </PositionButton>
          </H1_FlexRow>
        </RedGradientSectionFlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={isIconicWorkspace}>
        <IconicGradientSectionFlexColumn
          position="relative"
          padding="30px"
          width="100%"
          overflow="hidden"
          flex="0 1 auto"
          data-auto-id="home-page-top-section-iconic-container"
        >
          <H1_FlexRow justify="space-between" height="100%">
            <H1_FlexColumn justify="space-between">
              <MaxWidthTextHeadline
                fontFamily="Inter"
                color="white"
                whiteSpace="normal"
                fontWeight={500}
                fontSize="38px"
              >
                {formatMessage(homePageMessages.iconicWorkspaceHeadline)}
              </MaxWidthTextHeadline>
              <StyledButton
                isLoading={createDraftStatus === fetchingStatus.loading}
                onClick={onCreateNewIconicVideo}
              >
                {formatMessage(homePageMessages.bannerNewVideoCTA)}
              </StyledButton>
            </H1_FlexColumn>
            {flattenRecipes.length > 0 && <img alt="" src={flattenRecipes[0].image} />}
          </H1_FlexRow>
        </IconicGradientSectionFlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={(!isNoCharacters || !isUsageSales) && !isIconicWorkspace}>
        <ConditionalRender condition={isUsageSales}>
          <HomePageTopSectionSales />
        </ConditionalRender>
        <ConditionalRender condition={isUsageMarketing && isFirstVideoCreated}>
          <HomePageWorkflowSection />
        </ConditionalRender>
        <ConditionalRender condition={isUsageDevelopers && isFirstVideoCreated}>
          <HomePageTopSectionDevelopers />
        </ConditionalRender>
        <ConditionalRender condition={(!usageSegment || isUsageTraining) && isFirstVideoCreated}>
          <HomePageTopSectionTraining />
        </ConditionalRender>
        <ConditionalRender
          condition={!isFirstVideoCreated && !isUsageSales && flags[FeatureFlag.homepageFtu]}
        >
          <HomePageTopSectionFTU />
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender condition={isUsageMediaEntertainment}>
        <BannerFlexColumn
          overflow="hidden"
          position="relative"
          justify="space-between"
          height="262px"
          padding="33px"
          width="100%"
        >
          <Video
            muted
            loop
            autoPlay
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/tvShow_gordon_v02.mp4"
          />
          <GradientBackground />
          <GradientBackground2 />
          <H1_FlexColumn>
            <H1_TextLargeHeadline fontWeight={700} color="white" whiteSpace="normal">
              {formatMessage(homePageMessages.bannerMainText)}
            </H1_TextLargeHeadline>
            <H1_TextMiddle color="white" whiteSpace="normal">
              {formatMessage(homePageMessages.bannerSubText)}
            </H1_TextMiddle>
          </H1_FlexColumn>
          <ConditionalRender condition={isCGNAvailable}>
            <GetStartedButton onClick={onClickLive}>
              {formatMessage(homePageMessages.watchLive)}
            </GetStartedButton>
          </ConditionalRender>
          <ConditionalRender condition={!isCGNAvailable}>
            <GetStartedButton>{formatMessage(homePageMessages.getStarted)}</GetStartedButton>
          </ConditionalRender>
        </BannerFlexColumn>
      </ConditionalRender>
    </ContainerFlexRow>
  );
};

export default HomePageTopBanners;
