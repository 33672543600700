import * as filestackClient from "app/services/filestackClient";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { fetchingStatus } from "app/utils/helpers";
import * as Sentry from "@sentry/react";
import useErrors from "app/hooks/useErrors";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import { useIntl } from "react-intl";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { createVideoThumbnail, MAX_FILE_SIZE } from "app/services/filestackClient";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { FilestackPolicy } from "app/types";
import { fallbackPlaceholder } from "app/assets/images/placeholder";

interface Options {
  thumbnail?: boolean;
  autoUpload?: boolean;
}
const UseFileUploadFilestack = (options: Options = {}) => {
  const [file, setFile] = useState<File>();
  const [fileStoreResult, setFileStoreResult] = useState<{
    url: string;
    handle: string;
    name: string;
  }>();
  const [progress, setProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { notifyError } = useErrors();
  const dispatch = useAppDispatch();
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const createDraftLoading = createDraftStatus === fetchingStatus.loading;
  const filestackReadPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const [isFileStackErr, serIsFileStackErr] = useState<boolean>(false);
  useEffect(() => {
    if (file && options.autoUpload) {
      executeImport();
    }
  }, [file, options.autoUpload]);

  useEffect(() => {
    let timeoutId: any = null;
    if (fileStoreResult && options.thumbnail) {
      const executeThumbnail = async () => {
        const response = await createVideoThumbnail(
          filestackReadPolicy,
          fileStoreResult.handle as string
        );
        const thumbnailResource = await response.json();

        if (thumbnailResource.status === "completed") {
          const url = thumbnailResource.data.url;
          setThumbnail(url);
          setLoading(false);
        } else if (thumbnailResource.status === "failed") {
          console.error("failed create thumbnail", thumbnailResource);
          setLoading(false);
          setThumbnail(fallbackPlaceholder);
        } else {
          setLoading(true);
          timeoutId = setTimeout(executeThumbnail, 3000);
        }
      };
      executeThumbnail().catch((err) => {
        setThumbnail(fallbackPlaceholder);
        console.error("debug error", err);
        setLoading(false);
      });
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [fileStoreResult]);

  const onSelectFile = (selectedFile: File) => {
    setFile(selectedFile);
  };

  const executeImport = async () => {
    try {
      serIsFileStackErr(false);
      setLoading(true);
      setProgress(0);
      if (file && file.size > MAX_FILE_SIZE) {
        notifyError({
          general: buildGeneralError(
            `File size can't be larger than ${MAX_FILE_SIZE / 1024 / 1024}MB`,
            intl
          )
        });
        return;
      }
      const { payload: uploadPolicy } = await dispatch(
        mediaActions.getMediaPolicyRequest("upload")
      );
      filestackClient.init(uploadPolicy as FilestackPolicy, "upload");
      const client = filestackClient?.getClient("upload");
      const result = await client?.upload(file as File, {
        onProgress: (evt) => {
          if (evt?.totalPercent) {
            setProgress(evt.totalPercent);
          }
        },
        progressInterval: 500
      });
      setFileStoreResult(result);
    } catch (err) {
      serIsFileStackErr(true);
      console.error("error", err);
      Sentry.captureException(err, {
        extra: { description: "user failed import file" }
      });
      dispatch(
        analyticsEvents.somethingWentWrongEvent({
          message: "Failed process file"
        })
      );
      notifyError({ general: buildGeneralError("Failed process file", intl) });
      setLoading(false);
    } finally {
      if (!options.thumbnail) {
        setLoading(false);
      }
    }
  };

  const reset = () => {
    setFile(undefined);
    serIsFileStackErr(false);
    setProgress(0);
    setFileStoreResult(undefined);
  };

  return {
    file,
    reset,
    onSelectFile,
    executeImport,
    importLoading: loading || createDraftLoading,
    fileStoreResult,
    thumbnail,
    progress,
    isFileStackErr
  };
};

export default UseFileUploadFilestack;
