import { ConfigProvider } from "antd";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { darkTheme, lightTheme, ThemeMode } from "app/utils/theme";
import { useTheme as useNextTheme } from "next-themes";

type ThemeType = typeof lightTheme & { mode: ThemeMode };

interface ThemeContextType {
  // theme: Record<string, string>;
  theme: ThemeType;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);
export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

const StyledThemeProviderWrapper = ({
  children,
  theme
}: {
  children: ReactNode;
  theme: ThemeType;
}) => {
  return (
    <StyledThemeProvider theme={theme}>
      <ConfigProvider>{children}</ConfigProvider>
    </StyledThemeProvider>
  );
};

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<ThemeType>({
    ...darkTheme,
    mode: ThemeMode.Dark
  });
  const { theme: nextTheme, setTheme: setNextTheme } = useNextTheme();

  useEffect(() => {
    // Update the html class when the theme changes
    const htmlElement = document.documentElement;
    if (theme.mode === ThemeMode.Dark) {
      htmlElement.classList.remove("ho-light");
      htmlElement.classList.remove("dark");
      htmlElement.classList.add("ho-dark");
      htmlElement.classList.add("dark");
    } else {
      htmlElement.classList.remove("dark");
      htmlElement.classList.remove("ho-dark");
      htmlElement.classList.add("light");
      htmlElement.classList.add("ho-light");
    }
  }, [theme.mode]);

  const toggleTheme = () => {
    const newTheme =
      theme.mode === ThemeMode.Light
        ? { ...darkTheme, mode: ThemeMode.Dark }
        : { ...lightTheme, mode: ThemeMode.Light };
    setTheme(newTheme);
    if (nextTheme === "ho-light") {
      setNextTheme("ho-dark");
    } else {
      setNextTheme("ho-light");
    }
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <StyledThemeProviderWrapper theme={theme}>{children}</StyledThemeProviderWrapper>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
