import React, { useEffect, useMemo, useState } from "react";
import {
  useConnectionState,
  useLocalParticipant,
  useRoomContext,
  useTracks,
  VideoTrack
} from "@livekit/components-react";
import { ConnectionState, Track } from "livekit-client";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { SliderValue } from "@nextui-org/react";
import { realTimeActions } from "app/store/slices/realtime.slice";
import { useAppDispatch } from "app/hooks";
import RealTimeSlider from "app/pages/PlayGround/realTime/RealTimeSlider";
import ConditionalRender from "app/components/common/ConditionalRender";

const RoundFlexColumn = styled(H1_FlexRow)`
  video {
    border-radius: 50%;
  }
`;

// @ts-ignore - weird Livekit stuff
const StyledVideoTrack = styled(VideoTrack)`
  height: 205px;
  width: 205px;
  //filter: drop-shadow(12px -10px 42.57px #ababff);
  filter: blendTrans("lighten");
`;

const SlidersContainerFlexColumn = styled(H1_FlexColumn)`
  border-radius: 10px;
  background-color: #222222;
`;

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: #272727;
`;

const BlackContainer = styled(H1_FlexColumn)`
  border-radius: 12px;
  background-color: black;
`;

const ZoomContainer = styled(H1_FlexColumn)`
  border-radius: 12px;
  background-image: url("https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/realtime_bg.png");
  background-size: auto;
  background-position-y: calc(50% + 23px);
  background-position-x: calc(50% + 25px);
  background-repeat: no-repeat;
`;

const STEP = 0.01;
const SLIDER_MIN = -0.5;
const SLIDER_MAX = 0.5;
const PlaygroundRealTimeActiveRoom = ({ useBeta }: { useBeta: boolean }) => {
  const [pitch, setPitch] = useState<SliderValue>(0);
  const [yaw, setYaw] = useState<SliderValue>(0);
  const [roll, setRoll] = useState<SliderValue>(0);
  const [x, setX] = useState<SliderValue>(0);
  const [y, setY] = useState<SliderValue>(0);
  const [z, setZ] = useState<SliderValue>(0.25);
  const [gazeX, setGazeX] = useState<SliderValue>(0);
  const [gazeY, setGazeY] = useState<SliderValue>(0);
  const roomState = useConnectionState();
  const tracks = useTracks();
  const { localParticipant } = useLocalParticipant();
  const dispatch = useAppDispatch();
  const room = useRoomContext();

  const agentVideoTrack = tracks.find(
    (trackRef) => trackRef.publication.kind === Track.Kind.Video && trackRef.participant.isAgent
  );

  useEffect(() => {
    const handleChange = async () => {
      if (roomState === ConnectionState.Connected) {
        const data = {
          position: [pitch, yaw, roll, x, y, z],
          gaze: [gazeY, -gazeX]
        };
        const s = JSON.stringify(data);
        const encoder = new TextEncoder();
        const uint8Array = encoder.encode(s);
        // @ts-ignore - update data
        await room.localParticipant.publishData(uint8Array, { kind: "application/octet-stream" });
      }
    };
    handleChange();
  }, [pitch, yaw, roll, x, y, z, gazeY, gazeX]);

  useEffect(() => {
    if (roomState === ConnectionState.Connected) {
      localParticipant.setCameraEnabled(true);
      localParticipant.setMicrophoneEnabled(true);
    }
  }, [localParticipant, roomState]);

  useEffect(() => {
    return () => {
      dispatch(realTimeActions.removeTokenAndUrl());
      if (localParticipant) {
        localParticipant.setCameraEnabled(false);
        localParticipant.setMicrophoneEnabled(false);
      }
    };
  }, []);

  const videoTileContent = useMemo(() => {
    const videoFitClassName = `object-cover`;

    const disconnectedContent = (
      <div className="flex items-center justify-center text-gray-700 text-center w-full h-full">
        No video track. Connect to get started.
      </div>
    );

    const loadingContent = (
      <H1_FlexColumn
        align="center"
        justify="center"
        width="160px"
        height="160px"
        className="text-gray-700 text-center"
      >
        <CircleLoader />
        Waiting for video track
      </H1_FlexColumn>
    );

    const videoContent = (
      <H1_FlexRow flex="0 0 160px" gap="26px" height="100%">
        <StyledVideoTrack
          trackRef={agentVideoTrack}
          className={`absolute ${videoFitClassName} object-position-center`}
        />
      </H1_FlexRow>
    );

    let content = null;
    if (roomState === ConnectionState.Disconnected) {
      content = disconnectedContent;
      return (
        <H1_FlexColumn flex="0 0 160px" justify="center" height="160px" width="100%" align="center">
          {content}
        </H1_FlexColumn>
      );
    } else if (agentVideoTrack) {
      content = videoContent;
    } else {
      content = loadingContent;
      return (
        <H1_FlexColumn flex="0 0 160px" justify="center" height="160px" width="100%" align="center">
          {content}
        </H1_FlexColumn>
      );
    }

    return (
      <H1_FlexColumn
        position="relative"
        height="100%"
        width="100%"
        align="center"
        padding="20px 0"
        justify="center"
        gap="20px"
      >
        <H1_FlexRow gap="50px" width={useBeta ? "auto" : "800px"} align="center">
          <RoundFlexColumn
            flex="0 0 160px"
            overflow="hidden"
            justify="center"
            height="160px"
            width="160px"
            align="center"
            alignSelf="center"
          >
            {content}
          </RoundFlexColumn>
          <ConditionalRender condition={!useBeta}>
            <SlidersContainerFlexColumn flex="1" height="366px" padding="25px 35px" gap="11px">
              <H1_TextMiddle margin="0 0 13px" color="#787878">
                Live Settings
              </H1_TextMiddle>
              {/* Pitch */}
              <RealTimeSlider
                step={STEP}
                value={pitch}
                setValue={setPitch}
                title="Pitch"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
              {/* Yaw */}
              <RealTimeSlider
                value={yaw}
                step={STEP}
                setValue={setYaw}
                title="Yaw"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
              {/* Roll */}
              <RealTimeSlider
                value={roll}
                step={STEP}
                setValue={setRoll}
                title="Roll"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
              {/* x */}
              <RealTimeSlider
                value={x}
                step={STEP}
                setValue={setX}
                title="X"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
              {/* Y */}
              <RealTimeSlider
                value={y}
                step={STEP}
                setValue={setY}
                title="Y"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
              {/* Z */}
              <RealTimeSlider
                value={z}
                step={STEP}
                setValue={setZ}
                title="Z"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
              {/* gazeX */}
              <RealTimeSlider
                value={gazeX}
                step={STEP}
                setValue={setGazeX}
                title="GazeX"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
              {/* GazeY */}
              <RealTimeSlider
                value={gazeY}
                step={STEP}
                setValue={setGazeY}
                title="GazeY"
                min={SLIDER_MIN}
                max={SLIDER_MAX}
              />
            </SlidersContainerFlexColumn>
          </ConditionalRender>
        </H1_FlexRow>
      </H1_FlexColumn>
    );
  }, [agentVideoTrack, roomState, pitch, yaw, roll, x, y, z, gazeY, gazeX]);

  return (
    <BackgroundFlexColumn height="100%">
      <H1_FlexRow width="100%" flex="1">
        <H1_FlexColumn gap="20px" width="100%">
          <H1_FlexColumn padding="10px 0" flex="0 1 calc(100vh - 160px)">
            <ConditionalRender condition={useBeta}>
              <ZoomContainer overflow="hidden" margin="0 100px" flex="1">
                <H1_FlexColumn flex="1 1 auto">{videoTileContent}</H1_FlexColumn>
              </ZoomContainer>
            </ConditionalRender>
            <ConditionalRender condition={!useBeta}>
              <BlackContainer overflow="hidden" margin="0 100px" flex="1">
                <H1_FlexColumn flex="1 1 auto">{videoTileContent}</H1_FlexColumn>
              </BlackContainer>
            </ConditionalRender>
          </H1_FlexColumn>
        </H1_FlexColumn>
      </H1_FlexRow>
    </BackgroundFlexColumn>
  );
};

export default PlaygroundRealTimeActiveRoom;
