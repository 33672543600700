import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useEffect, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { Button, Input } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { paymentsActions } from "app/store/slices/payments.slice";
import { fetchingStatus } from "app/utils/helpers";
import { userActions } from "app/store/slices/user.slice";
import { workspacesActions } from "app/store/slices/workspaces.slice";

interface PartnershipModalProps {
  onFinish: (code?: string) => void;
  visible: boolean;
}
const PartnershipModal = ({ visible, onFinish }: PartnershipModalProps) => {
  const [code, setCode] = useState<string>("");
  const dispatch = useAppDispatch();

  const status = useAppSelector((state) => state.payments.redeemPartnershipCodeStatus);

  useEffect(() => {
    if (!visible) {
      setCode("");
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (status === fetchingStatus.succeeded) {
      dispatch(userActions.getUserDataRequest());
      dispatch(paymentsActions.getCreditsRequest());
      dispatch(workspacesActions.getWorkspacesRequest());
      onFinish();
      setTimeout(() => {
        dispatch(paymentsActions.updateRedeemCodeToIdle());
      }, 5000);
    }
  }, [status]);

  const onRedeemCode = () => {
    dispatch(paymentsActions.redeemPartnershipCodeRequest(code));
  };

  return (
    <StyledModal
      width="400px"
      open={visible}
      footer={null}
      centered
      onCancel={() => onFinish()}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="30px"
      $overflow="hidden"
      $gap="30px"
    >
      <H1_TextSubtitle>Partnership code</H1_TextSubtitle>
      <Input value={code} onValueChange={(value) => setCode(value)} />
      <H1_FlexRow justify="center">
        <Button
          fullWidth
          color="primary"
          onClick={onRedeemCode}
          isLoading={status === fetchingStatus.loading}
        >
          Redeem code
        </Button>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default PartnershipModal;
