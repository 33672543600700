import styled from "styled-components";
import { Popover } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import React, { useEffect, useMemo, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { SwiperSlide } from "swiper/react";
import { playgroundActions } from "app/store/slices/playground.slice";
import UploadArea from "app/components/common/UploadArea";
import ConditionalRender from "app/components/common/ConditionalRender";
import { PreloadedVideo } from "app/types/playground";
import { FeatureFlag, FoldersContext, MimeType } from "app/types";
import UploadFileContent from "app/components/common/UploadFileContent";
import MediaFileItem from "app/pages/PlayGround/MediaFileItem";
import { addPolicyToUrlIfFileStackMedia, fetchingStatus } from "app/utils/helpers";
import { getFilteredMediaBySpecificType } from "app/store/selectorsV2/media.selectors";
import { MediaType } from "app/types/media";
import { Button, Tab, Tabs, Textarea } from "@nextui-org/react";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { v4 as uuidv4 } from "uuid";
import useMediaLibraryUploader from "app/pages/mediaLibrary/useMediaLibraryUploader";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useFlags } from "launchdarkly-react-client-sdk";
import { playgroundImageCollection } from "app/pages/PlayGround/urls";
import VideoWithHover from "app/components/common/VideoWithHover";

const OuterImageBackgroundContainer = styled(H1_FlexRow)<{ $isBorderDisplayed: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ $isBorderDisplayed }) => ($isBorderDisplayed ? "white" : "transparent")};
  background-color: transparent;
  padding: 2px; /* Space between the border and the inner element */
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  width: calc(151px * 16 / 9);
  height: 151px;
  object-fit: cover;
  border-radius: 10px;
`;
const SaveButton = styled(Button)`
  align-self: flex-end;
`;
const StyledTextarea = styled(Textarea)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  height: 151px;
  &&&& div[data-slot="input-wrapper"] {
    height: 151px !important; // override the height: auto !important that was set in
    background-color: transparent;
    border: 1px solid #71717a;
    color: #d9d9d9;
    textarea[data-slot="input"] {
      color: #d9d9d9;
    }
  }
`;
const GenerateButton = styled(Button)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
const StyledTabs = styled(Tabs)`
  button[data-slot="tab"] {
    padding: 0;
    height: 20px;
  }
  span[data-slot="cursor"] {
    background-color: #71717a;
  }
  && div[data-slot="tabList"] {
    gap: 20px;
  }
  && div[data-slot="tabContent"] {
    color: #71717a;
  }
  &&&&& ~ {
    padding: 0;
  }
  &&& button[data-selected="true"] {
    > span {
      background-color: white;
    }
    > div {
      color: white;
    }
  }
`;
const StyledSwiper = styled(NavigationSwiper)`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  margin-left: 0;
  margin-right: 0;

  .swiper-slide {
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    top: calc(50% - 2px);
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(64 87 109 / 7%);
    border: 1px solid #8c8c8c;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: #8c8c8c;
    background-color: #343445;
    text-align: center;
    transition-property: border-color, color, opacity;
    transition-duration: 0.3s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);

    svg {
      fill: #8c8c8c;
      width: 16px;
      height: 16px;
    }

    &.swiper-button-disabled {
      pointer-events: auto;
    }

    &:hover {
      border-color: #8c8c8c;
    }

    &:active {
      transform: scale(0.95);
    }

    &::after {
      content: "";
    }
  }

  .swiper-button-prev {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-left-gray.svg");
    left: 0;
  }

  .swiper-button-next {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-right-gray.svg");
    right: 0;
  }

  &:hover .swiper-button-prev:not(.swiper-button-disabled),
  &:hover .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const BackgroundContainer = styled(H1_FlexColumn)<{ width: string; height: string }>`
  background-color: #27272a;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  //transition: width 1s ease-in-out, height 1s ease-in-out 1s;
  //will-change: height, width;
`;
const PopoverContainerFlexColumn = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: #27272a;
`;
const RemoveUploadButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
  background-color: #373737;
  i {
    color: white;
  }
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;
const PresenterImageBackgroundFlexRow = styled(H1_FlexRow)`
  border-radius: 12px;
  overflow: hidden;
  &:hover {
    ${RemoveUploadButton} {
      opacity: 1;
    }
  }
`;
const StyledImage = styled.img`
  cursor: pointer;
  z-index: 10;
  width: calc((128px * 16) / 9);
  height: 128px;
  object-fit: contain;
`;
const UploadIcon = styled.i<{ $size: string }>`
  font-size: ${({ $size }) => $size};
  color: #d9d9d9;
`;
const StyledPopover = styled(Popover)`
  padding: 0;
  &&& .ant-popover-content {
    border-radius: 12px;
    overflow: hidden;
  }

  &&& .ant-popover-inner-content {
    padding: 0;
  }
`;

enum PopoverTabs {
  Upload = "upload",
  Generate = "generate"
}
interface PlaygroundSelectExistingImageProps {
  executeImport: (files: File[]) => void;
  progress?: number;
  importLoading: boolean;
}
const PlaygroundSelectExistingImage = ({
  importLoading,
  progress,
  executeImport
}: PlaygroundSelectExistingImageProps) => {
  const [activeTab, setActiveTab] = useState<PopoverTabs>(PopoverTabs.Upload);
  const [promptValue, setPromptValue] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const livePortrait = useAppSelector((state) => state.playground.livePortrait);
  const files = useAppSelector((state) => getFilteredMediaBySpecificType(state, MediaType.image));
  const customPresenter = useAppSelector((state) => state.playground.customPresenter);

  const filestackReadPolicy = useAppSelector((state) => state.media.filestackReadPolicy);

  const {
    importLoading: importGenerateLoading,
    storeUrl,
    uploadedMedia,
    resetUploader
  } = useMediaLibraryUploader({ context: FoldersContext.playground });
  const mediaGenerateStatus = useAppSelector((state) => state.media.mediaGeneratorStatus);
  const mediaGeneratorResult = useAppSelector((state) => state.media.mediaGeneratorResult);
  const imageResultUrl = mediaGeneratorResult?.images?.[0]?.url;

  const generateLoading =
    mediaGenerateStatus === fetchingStatus.loading || importGenerateLoading || uploadLoading;

  const onChangePromptValue = (e: any) => {
    setPromptValue(e.target.value);
  };
  const handleGenerate = () => {
    dispatch(
      mediaActions.mediaGeneratorRequest({
        prompt: promptValue,
        orderId: uuidv4(),
        type: "image_generation"
      })
    );
  };

  const onClickUse = () => {
    dispatch(
      playgroundActions.updatePlayGroundDetails({
        livePortrait: {
          id: imageResultUrl as string,
          name: "Generated Image",
          image: imageResultUrl as string,
          url: imageResultUrl as string
        }
      })
    );
    onSaveInMedia();
    setOpen(false);
  };

  const onSaveInMedia = () => {
    setUploadLoading(true);
    storeUrl(imageResultUrl as string);
  };

  useEffect(() => {
    (async () => {
      if (uploadedMedia) {
        await dispatch(
          mediaActions.createMediaBulkRequest({
            bulk: [
              {
                url: uploadedMedia.url,
                handle: uploadedMedia.handle
                // name: uploadedMedia.name
              }
            ],
            context: FoldersContext.playground
          })
        );
        setUploadLoading(false);
        resetUploader();
      }
    })();
  }, [uploadedMedia]);

  const onSelectMedia = (value: PreloadedVideo) => {
    dispatch(
      playgroundActions.updatePlayGroundDetails({
        livePortrait: {
          id: value.url,
          name: "Live Portrait",
          image: value.thumbnail,
          url: value.url
        }
      })
    );
    setOpen(false);
  };

  const onChangeActiveTab = (value: PopoverTabs) => {
    setActiveTab(value);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onClickRemoveLivePortrait = () => {
    dispatch(playgroundActions.removePlayGroundLivePortrait());
  };

  const generateTitleContent = (
    <H1_FlexRow gap="5px" align="center">
      <H1_TextXs color={activeTab === PopoverTabs.Generate ? "white" : "#71717a"}>
        GENERATE
      </H1_TextXs>
      <H1_Icon icon="fas fa-sparkles" color="orange" />
    </H1_FlexRow>
  );

  const uploadTitleContent = (
    <H1_FlexRow gap="5px" align="center">
      <H1_TextXs color={activeTab === PopoverTabs.Upload ? "white" : "#71717a"}>UPLOAD</H1_TextXs>
    </H1_FlexRow>
  );

  const content = useMemo(
    () => (
      <BackgroundContainer
        width={activeTab === PopoverTabs.Upload ? "1200px" : "700px"}
        // height="288px"
        height={activeTab === PopoverTabs.Upload ? "197px" : "288px"}
      >
        <PopoverContainerFlexColumn
          padding="17px 25px 19px"
          width="100%"
          height="100%"
          align="center"
          justify="space-between"
          overflow="hidden"
        >
          <H1_FlexRow width="100%" flex="0 0 36px" align="center" justify="space-between">
            <H1_FlexRow gap="10px" align="center">
              <H1_FlexColumn>
                <StyledTabs
                  variant="underlined"
                  selectedKey={activeTab}
                  onSelectionChange={(value) => onChangeActiveTab(value as PopoverTabs)}
                >
                  <Tab key={PopoverTabs.Upload} title={uploadTitleContent}>
                    <H1_FlexColumn padding="20px 0 0 0" gap="5px" width="100%">
                      <H1_FlexRow gap="18px" width="1150px">
                        <StyledSwiper
                          allowTouchMove={false}
                          navigation
                          speed={700}
                          slidesPerView={6}
                          slidesPerGroup={6}
                        >
                          <SwiperSlide key="upgrade">
                            <H1_FlexRow position="relative">
                              <UploadFileContent
                                fileTypes={[MimeType.allowedImages]}
                                onSelectFile={(files) => {
                                  executeImport(files);
                                  setOpen(false);
                                }}
                              >
                                <UploadArea
                                  width="calc((81px * 16) / 9)"
                                  height="81px"
                                  text="Upload image"
                                  icon={
                                    <UploadIcon $size="20px" className="far fa-cloud-arrow-up" />
                                  }
                                  textColor="white"
                                />
                              </UploadFileContent>
                            </H1_FlexRow>
                          </SwiperSlide>

                          {files.reverse().map((file) => (
                            <SwiperSlide key={file.id}>
                              <MediaFileItem fileId={file.id} onSelectMedia={onSelectMedia} />
                            </SwiperSlide>
                          ))}
                          {playgroundImageCollection.map((media: PreloadedVideo) => (
                            <SwiperSlide key={media.url}>
                              <H1_FlexColumn gap="5px">
                                <OuterImageBackgroundContainer
                                  flex="0 0 87px"
                                  height="87px"
                                  $isBorderDisplayed={media.url === customPresenter?.url}
                                  position="relative"
                                >
                                  <H1_FlexRow
                                    position="relative"
                                    width="calc((81px * 16) / 9)"
                                    height="81px"
                                    onClick={() => onSelectMedia(media)}
                                    justify="center"
                                  >
                                    <VideoWithHover
                                      video={media.preview}
                                      image={media.thumbnail}
                                      autoPlay={false}
                                      disableIcon
                                      shouldRestartOnLeave={false}
                                    />
                                  </H1_FlexRow>
                                </OuterImageBackgroundContainer>
                              </H1_FlexColumn>
                            </SwiperSlide>
                          ))}
                        </StyledSwiper>
                      </H1_FlexRow>
                    </H1_FlexColumn>
                  </Tab>
                  {flags[FeatureFlag.falAi] && (
                    <Tab key={PopoverTabs.Generate} title={generateTitleContent}>
                      <H1_FlexColumn gap="6px" width="650px">
                        <H1_FlexRow gap="12px" flex="1 0 auto">
                          <H1_FlexRow position="relative" width="100%">
                            <StyledTextarea
                              value={promptValue}
                              defaultValue={promptValue}
                              onChange={onChangePromptValue}
                              placeholder="Describe the image you want to generate..."
                            />
                            <GenerateButton
                              size="sm"
                              isLoading={generateLoading}
                              onClick={handleGenerate}
                            >
                              Generate
                            </GenerateButton>
                          </H1_FlexRow>
                          <ConditionalRender condition={!!imageResultUrl}>
                            <H1_FlexRow flex="0 0 223px">
                              <Image src={imageResultUrl as string} />
                            </H1_FlexRow>
                          </ConditionalRender>
                        </H1_FlexRow>
                        <SaveButton
                          color="primary"
                          size="sm"
                          isDisabled={!imageResultUrl}
                          onClick={onClickUse}
                        >
                          Use
                        </SaveButton>
                      </H1_FlexColumn>
                    </Tab>
                  )}
                </StyledTabs>
              </H1_FlexColumn>
            </H1_FlexRow>
          </H1_FlexRow>
        </PopoverContainerFlexColumn>
      </BackgroundContainer>
    ),
    [files, promptValue, activeTab]
  );

  return (
    <StyledPopover
      placement="topLeft"
      className="select-video"
      getPopupContainer={() => document.querySelector(`.select-video`) as HTMLElement}
      content={content}
      overlayInnerStyle={{
        padding: 0
      }}
      trigger="click"
      showArrow={false}
      align={{ offset: [-19, -45], useCssRight: false, overflow: { adjustX: true } }}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <ConditionalRender condition={!livePortrait || importLoading}>
        <UploadArea
          borderRadius="10px"
          width="calc(128px * 16 / 9)"
          height="128px"
          text="Upload image"
          progress={progress}
          showValueLabel
          textColor="white"
          icon={
            importLoading ? (
              <UploadIcon $size={"30px"} className="fas fa-spinner-third fa-spin" />
            ) : (
              <UploadIcon $size={"30px"} className="far fa-image" />
            )
          }
        />
      </ConditionalRender>
      <ConditionalRender condition={!!livePortrait && !importLoading}>
        <PresenterImageBackgroundFlexRow
          align="center"
          width="calc((128px * 16) / 9)"
          height="128px"
          justify="center"
          position="relative"
        >
          <StyledImage
            src={addPolicyToUrlIfFileStackMedia(livePortrait?.url as string, filestackReadPolicy)}
          />
          <RemoveUploadButton
            size="sm"
            onClick={onClickRemoveLivePortrait}
            isIconOnly
            startContent={<i className="fas fa-trash" />}
          />
        </PresenterImageBackgroundFlexRow>
      </ConditionalRender>
    </StyledPopover>
  );
};

export default PlaygroundSelectExistingImage;
