import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import TitleComponent from "app/hoc/WithHeaderTitle";
import { SentryRoutes } from "app/services/sentry";
import CGNPage from "app/pages/CGN/CGNPage";
import { cgnMessages } from "app/pages/CGN/messages";
import CGNStreamPage from "app/pages/CGN/CGNStreamPage";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const LiveRouter = () => {
  useEffect(() => {
    const hideHubspot = (retry = 0) => {
      if (retry === 10) {
        return;
      }
      const hubspotContainer = document.getElementById("hubspot-messages-iframe-container");

      if (hubspotContainer) {
        hubspotContainer.style.zIndex = "-1"; // Hide the element
      } else {
        setTimeout(() => {
          hideHubspot(retry + 1);
        }, 1000);
      }
    };
    hideHubspot();
  }, []);
  return (
    <div className={`App auth-not-error allowed`}>
      <div className="no-responsive"></div>
      <H1_FlexRow flex="0 0 100vw" width="100vw" height="100vh" overflow="hidden">
        <SentryRoutes>
          <Route
            path="/"
            element={
              <>
                <CGNPage />
                <TitleComponent intlDef={cgnMessages.headline} />
              </>
            }
          />
          <Route
            path="/home"
            element={
              <>
                <CGNPage />
                <TitleComponent intlDef={cgnMessages.headline} />
              </>
            }
          />
          <Route
            path="live"
            element={
              <>
                <CGNPage />
                <TitleComponent intlDef={cgnMessages.headline} />
              </>
            }
          />
          <Route
            path="live/:id"
            element={
              <>
                <CGNStreamPage />
                <TitleComponent intlDef={cgnMessages.headline} />
              </>
            }
          />

          <Route path="" element={<Navigate to={"/"} />} />
        </SentryRoutes>
      </H1_FlexRow>
    </div>
  );
};

export default LiveRouter;
