import { useEffect, useState } from "react";

interface useVideoProps {
  videoNode: HTMLVideoElement | undefined;
  containerNode?: HTMLDivElement | null;
  visible: boolean;
  isAutoplay?: boolean;
  isDefaultMuted?: boolean;
  isDefaultFullScreen?: boolean;
  videoId?: string;
}

const useVideo = ({
  videoNode,
  visible,
  containerNode,
  isAutoplay = true,
  isDefaultMuted = true,
  isDefaultFullScreen = true,
  videoId
}: useVideoProps) => {
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(isAutoplay);
  const [isMuted, setIsMuted] = useState(isDefaultMuted);
  const [isFullScreen, setIsFullScreen] = useState(isDefaultFullScreen);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (videoNode) {
      if (isDefaultMuted) {
        videoNode.muted = true;
        setIsMuted(true);
      } else {
        videoNode.muted = false;
        setIsMuted(false);
      }
    }
  }, [isDefaultMuted]);

  useEffect(() => {
    if (visible && videoNode) {
      setVideoLoaded(false);
      setCurrentTime(0);
      setDuration(0);

      videoNode.addEventListener("timeupdate", handleCurrentTimeUpdate);
      videoNode.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoNode.addEventListener("canplay", handleOnLoadedVideo);
      videoNode.addEventListener("ended", handleOnEndedVideo);

      if (isAutoplay) {
        videoNode.play();
      }
      videoNode.muted = isDefaultMuted;
    }

    return () => {
      if (videoNode) {
        videoNode.removeEventListener("timeupdate", handleCurrentTimeUpdate);
        videoNode.removeEventListener("loadedmetadata", handleLoadedMetadata);
        videoNode.removeEventListener("canplay", handleOnLoadedVideo);
        videoNode.removeEventListener("ended", handleOnEndedVideo);
      }
    };
  }, [videoNode, visible, videoId]);

  // Bug in MUX Player - everytime the playback id changes, the video starts with sound on, regardless the muted being sent as a prop
  useEffect(() => {
    if (videoNode) {
      videoNode.muted = isMuted;
    }
  }, [videoId]);

  const handleCurrentTimeUpdate = () => {
    setCurrentTime(videoNode?.currentTime || 0);
  };

  const handleLoadedMetadata = () => {
    if (videoNode?.duration === Infinity) {
      videoNode.currentTime = 1000;
      fixVideoDuration();
    } else if (videoNode) {
      const { duration } = videoNode;
      setDuration(duration);
    }
  };

  const fixVideoDuration = (retry = 0) => {
    // hack for loading duration infinity
    if (retry === 10) {
      console.warn("failed load duration");
      return;
    }

    if (videoNode && videoNode.duration === Infinity) {
      setTimeout(() => {
        fixVideoDuration(retry + 1);
      }, 1000);
    } else if (videoNode) {
      setDuration(videoNode?.duration as number);
      videoNode.currentTime = 0;
    }
  };

  const handleOnLoadedVideo = () => {
    onVideoLoaded();
  };

  const handleOnEndedVideo = () => {
    setIsPlaying(false);
  };

  const onVideoLoaded = () => {
    const aspectRatio = (videoNode?.videoWidth || 1) / (videoNode?.videoHeight || 1);
    setAspectRatio(aspectRatio);
    setVideoLoaded(true);
  };

  const handlePlayPause = () => {
    if (videoNode?.paused) {
      videoNode?.play();
      setIsPlaying(true);
    } else {
      videoNode?.pause();
      setIsPlaying(false);
    }
  };

  const handleMuteUnmute = () => {
    if (videoNode) {
      if (videoNode.muted) {
        videoNode.muted = false;
        setIsMuted(false);
      } else {
        videoNode.muted = true;
        setIsMuted(true);
      }
    }
  };

  const handleFullScreen = async () => {
    if (!videoNode) {
      console.warn("Video node not available for fullscreen");
      return;
    }

    if (document.fullscreenElement) {
      if (containerNode) {
        containerNode.classList.remove("fullscreen");
      }
      setIsFullScreen(false);
      document.exitFullscreen();
    } else {
      setIsFullScreen(true);
      if (containerNode) {
        containerNode.classList.add("fullscreen");
        containerNode.requestFullscreen();
      } else {
        videoNode.requestFullscreen();
      }
    }
  };

  const handleTimeUpdate = () => {
    if (videoNode) {
      const { currentTime, duration } = videoNode;
      const progress = (currentTime / duration) * 100 || 0;
      setProgress(progress);
    }
  };

  const reset = () => {
    setIsPlaying(isAutoplay);
    setIsMuted(isDefaultMuted);
    setVideoLoaded(false);
    setAspectRatio(0);
    setCurrentTime(0);
    setDuration(0);
    setProgress(0);
    if (videoNode) {
      videoNode.muted = isDefaultMuted;
    }
  };

  return {
    reset,
    videoLoaded,
    handleTimeUpdate,
    handleMuteUnmute,
    handlePlayPause,
    handleFullScreen,
    isPlaying,
    isMuted,
    isFullScreen,
    currentTime,
    duration,
    aspectRatio,
    progress
  };
};

export default useVideo;
