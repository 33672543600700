import { Location } from "app/types";
import { useNavigate } from "react-router-dom";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button } from "@nextui-org/react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_TextMidHeadline, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { LiveKitRoom as LiveKitRoomContainer, RoomAudioRenderer } from "@livekit/components-react";
import ActiveRoom from "app/pages/RealTime/ActiveRoom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { realTimeActions } from "app/store/slices/realtime.slice";
import { fetchingStatus } from "app/utils/helpers";
import CircleLoader from "app/components/common/Loaders/CircleLoader";

const BlackBackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: #18181b;
  .lk-room-container {
    height: 100%;
  }
`;
const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background: linear-gradient(90deg, rgba(171, 171, 255, 0.6) 0%, rgba(24, 24, 27, 0.2) 100%);
`;
const BlackButtonContainer = styled(H1_FlexRow)`
  filter: drop-shadow(0px 0px 44px #ababff);
  width: 212px;
  height: 64px;
`;
const BlackButton = styled(Button)`
  background: rgba(24, 24, 27, 0.7);
  border-radius: 12px;
  height: 100%;
  color: white;
`;
const TextElement = styled(H1_FlexRow)`
  width: 756px;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.4px;
  letter-spacing: -7px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.4) 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 100px 0 0 0;
`;
const DarkRightFlexRow = styled(H1_FlexRow)`
  border-radius: 120px;
  background: #18181b;
  box-shadow: 0px 4px 34px 0px rgba(103, 103, 149, 0.6);
  position: fixed;
  right: -96px;
  bottom: -199px;
  width: 697px;
  height: 942px;
  flex-shrink: 0;
`;
const BlackSquare = styled(H1_FlexColumn)`
  border-radius: 34px;
  background: #27272a;
  box-shadow: 0px 0px 110px -12px rgba(171, 171, 255, 0.4);
  width: 564px;
  height: 391px;
  flex-shrink: 0;
`;
const GrayCircle = styled(H1_FlexRow)`
  border-radius: 50%;
  background-color: #71717a;
  width: 48px;
  height: 48px;
  flex: 0 0 48px;
  align-self: center;
`;
const StartConversationButton = styled(Button)`
  width: 212px;
  height: 48px;
  border-radius: 12px;
`;
const EllipseContainer = styled(H1_FlexRow)`
  position: relative;
  top: 77px;
  left: 77px;
  filter: drop-shadow(0px 0px 42.57px #ababff);
  width: 256px;
  height: 256px;
  border-radius: 50%;
  overflow: hidden;
`;
const Ellipse = styled(H1_FlexRow)`
  background-color: #d9d9d9;
`;
const GateImage = styled.img`
  object-fit: cover;
`;
const StyledHourOneLogo = styled(HourOneLogo)`
  margin-left: 5px;
  g path {
    fill: white;
  }
  g path:last-child {
    fill: #ababff;
  }
`;

const RealTimePage = () => {
  const [stage, setStage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getSessionStatus = useAppSelector((state) => state.realTime.getSessionStatus);
  const token = useAppSelector((state) => state.realTime.token);
  const livekitUrl = useAppSelector((state) => state.realTime.livekitUrl);

  useEffect(() => {
    if (getSessionStatus === fetchingStatus.succeeded) {
      dispatch(realTimeActions.updateGetSessionStatusToIdle());
    }
    if (getSessionStatus === fetchingStatus.failed) {
      dispatch(realTimeActions.updateGetSessionStatusToIdle());
      setStage(2);
    }
  }, [getSessionStatus]);

  const onBack = () => {
    navigate(Location.Home);
  };

  const onClickTalk = () => {
    setStage(2);
  };

  const onClickStartConversation = () => {
    dispatch(
      realTimeActions.getSessionRequest({
        useBeta: true,
        participantName: "Myself",
        roomName: `HourOne-${Date.now()}`
      })
    );
    setStage(3);
  };

  return (
    <BlackBackgroundFlexColumn overflow="hidden" width="100vw" height="100vh">
      <ConditionalRender condition={stage === 1}>
        <BackgroundFlexColumn overflow="hidden" width="100%" height="100%" position="relative">
          <H1_FlexRow alignSelf="flex-start" padding="10px" gap="12px" onClick={onBack}>
            <StyledHourOneLogo width="100%" height={38} />
          </H1_FlexRow>
          <H1_FlexColumn padding="0 0 0 70px" gap="70px">
            <TextElement>The Value Realization Framework</TextElement>
            <BlackButtonContainer>
              <BlackButton
                fullWidth
                onClick={onClickTalk}
                startContent={<i className="fas fa-video" />}
              >
                Talk to Gordon Live
              </BlackButton>
            </BlackButtonContainer>
          </H1_FlexColumn>
        </BackgroundFlexColumn>
        <DarkRightFlexRow>
          <EllipseContainer>
            <Ellipse>
              <GateImage src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/real-time-gate.webp" />
            </Ellipse>
          </EllipseContainer>
        </DarkRightFlexRow>
      </ConditionalRender>
      <ConditionalRender condition={stage === 2}>
        <H1_FlexRow align="center" justify="center" width="100%" height="100%">
          <BlackSquare justify="center" align="center">
            <H1_FlexColumn gap="7px" justify="center" align="center">
              <GrayCircle justify="center" align="center">
                <H1_Icon color="#27272A" icon="fas fa-video" />
              </GrayCircle>
              <H1_TextMidHeadline fontWeight={500} color="#ecedee">
                You`re about to talk with Gordon
              </H1_TextMidHeadline>
              <H1_FlexRow justify="center" height="115px" width="434px" flex="0 0 115px">
                <H1_TextSmall color="#71717a" lineHeight="20px" textAlign="center">
                  In a realtime video chat
                </H1_TextSmall>
              </H1_FlexRow>
              <StartConversationButton onClick={onClickStartConversation}>
                Start conversation
              </StartConversationButton>
            </H1_FlexColumn>
          </BlackSquare>
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={stage === 3 && getSessionStatus === fetchingStatus.loading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={stage === 3 && !!token}>
        {/* @ts-ignore - weird error */}
        <LiveKitRoomContainer
          token={token as string}
          serverUrl={livekitUrl as string}
          connectOptions={{ autoSubscribe: true }}
        >
          <ActiveRoom />
          <RoomAudioRenderer />
        </LiveKitRoomContainer>
      </ConditionalRender>
    </BlackBackgroundFlexColumn>
  );
};

export default RealTimePage;
