import { useEffect, useRef, useState } from "react";
import { MediaTrimType } from "app/types/media";

interface useVideoSyncEditorProps {
  video: HTMLVideoElement | null;
  duration?: number;
  durationInFrames?: number;
  initialTime?: number;
}
const useVideoSyncEditor = ({
  video,
  duration,
  durationInFrames,
  initialTime
}: useVideoSyncEditorProps) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [videoMax, setVideoMax] = useState<number>(duration || 0);
  const [videoRange, setVideoRange] = useState<[number, number]>([0, 0]);
  const [fillType, setFillType] = useState<MediaTrimType>(MediaTrimType.adaptSpeed);
  const [currentTime, setCurrentTime] = useState<number>(initialTime || 0);
  const requestRef = useRef<number>();

  useEffect(() => {
    const timeupdateHandler = () => {
      setCurrentTime(video?.currentTime ?? 0);
      if (video && videoRange && videoRange[0] > video?.currentTime) {
        video.currentTime = videoRange[0];
      }
      if (video && videoRange && videoRange[1] < video?.currentTime) {
        video.currentTime = videoRange[1];
      }
      if (video && videoRange && videoRange[1]) {
        const remainingTime = videoRange[1] - video.currentTime;
        if (remainingTime <= 0) {
          if (fillType === MediaTrimType.loop) {
            video.currentTime = videoRange[0] ?? 0;
            video.play();
          } else {
            video.pause();
          }
        }
      }
    };

    const loadedmetadata = () => {
      if (video?.duration === Infinity && !duration) {
        video.currentTime = 1000;
        fixVideoDuration();
      } else {
        const currentDuration = (video?.duration || duration) as number;
        setVideoMax(currentDuration);
        setVideoRange([0, currentDuration]);
      }
    };

    const onEnded = () => {
      if (video) {
        video.pause();
      }
      setPlaying(false);
    };

    const onPause = () => {
      if (video) {
        video.pause();
      }
      setPlaying(false);
    };

    if (video && video.readyState >= 1 && !videoMax) {
      loadedmetadata();
    } else if (video) {
      video.addEventListener("timeupdate", timeupdateHandler);
      video.addEventListener("loadedmetadata", loadedmetadata);
      video.addEventListener("ended", onEnded);
      video.addEventListener("pause", onPause);
    }
    return () => {
      video?.removeEventListener("timeupdate", timeupdateHandler);
      video?.removeEventListener("loadedmetadata", loadedmetadata);
      video?.removeEventListener("ended", onEnded);
      video?.removeEventListener("pause", onPause);
    };
  }, [video, videoRange, fillType, durationInFrames]);

  const syncPlayback = () => {
    if (!video || !videoRange) return;

    const currentVideoTime = video.currentTime;
    setCurrentTime(currentVideoTime);

    if (currentVideoTime >= videoRange[1]) {
      video.pause();
      video.currentTime = videoRange[1];
      setPlaying(false);
    } else {
      requestRef.current = requestAnimationFrame(syncPlayback);
    }
  };

  useEffect(() => {
    if (playing && video) {
      requestRef.current = requestAnimationFrame(syncPlayback);
    }
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [playing, video]);

  const fixVideoDuration = (retry = 0) => {
    // hack for loading duration infinity
    if (retry === 10) {
      console.warn("failed load duration");
      return;
    }
    if (video && video.duration === Infinity) {
      setTimeout(() => {
        fixVideoDuration(retry + 1);
      }, 1000);
    } else if (video) {
      setVideoMax(video?.duration as number);
      setVideoRange([0, video?.duration as number]);
      video.currentTime = 0;
    }
  };

  const onVideoRangeChange = ([min, max]: number[]) => {
    if (video) {
      video.currentTime = min;
      setVideoRange([min, max]);
    }
  };

  const onPlay = () => {
    if (video) {
      video.play();
      if (video.currentTime >= videoRange[1]) {
        video.currentTime = videoRange[0];
      }
      setPlaying(true);
    }
  };

  const onStop = () => {
    if (video) {
      video.pause();
      video.currentTime = 0;
      setPlaying(false);
    }
  };
  const onPause = () => {
    if (video) {
      video.pause();
    }
    setPlaying(false);
  };
  const onSelectFill = (value: MediaTrimType) => {
    setFillType(value);
  };

  const onTrackChange = (value: number) => {
    if (value > videoRange[1] || value < videoRange[0]) {
      return;
    }
    setCurrentTime(value);
    const timeInSec = value;
    if (video) {
      video.pause();
      video.currentTime = timeInSec;
    }
  };

  return {
    playing,
    currentTime,
    videoRange,
    videoMax,
    fillType,
    onPause,
    onStop,
    onPlay,
    onSelectFill,
    onVideoRangeChange,
    onTrackChange
  };
};

export default useVideoSyncEditor;
