import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  CGNType,
  ChannelsTabs,
  DEFIANCE,
  LiveStream,
  LiveVideoMode,
  Location,
  MuxStreamChapter
} from "app/types";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus, getTimeAgo } from "app/utils/helpers";
import { cgnActions } from "app/store/slices/cgn.slice";
import CGNHeader from "app/pages/CGN/CGNHeader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { capitalize } from "lodash-es";
import CGNVod from "app/pages/CGN/CGNVod";
import { ThemeMode } from "app/utils/theme";
import { Button } from "@nextui-org/react";
import { getStreamersVodByName } from "app/store/selectorsV2/cgn.selectors";
import MuxPlayerElement from "@mux/mux-player";
import LivePlayer from "app/pages/CGN/LivePlayer";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
`;

const EndFlexRow = styled(H1_FlexRow)`
  opacity: 0.7;
  &&&& {
    justify-content: flex-end;
  }
`;

const MuxWrapper = styled(H1_FlexColumn)`
  align-self: center;

  > div > div:first-of-type {
    overflow: hidden;
    flex: 1;
    aspect-ratio: 16 / 9;
    width: 100%;
    //max-height: 600px;

    > div {
      overflow: hidden;
      display: flex;
      justify-content: center;
      //max-height: 600px;

      > div {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        //max-height: 600px;
        > div {
          overflow: hidden;
          display: flex;
          justify-content: center;
          width: 100%;
          //max-height: 600px;
        }

        mux-player {
          border-radius: 14px;
          overflow: hidden;
          height: 100%;
          aspect-ratio: 16 / 9;
          //max-height: 600px;
          --captions-button: initial;
        }
      }
    }
    mux-player {
      --controls: none;
    }
    mux-player::part(--controls) {
      display: none !important;
    }
  }
`;

const ThumbnailImage = styled.img`
  border-radius: 15px;
  width: 149px;
  height: 82px;
`;

const WhatNextContainer = styled(H1_FlexRow)`
  background-color: ${(props) => props.theme.gray2};
  border-radius: 15px;
`;

const CHECK_INTERVAL = 2;

const CGNStreamPage = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isAutoPlayLoading, setIsAutoPlayLoading] = useState<boolean>(true);
  const [nextChapter, setNextChapter] = useState<MuxStreamChapter | undefined>(undefined);
  const [lastCheckedTime, setLastCheckedTime] = useState<number>(0);
  const playerRef = useRef<MuxPlayerElement>(null);
  const { id } = useParams<{ id: ChannelsTabs | DEFIANCE }>();
  // todo queryparam for vod
  const [searchParams] = useSearchParams();
  const vodId = searchParams.get("vod_id"); // Replace 'myParam' with your query param key

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentChannel = useAppSelector((state) =>
    state.cgn.channels.find((channel) => channel.name === id)
  );

  const cgnType = currentChannel?.type;
  const CGNInternalUse = useAppSelector((state) => state.cgn.CGNInternalUse);
  const streamersVod = useAppSelector((state) => getStreamersVodByName(state, id));
  const getCGNStatus = useAppSelector((state) => state.cgn.getCGNStatus);
  const getCGNStreamersVodStatus = useAppSelector((state) => state.cgn.getCGNStreamerVodStatus);
  const isCGNAsset = cgnType === CGNType.asset;
  const isCGNLive = cgnType === CGNType.live;
  const isCgnLoading = getCGNStatus === fetchingStatus.loading;
  const isVodLoading = getCGNStreamersVodStatus === fetchingStatus.loading;
  const isLoading = isVodLoading || isCgnLoading;

  const streams = useMemo(() => {
    if (currentChannel) {
      return [currentChannel, ...streamersVod];
    } else {
      return streamersVod;
    }
  }, [currentChannel, streamersVod]);

  const currentStream = useMemo(
    () => streams.find((stream) => vodId && stream.id && stream.id === vodId),
    [streams, vodId]
  );

  const playbackId = currentStream?.playback_id;

  useEffect(() => {
    if (streamersVod.length === 0) {
      dispatch(cgnActions.getCGNRequest(CGNInternalUse));
      dispatch(cgnActions.getCGNStreamerVodRequest(CGNInternalUse));
    }
  }, [CGNInternalUse]);

  useEffect(() => {
    if (isAutoPlayLoading) {
      setTimeout(() => {
        setIsAutoPlayLoading(false);
      }, 500);
    }
  }, [isAutoPlayLoading]);

  useEffect(() => {
    if (playerRef?.current) {
      if (playbackId) {
        const parsedChapters = currentStream.chapters.map((chapter) => {
          const { start_time, title } = chapter;
          return { startTime: start_time, value: title };
        });

        if (parsedChapters.length > 0) {
          // @ts-ignore - ignore endtime
          playerRef.current.addChapters(parsedChapters);
        }
      } else if (currentChannel) {
        const parsedChapters = currentChannel.chapters.map((chapter) => {
          const { start_time, title } = chapter;
          return { startTime: start_time, value: title };
        });

        if (parsedChapters.length > 0) {
          // @ts-ignore - ignore endtime
          playerRef.current.addChapters(parsedChapters);
        }
      }
    }
  }, [playbackId, currentChannel]);

  const nextStream = useMemo(() => {
    const index = streams.findIndex((stream) => stream.id === id);
    if (index === -1) {
      return streams.find((stream) => !!stream.thumbnail);
    } else if (streams[index + 1]?.thumbnail) {
      return streams[index + 1];
    } else {
      return streams.find((stream) => !!stream.thumbnail);
    }
  }, [streams]);

  const onChangeVideoMode = () => {
    let url = `${Location.Live}?channel=${id}`;
    if (vodId) {
      url = url + `&vod_id=${vodId}`;
    }
    navigate(url);
  };

  const onPlayVideo = (name: string, currId?: string) => {
    if (currId) {
      navigate(`${Location.Live}/${name}?vod_id=${currId}`);
    } else {
      navigate(`${Location.Live}/${name}`);
    }
  };

  const onClickNext = () => {
    const nextStreamsChaptersLength = nextStream?.chapters?.length;
    const currentChannelChaptersLength = currentChannel?.chapters?.length;
    if (playerRef?.current) {
      if (nextStreamsChaptersLength) {
        const nextChapterIndex = nextStream.chapters.findIndex(
          (chapter) => chapter.start_time === nextChapter?.start_time
        );

        if (nextChapterIndex > -1) {
          playerRef.current.currentTime = nextStream.chapters[nextChapterIndex].start_time;
        }
      } else if (currentChannelChaptersLength) {
        const nextChapterIndex = currentChannel.chapters.findIndex(
          (chapter) => chapter.start_time === nextChapter?.start_time
        );

        if (nextChapterIndex > -1) {
          playerRef.current.currentTime = currentChannel.chapters[nextChapterIndex].start_time;
        }
      }
    }
  };

  const onTimeUpdate = (event: any) => {
    const { currentTime } = event.target;
    if (Math.abs(currentTime - lastCheckedTime) > CHECK_INTERVAL) {
      setLastCheckedTime(currentTime);
      let theNextChapter;
      if (playbackId) {
        theNextChapter = nextStream?.chapters.find(
          (chapter: MuxStreamChapter) => currentTime < chapter.start_time
        );
      } else if (currentChannel) {
        theNextChapter = currentChannel.chapters.find(
          (chapter: MuxStreamChapter) => currentTime < chapter.start_time
        );
      }

      if (theNextChapter) {
        setNextChapter(theNextChapter);
      } else {
        setNextChapter(undefined);
      }
    }
  };

  const onChangeActiveTab = (tab: ChannelsTabs | DEFIANCE) => {
    navigate(`${Location.Live}/${tab}`);
  };

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsScrolled(event.currentTarget.scrollTop > 0);
  };

  return (
    <BackgroundFlexColumn
      onScroll={handleScroll}
      overflow="auto"
      position="relative"
      gap="28px"
      width="100%"
      height="100%"
    >
      <CGNHeader
        activeTab={id as ChannelsTabs | DEFIANCE}
        onChangeActiveTab={onChangeActiveTab}
        videoMode={LiveVideoMode.Player}
        onChangeVideoMode={onChangeVideoMode}
        isScrolled={isScrolled}
      />
      <H1_FlexColumn flex="1 1 auto" overflow="auto">
        <H1_FlexRow
          padding="0 28px 41px"
          overflow="hidden auto"
          flex="1 0 auto"
          justify="space-between"
          gap="28px"
          width="100%"
        >
          <H1_FlexColumn gap="10px" width="100%">
            <H1_FlexColumn width="100%" height="fit-content">
              <MuxWrapper gap="28px" flex="1 1 auto" width="100%" height="100%" align="center">
                <ConditionalRender condition={isLoading}>
                  <CircleLoader />
                </ConditionalRender>
                <ConditionalRender condition={!isLoading}>
                  <H1_FlexColumn width="100%" gap="40px" position="relative" height="100%" flex="1">
                    <H1_FlexColumn flex="1" position="relative">
                      {/*<MuxPlayer*/}
                      {/*  key={playbackId || currentChannel?.playback_id}*/}
                      {/*  shape="landscape"*/}
                      {/*  playbackId={*/}
                      {/*    playbackId ||*/}
                      {/*    currentChannel?.playback_id ||*/}
                      {/*    "f3uwVtnFPDHdGRrtR01RsfSUG4FO8ndEWTzvIGKdo80100"*/}
                      {/*  }*/}
                      {/*  streamType={isCGNAsset ? MuxStream.onDemand : MuxStream.live}*/}
                      {/*  overflow="hidden"*/}
                      {/*  loop*/}
                      {/*  autoPlay={!isAutoPlayLoading}*/}
                      {/*  ref={playerRef}*/}
                      {/*  disableCC={false}*/}
                      {/*  onTimeUpdate={onTimeUpdate}*/}
                      {/*  minResolution={flags[FeatureFlag.liveMinResolution]}*/}
                      {/*/>*/}
                      <LivePlayer
                        isCGNAsset={isCGNAsset}
                        isCGNLive={isCGNLive}
                        ref={playerRef}
                        onTimeUpdate={onTimeUpdate}
                        playbackId={
                          playbackId ||
                          currentChannel?.playback_id ||
                          "f3uwVtnFPDHdGRrtR01RsfSUG4FO8ndEWTzvIGKdo80100" // MUST have a default id for the mux to render
                        }
                      />
                    </H1_FlexColumn>
                  </H1_FlexColumn>
                </ConditionalRender>
              </MuxWrapper>
            </H1_FlexColumn>
            <EndFlexRow padding="5px 0" width="100%">
              <Button
                size="sm"
                onClick={onChangeVideoMode}
                startContent={<i className="far fa-rectangle-wide fa-sharp" />}
              >
                Theater Mode
              </Button>
            </EndFlexRow>
            <H1_FlexRow flex="0 0 40px" justify="space-between">
              <H1_FlexRow gap="8px">
                <H1_FlexColumn>
                  <H1_TextSmall color={theme.gray5}>
                    {currentStream?.title || currentChannel?.title}
                  </H1_TextSmall>
                  <H1_TextSmall color={theme.gray6}>
                    {getTimeAgo(currentStream?.created_at || currentChannel?.created_at)}
                  </H1_TextSmall>
                  <ConditionalRender condition={!!currentStream?.viewers}>
                    <H1_TextSmall color={theme.gray6}>
                      {currentStream?.viewers} viewers
                    </H1_TextSmall>
                  </ConditionalRender>
                </H1_FlexColumn>
              </H1_FlexRow>
            </H1_FlexRow>
            {/* VOD next video */}
            <ConditionalRender condition={!!playbackId && !!nextChapter}>
              <WhatNextContainer padding="13px" gap="23px" onClick={onClickNext}>
                <ThumbnailImage src={nextChapter?.thumbnail}></ThumbnailImage>
                <H1_FlexColumn justify="center">
                  <H1_TextMiddle fontWeight={700} color={theme.gray11}>
                    Next up
                  </H1_TextMiddle>
                  <H1_TextSmall color={theme.gray11}>{capitalize(nextChapter?.title)}</H1_TextSmall>
                </H1_FlexColumn>
              </WhatNextContainer>
            </ConditionalRender>
            {/* Live channel next video */}
            <ConditionalRender
              condition={!playbackId && !!currentChannel && isCGNAsset && !!nextChapter}
            >
              <WhatNextContainer padding="13px" gap="23px" onClick={onClickNext}>
                <ThumbnailImage src={nextChapter?.thumbnail}></ThumbnailImage>
                <H1_FlexColumn justify="center">
                  <H1_TextMiddle fontWeight={700} color={theme.gray11}>
                    Next up
                  </H1_TextMiddle>
                  <H1_TextSmall color={theme.gray11}>{capitalize(nextChapter?.title)}</H1_TextSmall>
                </H1_FlexColumn>
              </WhatNextContainer>
            </ConditionalRender>
          </H1_FlexColumn>
          <ConditionalRender condition={!isLoading}>
            <H1_FlexColumn overflow="auto" flex="0 0 400px" width="400px" height="100%" gap="26px">
              {streams.map((vod: LiveStream, index) => (
                <CGNVod
                  key={vod.playback_id + index}
                  playbackId={vod.playback_id as string}
                  onPlayVideo={() => onPlayVideo(vod.name, vod.id)}
                  isSelected={vod.playback_id === playbackId}
                />
              ))}
            </H1_FlexColumn>
          </ConditionalRender>
        </H1_FlexRow>
      </H1_FlexColumn>
    </BackgroundFlexColumn>
  );
};

export default CGNStreamPage;
