import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChannelsTabs, DEFIANCE, LiveStream } from "app/types";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import {
  getPlayback,
  getStreamersVod,
  sendLiveWatchNext,
  sendPublicLivePrompt,
  uploadStreamersVod
} from "app/services/serviceV2Apis";
import { parseISO, differenceInMilliseconds } from "date-fns";

const prefix = "[CGN]";

const getCGNRequest = createAsyncThunk<LiveStream[], boolean>(
  `${prefix} getCGNRequest`,
  async (CGNInternalUse) => {
    const res = await getPlayback(CGNInternalUse);
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

    return res.filter((stream) => {
      const createdAtDate = stream.created_at ? new Date(stream.created_at) : new Date();
      return createdAtDate >= threeDaysAgo;
    });
  },
  thunkOptions
);

const getCGNStreamerVodRequest = createAsyncThunk<LiveStream[], boolean>(
  `${prefix} getCGNStreamerRequest`,
  async (CGNInternalUse) => {
    let res = await getStreamersVod(CGNInternalUse);
    res = res.map((streamer) => {
      if (streamer.created_at) {
        const createdAt = parseISO(streamer.created_at);
        const now = new Date();

        const timeDifference = differenceInMilliseconds(now, createdAt);
        const viewers = Math.floor(timeDifference / 100000);

        return { ...streamer, viewers };
      } else {
        return streamer;
      }
    });

    return res;
  },
  thunkOptions
);
const uploadVodRequest = createAsyncThunk<
  void,
  { url: string; channel: ChannelsTabs | DEFIANCE; title: string; orderId: string }
>(
  `${prefix} uploadVodRequest`,
  async ({ url, channel, title, orderId }) => {
    const res = await uploadStreamersVod({ url, channel, title, orderId });
    return res;
  },
  thunkOptions
);

const sendPublicLivePromptRequest = createAsyncThunk<
  void,
  { prompt: string; useCase: ChannelsTabs | DEFIANCE }
>(
  `${prefix} sendPublicLivePromptRequest`,
  async ({ prompt, useCase }) => {
    const res = await sendPublicLivePrompt(prompt, useCase);
    return res;
  },
  thunkOptions
);

const sendLiveWatchNextRequest = createAsyncThunk<void, { email: string; description: string }>(
  `${prefix} sendLiveWatchNextRequest`,
  async ({ email, description }) => {
    await sendLiveWatchNext(email, description);
  },
  thunkOptions
);
export default {
  getCGNRequest,
  getCGNStreamerVodRequest,
  uploadVodRequest,
  sendPublicLivePromptRequest,
  sendLiveWatchNextRequest
};
