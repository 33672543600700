/* eslint-disable  camelcase */
import { Draft } from "app/types/draft";

export enum FolderType {
  file = "file",
  folder = "folder"
}
export type FileType = "draft";

export interface Folder {
  id?: string;
  name?: string;
  updated_at?: string;
  created_at?: string;
  parent_id?: string;
  type?: FolderType;
  file_id?: string;
  file_type?: FileType;
  data?: Draft;
  total_items?: number;
  owner_email?: string;
  owner_picture?: string;
}

export interface CreateFolder {
  name: string;
  parent_id?: string;
}

export enum FoldersContext {
  media = "media",
  playground = "playground",
  workflow = "workflow"
}
