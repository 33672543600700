import { FetchStatus } from "app/types";
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/realTime.thunk";
import { fetchingStatus } from "app/utils/helpers";

interface RealTimeSliceState {
  getSessionStatus: FetchStatus;
  token?: string;
  livekitUrl?: string;
}

const INITIAL_STATE: RealTimeSliceState = {
  getSessionStatus: fetchingStatus.idle
};

export const realTimeSlice = createSlice({
  name: "RealTime",
  initialState: INITIAL_STATE,
  reducers: {
    updateGetSessionStatusToIdle: (state) => {
      state.getSessionStatus = fetchingStatus.idle;
      return state;
    },
    removeTokenAndUrl: (state) => {
      state.token = undefined;
      state.livekitUrl = undefined;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getSessionRequest.fulfilled, (state, action) => {
      state.getSessionStatus = fetchingStatus.succeeded as FetchStatus;
      state.token = action.payload.token;
      state.livekitUrl = action.payload.livekit_url;
    });
    builder.addCase(asyncThunks.getSessionRequest.pending, (state) => {
      state.getSessionStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getSessionRequest.rejected, (state) => {
      state.getSessionStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});
export default realTimeSlice.reducer;

export const realTimeActions = {
  getSessionRequest: asyncThunks.getSessionRequest,
  ...realTimeSlice.actions
};
