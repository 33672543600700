import { StyledModal } from "app/components/common/StyledModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Select,
  Selection,
  SelectItem,
  Slider,
  SliderValue
} from "@nextui-org/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import { charactersActions } from "app/store/slices/characters.slice";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { fetchingStatus } from "app/utils/helpers";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ThemeMode } from "app/utils/theme";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { SwiperSlide } from "swiper/react";
import VideoWithHover from "app/components/common/VideoWithHover";

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: calc(100% - 24px);
  margin: 0 24px 0 0;
  padding: 0 5px;
  .swiper-slide {
    display: flex;
    width: auto;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    box-shadow: none;
    background-color: white;
    border: none;
    top: calc(50% + 2px);
    bottom: 20px;
    background-size: 24px;
    transition: 0.3s all ease-in-out;
    z-index: 100;
    &:hover {
      background-size: 30px;
    }
    &:active {
      background-size: 20px;
    }
  }
  && .swiper-button-prev {
    left: -10px;
    background-color: ${({ theme }) => theme.gray1};
    &::before {
      background: linear-gradient(to left, transparent 0%, ${({ theme }) => theme.gray1} 100%);
      content: "";
      height: 100%;
      pointer-events: none;
      position: absolute;
      width: 24px;
      left: 24px;
    }
  }
  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) => theme.gray1};
    &::after {
      background: linear-gradient(to right, transparent 0%, ${({ theme }) => theme.gray1} 100%);
      content: "";
      height: 100%;
      pointer-events: none;
      position: absolute;
      width: 24px;
      right: 24px;
    }
  }
  .swiper-button-prev:not(.swiper-button-disabled),
  .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const CharImage = styled.img`
  max-height: 164px;
`;

const StyledSelect = styled(Select)<{ $maxWidth?: string }>`
  flex: 1 0 auto;
  max-width: ${({ $maxWidth }) => $maxWidth};
  &&& li {
    transition: background-color 0.3s ease-in-out;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray3 : theme.gray2};
    &:hover {
      background-color: ${({ theme }) =>
        theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray3};
    }
    span {
      color: ${({ theme }) => (ThemeMode.Light ? theme.gray6 : theme.gray5)};
    }
  }
`;

const BorderedBox = styled(H1_FlexColumn)<{ $selected?: boolean }>`
  background-color: #343443;
  border-radius: 15px;
  border: 2px solid ${({ $selected, theme }) => ($selected ? theme.blue2 : "transparent")};
  &:active {
    transform: scale(0.9);
  }
  transition: transform 0.3s ease-in-out;
`;

const BlackRow = styled(H1_FlexRow)`
  background-color: black;
`;

interface CreateChannelModalProps {
  visible: boolean;
  onClose: () => void;
}

const characterIds = [
  "646b491a8be476dc8ce29616",
  "6512ae905bd3eea9909c1df8",
  "6422b7b5a1a8e94971398e01",
  "6512a7086b2357af7a1a9e10",
  "64eda7f7d26d20b1f5974ac4"
];

const CreateChannelModal = ({ visible, onClose }: CreateChannelModalProps) => {
  const [selectedCharacter, setSelectedCharacter] = useState<string>("");
  const [tone, setTone] = useState<Selection>(new Set(["serious"]));
  const [url, setUrl] = useState<string>("");
  const [backgroundUrl, setBackgroundUrl] = useState<string>("1");
  const [timesPerDay, setTimesPerDay] = useState<SliderValue>(4);
  const [length, setLength] = useState<SliderValue>(20);
  const [segments, setSegments] = useState<SliderValue>(3);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const hasCharacters = useAppSelector(charactersGlobalSelectors.selectAll).length > 0;
  const charactersStatus = useAppSelector((state) => state.characters.charactersStatus);
  const publicCharacters = useAppSelector((state) =>
    CharacterSelectors.getAvatarsPageListByProperties(state, "")
  );

  useEffect(() => {
    if (!hasCharacters && visible) {
      dispatch(charactersActions.getCharactersListRequest());
    }
  }, [hasCharacters, visible]);

  const filteredCharacters = useMemo(() => {
    if (visible && publicCharacters.length > 0) {
      const filtered = publicCharacters.filter((character) =>
        characterIds.includes(character.character_id)
      );

      return filtered.length > 0 ? filtered : publicCharacters;
    }
    return [];
  }, [publicCharacters, visible]);

  return (
    <StyledModal
      open={visible}
      bodyStyle={{ height: "80vh" }}
      footer={null}
      centered
      onCancel={onClose}
      destroyOnClose
      maskClosable
      closeIcon={<CloseModalIcon />}
      $padding="80px 60px"
      $gap="37px"
      width="800px"
      $overflow="auto"
    >
      <ConditionalRender condition={charactersStatus === fetchingStatus.loading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={charactersStatus !== fetchingStatus.loading}>
        <H1_TextSubtitle fontSize="22px" color={theme.gray11}>
          Create your channel
        </H1_TextSubtitle>
        <H1_FlexColumn flex="0 0 auto" gap="23px">
          <H1_TextSubtitle color="#a5a5a5">Select crew</H1_TextSubtitle>
          <H1_FlexRow gap="11px" position="relative">
            <StyledSwiper
              allowTouchMove={false}
              navigation
              speed={1000}
              slidesPerView="auto"
              spaceBetween={10}
              slidesPerGroup={1}
            >
              {filteredCharacters.slice(0, 5).map((character) => (
                <SwiperSlide key={character.character_id}>
                  <BorderedBox
                    $selected={character.character_id === selectedCharacter}
                    onClick={() => setSelectedCharacter(character.character_id)}
                    key={character.character_id}
                    overflow="hidden"
                    justify="flex-end"
                  >
                    <CharImage
                      height={164}
                      src={character?.thumbnails?.page.image || character?.image}
                      alt={character?.title}
                    />
                    <BlackRow justify="center" align="center" width="100%" height="32px">
                      <H1_TextSmall color={theme.gray11}>{character.title}</H1_TextSmall>
                    </BlackRow>
                  </BorderedBox>
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </H1_FlexRow>
        </H1_FlexColumn>
        <H1_FlexColumn flex="0 0 auto" gap="20px" margin="18px 0 0 0">
          <H1_TextSubtitle color="#a5a5a5">Select tone</H1_TextSubtitle>
          <StyledSelect
            size="sm"
            className="max-w-xs"
            labelPlacement="outside-left"
            placeholder="Select tone"
            selectedKeys={tone}
            variant="bordered"
            onSelectionChange={setTone}
            classNames={{
              base: "items-center",
              label: "w-[160px]",
              popoverContent: "text-gray-400"
            }}
          >
            <SelectItem key="serious">Serious</SelectItem>
            <SelectItem key="neutral">Neutral</SelectItem>
            <SelectItem key="friendly">Friendly</SelectItem>
            <SelectItem key="urgent">Urgent</SelectItem>
            <SelectItem key="enthusiastic">Enthusiastic</SelectItem>
          </StyledSelect>
        </H1_FlexColumn>
        <H1_FlexColumn flex="0 0 auto" gap="23px">
          <H1_TextSubtitle color="#a5a5a5">Select URL</H1_TextSubtitle>
          <Input
            className="max-w-lg"
            placeholder="Paste your URL here"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            size="sm"
            labelPlacement="outside-left"
            classNames={{ mainWrapper: "w-full" }}
          />
        </H1_FlexColumn>
        <H1_FlexColumn flex="0 0 auto" gap="23px">
          <H1_TextSubtitle color="#a5a5a5">Background</H1_TextSubtitle>
          <H1_FlexRow gap="22px">
            <BorderedBox
              onClick={() => setBackgroundUrl("1")}
              $selected={backgroundUrl === "1"}
              overflow="hidden"
              width="215px"
              height="120px"
            >
              <VideoWithHover
                disableIcon
                video={
                  "https://sizzly-public-perma-user-assets-examples-prod.s3.us-east-2.amazonaws.com/Assets-Library/videos/background/enter_02.mp4"
                }
              />
            </BorderedBox>
            <BorderedBox
              onClick={() => setBackgroundUrl("2")}
              $selected={backgroundUrl === "2"}
              overflow="hidden"
              width="215px"
              height="120px"
            >
              <VideoWithHover
                disableIcon
                video={"https://cdn.filestackcontent.com/8uWWPjJTDmegIkGMmoAU"}
              />
            </BorderedBox>
            <BorderedBox
              onClick={() => setBackgroundUrl("3")}
              $selected={backgroundUrl === "3"}
              overflow="hidden"
              width="215px"
              height="120px"
            >
              <VideoWithHover
                disableIcon
                video={
                  "https://sizzly-public-perma-user-assets-examples-prod.s3.us-east-2.amazonaws.com/Assets-Library/videos/background/tech_02.mp4"
                }
              />
            </BorderedBox>
          </H1_FlexRow>
        </H1_FlexColumn>
        <H1_FlexColumn flex="0 0 auto" gap="30px">
          <H1_TextSubtitle color="#a5a5a5">Schedule</H1_TextSubtitle>
          <H1_FlexRow justify="space-between">
            <H1_TextSmall color={theme.gray11}>Times per day</H1_TextSmall>
            <H1_FlexRow width="550px" gap="16px" align="center">
              <H1_TextSmall color={theme.gray11}>1</H1_TextSmall>
              <Slider
                size="sm"
                value={timesPerDay}
                onChange={setTimesPerDay}
                step={1}
                minValue={1}
                maxValue={24}
                showTooltip
                color="foreground"
              />
              <H1_TextSmall color={theme.gray11}>24</H1_TextSmall>
            </H1_FlexRow>
          </H1_FlexRow>

          <H1_FlexRow justify="space-between">
            <H1_TextSmall color={theme.gray11}>Length</H1_TextSmall>
            <H1_FlexRow width="550px" gap="16px" align="center">
              <H1_TextSmall color={theme.gray11}>10s</H1_TextSmall>
              <Slider
                size="sm"
                value={length}
                onChange={setLength}
                step={1}
                minValue={10}
                maxValue={60}
                showTooltip
                formatOptions={{
                  style: "unit",
                  unit: "second",
                  unitDisplay: "narrow",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }}
                color="foreground"
              />
              <H1_TextSmall color={theme.gray11}>60s</H1_TextSmall>
            </H1_FlexRow>
          </H1_FlexRow>

          <H1_FlexRow justify="space-between">
            <H1_TextSmall color={theme.gray11}>Times per day</H1_TextSmall>
            <H1_FlexRow width="550px" gap="16px" align="center">
              <H1_TextSmall color={theme.gray11}>1</H1_TextSmall>
              <Slider
                size="sm"
                value={segments}
                onChange={setSegments}
                step={1}
                minValue={1}
                maxValue={6}
                showTooltip
                color="foreground"
              />
              <H1_TextSmall color={theme.gray11}>6</H1_TextSmall>
            </H1_FlexRow>
          </H1_FlexRow>
        </H1_FlexColumn>
        <H1_FlexRow flex="0 0 auto" margin="50px 0 0 0" justify="flex-end">
          <Button color="primary" onClick={onClose}>
            Create Channel
          </Button>
        </H1_FlexRow>
      </ConditionalRender>
    </StyledModal>
  );
};

export default CreateChannelModal;
