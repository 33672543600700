/* eslint-disable no-param-reassign,camelcase */

import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/payments.thunk";
import { fetchingStatus } from "app/utils/helpers";
import {
  CreditsData,
  FeaturePrice,
  FetchStatus,
  PaymentModalPhase,
  PaymentPeriod,
  PlanAndPriceModel,
  PlanToUpgrade,
  PricingModalProperties,
  SubscriptionResults
} from "app/types";
import {
  MinuteAnalytic,
  SubscriptionCancellationResult,
  SubscriptionStatus,
  VideoUsageAnalytic
} from "app/types/payments";

interface PaymentsState {
  createPaymentStatus: FetchStatus;
  executeSessionStatus: FetchStatus;
  verifyCouponStatus: FetchStatus;
  paymentsUrl?: string;
  createSubscriptionResults?: SubscriptionResults;
  pricingAndPlans: Partial<PlanAndPriceModel>[];
  verifyCouponResults?: {
    isCouponValid: boolean;
    percentOff?: number;
    planId: string;
  };
  addOnPaymentResult?: {
    secret: string;
    invoice_id: string;
  };
  setupResult?: {
    secret: string;
  };
  setupStatus: FetchStatus;
  pricingStatus: FetchStatus;
  addOnPaymentStatus: FetchStatus;
  cancelStatus: FetchStatus;
  updateCreditCardStatus: FetchStatus;
  minutesAnalyticsStatus: FetchStatus;
  analyticsMetadataStatus: FetchStatus;
  redeemPartnershipCodeStatus: FetchStatus;
  minutesAnalytics: MinuteAnalytic[];
  analyticsMetadata: VideoUsageAnalytic[];
  updateCreditCardUrl: { url?: string };
  getSubscriptionStatus: FetchStatus;
  featuresPricing: FeaturePrice[];
  pricingModalProperties: PricingModalProperties;
  creditsStatus: CreditsData[];
  subscriptions: SubscriptionStatus[];
  cancelResult?: SubscriptionCancellationResult;
}

const InitialState: PaymentsState = {
  cancelStatus: fetchingStatus.idle as FetchStatus,
  updateCreditCardStatus: fetchingStatus.idle as FetchStatus,
  createPaymentStatus: fetchingStatus.idle as FetchStatus,
  executeSessionStatus: fetchingStatus.idle as FetchStatus,
  verifyCouponStatus: fetchingStatus.idle as FetchStatus,
  setupStatus: fetchingStatus.idle as FetchStatus,
  pricingStatus: fetchingStatus.idle as FetchStatus,
  addOnPaymentStatus: fetchingStatus.idle as FetchStatus,
  getSubscriptionStatus: fetchingStatus.idle as FetchStatus,
  minutesAnalyticsStatus: fetchingStatus.idle as FetchStatus,
  analyticsMetadataStatus: fetchingStatus.idle as FetchStatus,
  redeemPartnershipCodeStatus: fetchingStatus.idle as FetchStatus,
  minutesAnalytics: [],
  analyticsMetadata: [],
  updateCreditCardUrl: {},
  pricingAndPlans: [],
  paymentsUrl: undefined,
  createSubscriptionResults: {
    subscriptionId: undefined,
    secret: undefined,
    isCoupon: false
  },
  featuresPricing: [],
  pricingModalProperties: {
    selectedPlan: PlanToUpgrade.personal,
    phase: PaymentModalPhase.INITIAL,
    paymentPeriod: PaymentPeriod.Monthly
  },
  creditsStatus: [],
  subscriptions: []
};
export const paymentsSlice = createSlice({
  name: "Payments",
  initialState: InitialState,
  reducers: {
    cleanPayment(state) {
      state.paymentsUrl = undefined;
      state.createPaymentStatus = fetchingStatus.idle as FetchStatus;
      state.createSubscriptionResults = {};
      state.verifyCouponStatus = fetchingStatus.idle as FetchStatus;
      state.setupStatus = fetchingStatus.idle as FetchStatus;
      state.addOnPaymentStatus = fetchingStatus.idle as FetchStatus;
      state.verifyCouponResults = undefined;
      state.setupResult = undefined;
      state.addOnPaymentResult = undefined;

      return state;
    },
    updateCreditCardStatusToIdle(state) {
      state.updateCreditCardStatus = fetchingStatus.idle;
      state.updateCreditCardUrl = {};
    },
    cleanSubscriptions(state) {
      state.subscriptions = [];

      return state;
    },
    setExecuteSessionStatusToIdle(state) {
      state.executeSessionStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setPricingSessionStatusToIdle(state) {
      state.pricingStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setAddOnPaymentToIdle(state) {
      state.addOnPaymentStatus = fetchingStatus.idle as FetchStatus;
      state.addOnPaymentResult = undefined;
      return state;
    },
    setSubscriptionCancelToIdle(state) {
      state.cancelStatus = fetchingStatus.idle as FetchStatus;
      state.cancelResult = undefined;
      return state;
    },
    setCreatePaymentStatusToIdle(state) {
      state.createPaymentStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setVerifyCouponStatusToIdle(state) {
      state.verifyCouponStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    cleanCouponCode: (state) => {
      state.pricingModalProperties.coupon = "";
      return state;
    },
    updatePricingModalProperties: (state, action) => {
      state.pricingModalProperties = { ...state.pricingModalProperties, ...action.payload };
    },
    updateRedeemCodeToIdle: (state) => {
      state.redeemPartnershipCodeStatus = fetchingStatus.idle;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.createPaymentSessionRequest.fulfilled, (state, action) => {
      state.paymentsUrl = action.payload.url;
      state.createPaymentStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.createPaymentSessionRequest.pending, (state) => {
      state.createPaymentStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createPaymentSessionRequest.rejected, (state) => {
      state.createPaymentStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.executeSessionRequest.fulfilled, (state) => {
      state.executeSessionStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.executeSessionRequest.pending, (state) => {
      state.executeSessionStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.executeSessionRequest.rejected, (state) => {
      state.executeSessionStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.verifyCouponRequest.fulfilled, (state, action) => {
      state.verifyCouponStatus = fetchingStatus.succeeded as FetchStatus;
      state.verifyCouponResults = {
        isCouponValid: action.payload.is_valid,
        percentOff: action.payload.percent_off,
        planId: action.meta.arg.planId
      };
    });

    builder.addCase(asyncThunks.verifyCouponRequest.pending, (state) => {
      state.verifyCouponStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.verifyCouponRequest.rejected, (state) => {
      state.verifyCouponStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.createSubscriptionRequest.fulfilled, (state, action) => {
      state.createPaymentStatus = fetchingStatus.succeeded as FetchStatus;
      const { subscription_id, secret, payment_status } = action.payload;
      state.createSubscriptionResults = {
        secret,
        subscriptionId: subscription_id,
        paymentStatus: payment_status
      };
    });

    builder.addCase(asyncThunks.createSubscriptionRequest.pending, (state) => {
      state.createPaymentStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createSubscriptionRequest.rejected, (state) => {
      state.createPaymentStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.setupRequest.pending, (state) => {
      state.setupStatus = fetchingStatus.loading as FetchStatus;
    });

    builder.addCase(asyncThunks.setupRequest.fulfilled, (state, action) => {
      state.setupStatus = fetchingStatus.succeeded as FetchStatus;
      state.setupResult = action.payload;
    });
    builder.addCase(asyncThunks.setupRequest.rejected, (state) => {
      state.setupStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getPricingAndPlansRequest.pending, (state) => {
      state.pricingStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getPricingAndPlansRequest.fulfilled, (state, action) => {
      state.pricingStatus = fetchingStatus.succeeded as FetchStatus;
      state.pricingAndPlans = action.payload.plans;
    });
    builder.addCase(asyncThunks.getPricingAndPlansRequest.rejected, (state) => {
      state.pricingStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getFeaturesPricingRequest.fulfilled, (state, action) => {
      state.featuresPricing = action.payload;
    });
    builder.addCase(asyncThunks.addOnPaymentRequest.pending, (state) => {
      state.addOnPaymentStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.addOnPaymentRequest.rejected, (state) => {
      state.addOnPaymentStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.addOnPaymentRequest.fulfilled, (state, action) => {
      state.addOnPaymentStatus = fetchingStatus.succeeded as FetchStatus;
      state.addOnPaymentResult = action.payload;
    });

    builder.addCase(asyncThunks.getCreditsRequest.fulfilled, (state, action) => {
      state.creditsStatus = action.payload;
    });
    builder.addCase(asyncThunks.getSubscriptionStatusRequest.fulfilled, (state, action) => {
      state.getSubscriptionStatus = fetchingStatus.succeeded;
      state.subscriptions = action.payload;
    });
    builder.addCase(asyncThunks.getSubscriptionStatusRequest.pending, (state) => {
      state.getSubscriptionStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getSubscriptionStatusRequest.rejected, (state) => {
      state.getSubscriptionStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.cancelSubscriptionRequest.fulfilled, (state, action) => {
      state.subscriptions = [action.payload];
      state.cancelStatus = fetchingStatus.succeeded;
      state.cancelResult = action.payload.result;
    });
    builder.addCase(asyncThunks.cancelSubscriptionRequest.pending, (state) => {
      state.cancelStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.cancelSubscriptionRequest.rejected, (state) => {
      state.cancelStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.updateCreditCardRequest.fulfilled, (state, action) => {
      state.updateCreditCardStatus = fetchingStatus.succeeded;
      state.updateCreditCardUrl = action.payload;
    });
    builder.addCase(asyncThunks.updateCreditCardRequest.pending, (state) => {
      state.updateCreditCardStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.updateCreditCardRequest.rejected, (state) => {
      state.updateCreditCardStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.getMinutesAnalyticsRequest.fulfilled, (state, action) => {
      state.minutesAnalyticsStatus = fetchingStatus.succeeded;
      state.minutesAnalytics = action.payload;
    });
    builder.addCase(asyncThunks.getMinutesAnalyticsRequest.pending, (state) => {
      state.minutesAnalyticsStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getMinutesAnalyticsRequest.rejected, (state) => {
      state.minutesAnalyticsStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.getAnalyticsMetadataRequest.fulfilled, (state, action) => {
      state.analyticsMetadataStatus = fetchingStatus.succeeded;
      state.analyticsMetadata = action.payload;
    });
    builder.addCase(asyncThunks.getAnalyticsMetadataRequest.pending, (state) => {
      state.analyticsMetadataStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getAnalyticsMetadataRequest.rejected, (state) => {
      state.analyticsMetadataStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.redeemPartnershipCodeRequest.fulfilled, (state) => {
      state.redeemPartnershipCodeStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.redeemPartnershipCodeRequest.pending, (state) => {
      state.redeemPartnershipCodeStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.redeemPartnershipCodeRequest.rejected, (state) => {
      state.redeemPartnershipCodeStatus = fetchingStatus.failed;
    });
  }
});

export default paymentsSlice.reducer;

export const paymentsActions = {
  createPaymentSessionRequest: asyncThunks.createPaymentSessionRequest,
  createSubscriptionRequest: asyncThunks.createSubscriptionRequest,
  executeSessionRequest: asyncThunks.executeSessionRequest,
  setupRequest: asyncThunks.setupRequest,
  verifyCouponRequest: asyncThunks.verifyCouponRequest,
  getPricingAndPlansRequest: asyncThunks.getPricingAndPlansRequest,
  getFeaturesPricingRequest: asyncThunks.getFeaturesPricingRequest,
  addOnPaymentRequest: asyncThunks.addOnPaymentRequest,
  getCreditsRequest: asyncThunks.getCreditsRequest,
  getSubscriptionStatusRequest: asyncThunks.getSubscriptionStatusRequest,
  cancelSubscriptionRequest: asyncThunks.cancelSubscriptionRequest,
  updateCreditCardRequest: asyncThunks.updateCreditCardRequest,
  getMinutesAnalyticsRequest: asyncThunks.getMinutesAnalyticsRequest,
  getAnalyticsMetadataRequest: asyncThunks.getAnalyticsMetadataRequest,
  redeemPartnershipCodeRequest: asyncThunks.redeemPartnershipCodeRequest,
  ...paymentsSlice.actions
};
