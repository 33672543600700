import { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { extractUserIdForPusher } from "app/utils/helpers";
import uiActions from "app/store/actions/ui.actions";

const PusherAutoTrainHandler = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuth();

  useEffect(() => {
    const handleUpdate = ({ status }: { status: string }) => {
      if (status === "ready") {
        dispatch(
          uiActions.setNotifications([
            {
              message: "Your new avatar is ready!"
            }
          ])
        );
      }
    };
    const userId = extractUserIdForPusher(user?.sub);
    const channelName = `${userId}`;
    const channel = pusher.subscribe(channelName);

    channel.bind("playground_auto_avatar", handleUpdate);
    return () => {
      if (channel) {
        channel.unbind("playground_auto_avatar", handleUpdate);
      }
    };
    //  its important to not have dependencies here ,
    //  because on each change use effect bind and unbind which can cause to miss messages
  }, []);

  return null;
};

export default PusherAutoTrainHandler;
