import React, { useEffect, useMemo, useRef, useState, forwardRef, MutableRefObject } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import { VideoFit } from "app/types/media";
import { FeatureFlag, PaletteColor } from "app/types";
import { hexToHsl, hexToHslWithAdjustedLightness } from "app/utils/helpers";
import { Button } from "@nextui-org/react";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useFlags } from "launchdarkly-react-client-sdk";

const StyledFlexRow = styled(H1_FlexRow)<{
  $isScaled: boolean;
  $isCropped: boolean;
}>`
  overflow: hidden;
  video {
    object-fit: ${({ $isScaled, $isCropped }) =>
      $isScaled ? "fill" : $isCropped ? "cover" : "contain"};
  }
`;

const StyledVideo = styled.video<{
  $objectPosition: string;
  $transform?: string;
  $isCentered?: boolean;
  $width: string;
  $height: string;
  $backgroundColorLeft?: string;
  $backgroundColorRight?: string;
  $padWidth: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: ${({ $transform, $isCentered }) => !$isCentered && $transform};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  max-height: ${({ $height }) => $height};
  object-position: ${({ $objectPosition, $isCentered }) =>
    $isCentered ? "50% 50%" : $objectPosition};
  background: ${({ $backgroundColorLeft, $backgroundColorRight, $isCentered }) =>
    $isCentered &&
    $backgroundColorLeft &&
    $backgroundColorRight &&
    `linear-gradient(
    to bottom,
    ${$backgroundColorLeft},
    ${$backgroundColorRight}
  )`};
  padding: ${({ $padWidth }) => `${$padWidth}px`};
`;

const VideoContainer = styled.div<{ $width: string; $height: string }>`
  position: relative;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

const VideoControlsFlexRow = styled(H1_FlexRow)`
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const ProgressBar = styled.input<{
  $progressColor?: string;
  $handleColor?: string;
  value: number;
  min: number;
  max: number;
}>`
  flex: 1;
  height: 4px;
  cursor: pointer;
  -webkit-appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: linear-gradient(
      to right,
      ${(props) => props.$progressColor || "#4CAF50"} 0%,
      ${(props) => props.$progressColor || "#4CAF50"}
        ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%,
      #52525b ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%,
      #52525b 100%
    );
    border-radius: 2px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${(props) => props.$handleColor || "#4CAF50"};
    margin-top: -4px;
    cursor: pointer;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    background: #52525b;
    border-radius: 2px;
  }

  &::-moz-range-progress {
    height: 4px;
    background: ${(props) => props.$progressColor || "#4CAF50"};
    border-radius: 2px;
  }

  &::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${(props) => props.$handleColor || "#4CAF50"};
    cursor: pointer;
    border: none;
  }
`;

interface VideoPreviewProps {
  cropAspectRatio: number;
  videoFit: VideoFit;
  padColor: PaletteColor | undefined;
  padWidth: number;
  originalWidth: number;
  originalHeight: number;
  disablePreview?: boolean;
  isVideo?: boolean;
  src: string;
  scaleSize: number;
  cropX: number;
  cropY: number;
  durationInFrames: number;
  trimStart: number;
  trimEnd: number;
  width: number;
  height: number;
  onClick?: () => void;
  onPlay: () => void;
  duration: number;
  onDurationInFrames: (frames: number) => void;
  onVideoLoaded: () => void;
  isVideoLoaded: boolean;
  currentTime?: number;
  onTimeUpdate?: (time: number) => void;
  showControls?: boolean;
  isPlaying?: boolean;
  onPause: () => void;
}

const VideoPreview = forwardRef<HTMLVideoElement, VideoPreviewProps>((props, videoRef) => {
  const animationFrameRef = useRef<number>();
  const [currentTime, setCurrentTime] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const flags = useFlags();

  const {
    cropAspectRatio,
    videoFit,
    padColor,
    originalWidth,
    originalHeight,
    src,
    scaleSize,
    cropX,
    cropY,
    width,
    height,
    padWidth,
    durationInFrames,
    trimStart,
    trimEnd,
    duration,
    onDurationInFrames,
    onVideoLoaded,
    isVideoLoaded,
    currentTime: externalCurrentTime,
    onTimeUpdate,
    showControls = true,
    isPlaying,
    onPlay: externalOnPlay,
    onPause: externalOnPause
  } = props;
  const fps = useMemo(() => durationInFrames / duration, []);
  const startFrom = useMemo(() => Math.floor(trimStart * fps), [trimStart, fps]);
  const endAt = useMemo(() => Math.floor(trimEnd * fps), [trimEnd, fps]);
  const multiplier = cropAspectRatio > 1 ? width : height;
  const divider = cropAspectRatio > 1 ? originalWidth : originalHeight;
  const objectPositionLeft = (cropX * multiplier) / divider;
  const objectPositionTop = (cropY * multiplier) / divider;
  const isVideoScaled = videoFit === VideoFit.Scale;
  const isVideoCropped = videoFit === VideoFit.Crop;
  const isVideoPadded = videoFit === VideoFit.Pad;
  const videoNode = (videoRef as MutableRefObject<HTMLVideoElement>)?.current;

  const padHsl = useMemo(() => {
    if (padColor) {
      return hexToHsl(padColor.color);
    }
  }, [padColor]);

  const padHslLightness = useMemo(() => {
    if (padColor) {
      return hexToHslWithAdjustedLightness(padColor.color, 30);
    }
  }, [padColor]);

  const padHslCssString = padHsl ? `hsl(${padHsl[0]}, ${padHsl[1]}%, ${padHsl[2]}%)` : undefined;
  const padHslLightnessCssString = padHslLightness
    ? `hsl(${padHslLightness[0]}, ${padHslLightness[1]}%, ${padHslLightness[2]}%)`
    : undefined;

  useEffect(() => {
    onDurationInFrames(durationInFrames);
  }, [durationInFrames]);

  // Convert frame numbers to seconds
  const startTimeInSeconds = useMemo(() => startFrom / fps, [startFrom, fps]);
  const endTimeInSeconds = useMemo(() => endAt / fps, [endAt, fps]);

  useEffect(() => {
    if (videoNode) {
      videoNode.currentTime = startTimeInSeconds;
      setCurrentTime(startTimeInSeconds);
    }
  }, [startTimeInSeconds, isVideoLoaded]);

  useEffect(() => {
    if (videoNode && externalCurrentTime !== undefined && !isPlaying) {
      videoNode.currentTime = externalCurrentTime;
      setCurrentTime(externalCurrentTime);
    }
  }, [externalCurrentTime]);

  useEffect(() => {
    if (isPlaying) {
      animationFrameRef.current = requestAnimationFrame(updateProgressBar);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isPlaying]);

  const updateProgressBar = () => {
    if (videoNode) {
      const current = videoNode.currentTime;
      setCurrentTime(current);
      onTimeUpdate?.(current);

      if (isPlaying && current < endTimeInSeconds) {
        animationFrameRef.current = requestAnimationFrame(updateProgressBar);
      } else if (current >= endTimeInSeconds) {
        videoNode.pause();
        videoNode.currentTime = startTimeInSeconds;
      }
    }
  };

  const handlePlayPause = () => {
    if (videoNode) {
      if (isPlaying) {
        videoNode.pause();
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      } else {
        if (videoNode.currentTime >= endTimeInSeconds) {
          videoNode.currentTime = startTimeInSeconds;
        }
        videoNode.play();
      }
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoNode) {
      const time = Number(e.target.value);
      videoNode.currentTime = time;
      setCurrentTime(time);
      onTimeUpdate?.(time);
    }
  };

  const handleMuteToggle = () => {
    if (videoNode) {
      videoNode.muted = !videoNode.muted;
      setIsMuted(!isMuted);
    }
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (videoNode) {
      if (isPlaying) {
        videoNode.play();
      } else {
        videoNode.pause();
      }
    }
  }, [isPlaying]);

  const handlePlayPauseExternal = () => {
    handlePlayPause();
    if (videoNode) {
      if (isPlaying) {
        externalOnPause();
      } else {
        externalOnPlay();
      }
    }
  };

  return (
    <H1_FlexRow justify="center" width="815px" height={`${height}px`} position="relative">
      <StyledFlexRow
        overflow="hidden"
        position="relative"
        width={`${width}px`}
        height={`${height}px`}
        $isScaled={isVideoScaled}
        $isCropped={isVideoCropped}
      >
        <VideoContainer $width={`${width}px`} $height={`${height}px`}>
          <StyledVideo
            ref={videoRef}
            onCanPlay={onVideoLoaded}
            onPlay={() => {
              externalOnPlay();
            }}
            onPause={() => {
              externalOnPause();
            }}
            $transform={`scale(${scaleSize})`}
            src={src}
            $width={`${width}px`}
            $height={`${height}px`}
            $isCentered={isVideoScaled || isVideoPadded}
            $objectPosition={`left -${objectPositionLeft}px top -${objectPositionTop}px`}
            $backgroundColorLeft={padHslCssString}
            $backgroundColorRight={padHslLightnessCssString}
            $padWidth={isVideoPadded && flags[FeatureFlag.paddingWidth] ? padWidth / 2 : 0}
          />
        </VideoContainer>
      </StyledFlexRow>
      {showControls && (
        <VideoControlsFlexRow
          position="absolute"
          width="100%"
          padding="0 15px"
          height="50px"
          gap="10px"
          align="center"
        >
          <Button
            isDisabled={!isVideoLoaded}
            onClick={handlePlayPauseExternal}
            isIconOnly
            variant="light"
            startContent={<i className={isPlaying ? "fas fa-pause" : "fas fa-play"} />}
          />
          <ProgressBar
            type="range"
            min={startTimeInSeconds}
            max={endTimeInSeconds}
            step="0.01"
            value={currentTime}
            onChange={handleSeek}
            $progressColor="#FAFAFA"
            $handleColor="#F5F5F5"
          />
          <Button
            onClick={handleMuteToggle}
            isIconOnly
            variant="light"
            startContent={<i className={isMuted ? "fas fa-volume-mute" : "fas fa-volume-up"} />}
          />
          <H1_TextSmall color="white">
            {formatTime(currentTime)} / {formatTime(endTimeInSeconds)}
          </H1_TextSmall>
        </VideoControlsFlexRow>
      )}
    </H1_FlexRow>
  );
});

VideoPreview.displayName = "VideoPreview";

export default VideoPreview;
