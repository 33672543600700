import React, { useEffect, useMemo } from "react";
import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import styled, { useTheme } from "styled-components";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import {
  Dimensions,
  Draft,
  FeatureFlag,
  FitOptions,
  FoldersContext,
  LayoutAsset,
  LayoutCategory,
  MediaCategory,
  PatchOperation
} from "app/types";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import AdobeExpressEditor from "app/components/editor/sideDrawers/SceneDrawer/ImageFit/AdobeExpressEditor";
import useMediaLibraryUploader from "app/pages/mediaLibrary/useMediaLibraryUploader";
import {
  base64ToFile,
  cropImageDimensions,
  fileStackFetchImageSizePromise
} from "app/utils/helpers";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store/store";
import { useFlags } from "launchdarkly-react-client-sdk";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Progress } from "antd";

const StyledFlexDrawer = styled(FlexDrawer)`
  position: relative;
  padding: ${({ active }) => (active ? "20px 24px" : "0")};
`;

const StyledProgress = styled(Progress)`
  .ant-progress-text {
    color: ${({ theme }) => theme.gray11};
  }
`;
export interface ImageFitDrawerProps {
  right: string;
  width: string;
}

const ImageFitDrawerAdobe = ({ right, width }: ImageFitDrawerProps) => {
  const { editorDrawerOpen, originContext, openDrawer } = useDrawer();
  const { scene, sceneId } = useSelectedScene();
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const theme = useTheme();

  const draft: Draft = useAppSelector((state: RootState) => state.drafts.currentDraft);
  const filestackPolicy = useAppSelector((state: RootState) => state.media.filestackReadPolicy);

  const draftId = draft?.id as string;
  const { media, attributeType } = originContext;
  const mediaAttributeType = attributeType as MediaCategory;

  const assetKey = media?.key || "";
  const currentValue =
    mediaAttributeType === "visual"
      ? scene?.attributes?.visual?.[assetKey]?.preset_override?.media_url || ""
      : scene?.attributes?.media?.[assetKey]?.url || "";
  const resolvedPreviewUrl = currentValue;
  const { displayUrls } = useDisplayUrls([resolvedPreviewUrl]);
  const isDrawerActive = editorDrawerOpen === Drawer.AdobeImageFit;
  // todo
  console.log("isDrawerActive Drawer.AdobeImageFit", isDrawerActive);
  const currentLayoutMedia: LayoutAsset | undefined = (
    scene?.layout?.assets[attributeType as LayoutCategory] as LayoutAsset[]
  )?.find((theMedia: LayoutAsset) => theMedia.key === assetKey);
  const isImageFitDisplayed =
    flags[FeatureFlag.imageFit] && currentLayoutMedia?.restrictions?.ratio_w;
  const {
    executeImport,
    importLoading,
    progress,
    uploadedMedia,
    clearUserUploadedMedia,
    resetUploader
  } = useMediaLibraryUploader({
    context: FoldersContext.media
  });

  useEffect(() => {
    if (uploadedMedia) {
      (async () => {
        const handle = uploadedMedia.url.split("/").pop() as string;
        const dimensions: Dimensions | undefined = await getImageWidthAndHeight(handle);
        let value: any = {
          url: uploadedMedia.url,
          modification: dimensions
            ? {
                fit: FitOptions.smart_crop,
                width: dimensions.width as number,
                height: dimensions.height as number
              }
            : undefined
        };

        if (attributeType === "visual") {
          value = {
            ...value,
            preset_override: {
              ...scene?.attributes?.visual?.[assetKey]?.preset_override,
              media_url: uploadedMedia.url,
              type: "media"
            }
          };
        }

        const operations: PatchOperation[] = [
          {
            op: "replace",
            path: `attributes.${attributeType}.${assetKey}`,
            value
          }
        ];

        await dispatch(
          scenesActions.patchSceneRequest({
            draftId,
            sceneId,
            operations
          })
        );
        resetUploader();
        clearUserUploadedMedia();
        openDrawer(Drawer.ImageFit, {
          backDrawer: Drawer.Scene,
          media,
          mediaUrl: value.url,
          attributeType
        });
      })();
    }
  }, [uploadedMedia]);

  const previewUrl = useMemo(() => {
    if (displayUrls[resolvedPreviewUrl] && isDrawerActive) {
      const preview = displayUrls[resolvedPreviewUrl];
      return preview.url;
    }
    return undefined;
  }, [displayUrls, resolvedPreviewUrl, isDrawerActive]);

  useEffect(() => {
    if (!isDrawerActive) {
      resetUploader();
      clearUserUploadedMedia();
    }
  }, [isDrawerActive]);

  const getImageWidthAndHeight = async (handle: string): Promise<Dimensions | undefined> => {
    if (isImageFitDisplayed) {
      const dimensions: Dimensions = await fileStackFetchImageSizePromise(handle, filestackPolicy);
      const aspectRatio =
        (currentLayoutMedia?.restrictions?.ratio_w || 1) /
        (currentLayoutMedia?.restrictions?.ratio_h || 1);
      const { width, height } = cropImageDimensions(
        dimensions.width,
        dimensions.height,
        aspectRatio
      );
      return { width, height };
    }
    return undefined;
  };

  const handleSaveImage = (editedImageUrl: string) => {
    const file = base64ToFile(editedImageUrl, new Date().toString());
    executeImport([file]);
  };

  const onCancel = () => {
    openDrawer(Drawer.ImageFit, {
      backDrawer: Drawer.Scene,
      media,
      mediaUrl: currentValue,
      attributeType
    });
  };
  if (!isDrawerActive) {
    return null;
  }

  return (
    <StyledFlexDrawer
      right={right}
      active={isDrawerActive}
      width={width}
      className="image-fit-drawer"
    >
      <H1_FlexColumn gap="30px" overflow="auto">
        {isDrawerActive && (
          <AdobeExpressEditor
            imageUrl={previewUrl || ""}
            onSave={handleSaveImage}
            onCancel={onCancel}
          />
        )}
      </H1_FlexColumn>
      <ConditionalRender condition={importLoading}>
        <H1_FlexColumn
          position="absolute"
          width="100%"
          height="100%"
          align="center"
          justify="center"
        >
          <StyledProgress
            width={80}
            type="circle"
            trailColor={theme.blue1}
            strokeColor={theme.blue4}
            percent={progress || 0}
            showInfo
          />
        </H1_FlexColumn>
      </ConditionalRender>
    </StyledFlexDrawer>
  );
};

export default ImageFitDrawerAdobe;
