import { last } from "lodash-es";
import * as Sentry from "@sentry/react";
import Config from "app/config/Config";
import {
  FetchStatus,
  FilestackPolicy,
  InternalScene,
  LayoutAsset,
  LayoutAssetPreset,
  LayoutCategory,
  PaletteColor,
  PaletteColorKey,
  SynthesisMarkupLanguage,
  SynthesisMarkupLanguageType
} from "app/types";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { MediaType } from "app/types/media";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { SyntheticEvent } from "react";
import { Emotion } from "app/types/character";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import { IntlShape } from "react-intl";

export const arabicRx = /^[\u0621-\u064A\040]+/i;
export const urlRx =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
export const wwwRx = /\w+@\w+\.\w+/;
export const zoomUrlRx = /^https:\/\/[a-zA-Z0-9]+\.zoom\.us\/j\/\d+(?:\?pwd=\w+)?$/;
export const fileStackRx = /cdn\.filestackcontent\.com/g;
export const unsplashRx = /(images\.unsplash\.com)|(api\.unsplash\.com)/g;
export const unsplashRxApi = /api\.unsplash\.com/g;
export const SUPPORT_EMAIL = "mailto:support@hourone.ai";
export const SINGLE_GRID_ITEM_CLASSNAME = "single-grid-item";
export const SINGLE_GRID_ITEM_LOWER_AREA_CLASSNAME = "single-grid-item-lower-area";

export const imagePlaceholder =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQ2IiBoZWlnaHQ9IjEzOCIgdmlld0JveD0iMCAwIDI0NiAxMzgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cmVjdCB3aWR0aD0iMjQ2IiBoZWlnaHQ9IjEzOCIgZmlsbD0iI0Y1RjVGNSIvPgogICAgPHBhdGggZD0iTTExNC45MDYgNjEuNzgxMkMxMTMuMzI0IDYxLjc4MTIgMTEyLjA5NCA2My4wNzAzIDExMi4wOTQgNjQuNTkzOEMxMTIuMDk0IDY2LjE3NTggMTEzLjMyNCA2Ny40MDYyIDExNC45MDYgNjcuNDA2MkMxMTYuNDMgNjcuNDA2MiAxMTcuNzE5IDY2LjE3NTggMTE3LjcxOSA2NC41OTM4QzExNy43MTkgNjMuMDcwMyAxMTYuNDMgNjEuNzgxMiAxMTQuOTA2IDYxLjc4MTJaTTEzMi4xOTEgNTYuNjI1SDEwOS42OTFDMTA3LjY0MSA1Ni42MjUgMTA1Ljk0MSA1OC4zMjQyIDEwNS45NDEgNjAuMzc1Vjc5LjEyNUMxMDUuOTQxIDgxLjIzNDQgMTA3LjY0MSA4Mi44NzUgMTA5LjY5MSA4Mi44NzVIMTMyLjE5MUMxMzQuMjQyIDgyLjg3NSAxMzUuOTQxIDgxLjIzNDQgMTM1Ljk0MSA3OS4xMjVWNjAuMzc1QzEzNS45NDEgNTguMzI0MiAxMzQuMzAxIDU2LjYyNSAxMzIuMTkxIDU2LjYyNVpNMTMzLjEyOSA3OC43NzM0TDEyNS4xMDIgNjcuODc1QzEyNC45MjYgNjcuNTgyIDEyNC42MzMgNjcuNDA2MiAxMjQuMjgxIDY3LjQwNjJDMTIzLjg3MSA2Ny40MDYyIDEyMy41NzggNjcuNTgyIDEyMy4zNDQgNjcuODc1TDExNy4xMzMgNzYuMzEyNUwxMTQuOTY1IDczLjYxNzJDMTE0LjczIDczLjM4MjggMTE0LjQzOCA3My4yMDcgMTE0LjA4NiA3My4yMDdDMTEzLjczNCA3My4yMDcgMTEzLjQ0MSA3My4zODI4IDExMy4yMDcgNzMuNjE3MkwxMDguODEyIDc5LjEyNUwxMDguNzU0IDYwLjM3NUMxMDguNzU0IDU5LjkwNjIgMTA5LjIyMyA1OS40Mzc1IDEwOS42OTEgNTkuNDM3NUgxMzIuMTkxQzEzMi43MTkgNTkuNDM3NSAxMzMuMTI5IDU5LjkwNjIgMTMzLjEyOSA2MC4zNzVWNzguNzczNFoiIGZpbGw9IiM4QzhDOEMiLz4KPC9zdmc+";

export const isArabicText = (text: string): boolean => {
  const testArabic = arabicRx.test(text);
  return testArabic;
};

export const containsUrl = (inputString: string): boolean => {
  let match;

  while ((match = urlRx.exec(inputString)) !== null) {
    // Check if the matched URL is part of an email address
    const emailCheckIndex = Math.max(0, urlRx.lastIndex - match[0].length - 1);
    if (!wwwRx.test(inputString.substring(emailCheckIndex, urlRx.lastIndex))) {
      return true; // Found a URL that's not part of an email
    }
  }

  return false;
};

export const isValidZoomMeetingUrl = (url: string): boolean => {
  return true;
  if (!url) {
    return false;
  }
  return zoomUrlRx.test(url);
};

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
export const hasSomeParentTheClass = (element: Element, className: string) => {
  return !!(
    (element.classList.length && element.classList.contains(className)) ||
    element.closest(`.${className}`)
  );
};

const loadingScripts: Record<string, boolean> = {};
export const loadScript = (url: string, id: string, onLoad: CallableFunction) => {
  let script = document.getElementById(id);
  const gotScript = !!script;
  if (!gotScript) {
    loadingScripts[id] = true;
    script = document.createElement("script") as HTMLScriptElement;
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", url);
    script.setAttribute("id", id);
    // @ts-ignore handels Property does not exist on type HTMLElement
    if (script.readyState) {
      // @ts-ignore handels Property does not exist on type HTMLElement
      script.onreadystatechange = () => {
        // @ts-ignore handels Property does not exist on type HTMLElement
        if (script.readyState === "loaded" || script.readyState === "complete") {
          // @ts-ignore handels Property does not exist on type HTMLElement
          script.onreadystatechange = null;
          onLoad();
          loadingScripts[id] = false;
        }
      };
    } else {
      script.onload = () => {
        onLoad();
        loadingScripts[id] = false;
      };
    }

    const { head } = document;
    head.insertBefore(script, head.firstElementChild);
  } else if (!loadingScripts[id]) {
    onLoad();
  }
};

export const unLoadScript = (id: string) => {
  const script = document.getElementById(id);
  if (script) {
    script.remove();
  }
};

export const mappedAlphaHex = (alpha: any) => {
  const mapped = {
    100: "FF",
    95: "F2",
    90: "E6",
    85: "D9",
    80: "CC",
    75: "BF",
    70: "B3",
    65: "A6",
    60: "99",
    55: "8C",
    50: "80",
    45: "73",
    40: "66",
    35: "59",
    30: "4D",
    25: "40",
    20: "33",
    15: "26",
    10: "1A",
    5: "0D",
    0: "00"
  };

  // @ts-ignore excppect curr and prev will be numbers
  const closestAlpha = Object.keys(mapped).reduce((prev, curr) =>
    // @ts-ignore excppect curr and prev will be numbers
    Math.abs(curr - alpha) < Math.abs(prev - alpha) ? curr : prev
  );
  // @ts-ignore Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
  return mapped[closestAlpha];
};

export const fileStackFetchMetaPromise = (
  url: string,
  fileStackPolicy: FilestackPolicy
): Promise<{ filename: string; mimetype?: string }> => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const api = addPolicyToUrlIfFileStackMedia(
    `https://www.filestackapi.com/api/file/${last(url.split("/"))}/metadata`,
    fileStackPolicy
  );
  return fetch(api)
    .then((res) => {
      return res.json();
    })
    .catch(() => ({ mimetype: "image" }));
};

export const fileStackFetchImageSizePromise = (
  handle: string,
  filestackPolicy: FilestackPolicy
) => {
  // eslint-disable-next-line max-len
  const api = `https://cdn.filestackcontent.com/${Config.filestackApi}/security=policy:${filestackPolicy?.policy},signature:${filestackPolicy?.signature}/imagesize/${handle}`;
  return fetch(api).then((res) => {
    return res.json();
  });
};

export const addUnsplashUtms = (url?: string) => {
  if (!url || !url.match(unsplashRx)) return url;
  const utlObj = new URL(url);
  utlObj.searchParams.set("utm_source", "Reals");
  utlObj.searchParams.set("utm_medium", "referral");
  return `${utlObj.toString()}`;
};

export const getUnsplashDisplayUrl = (imageId: string) => {
  return fetch(`https://api.unsplash.com/photos/${imageId}?client_id=${Config.unsplashClient}`)
    .then((res) => res.json())
    .then((res) => addUnsplashUtms(res?.urls?.regular));
};

export const convertAssetsUrlIfUnsplash = (urls: string[]) => {
  return urls.map(async (url) => {
    if (!url || !url.match(unsplashRxApi)) {
      return url;
    }
    const imageId = url.split("/")[4];
    // eslint-disable-next-line no-return-await
    return await getUnsplashDisplayUrl(imageId).then((res) => res);
  });
};

export const convertAssetsUrlIfUnsplashToThumb = async (url: string) => {
  if (!url || !url.match(unsplashRxApi)) {
    return url;
  }
  const imageId = url.split("/")[4];
  // eslint-disable-next-line no-return-await
  return await fetch(
    `https://api.unsplash.com/photos/${imageId}?client_id=${Config.unsplashClient}`
  )
    .then((res) => res.json())
    .then((res) => addUnsplashUtms(res?.urls?.thumb));
};

const isVimeoPlayer = (url: string) => {
  // for pexels support
  if (url.includes("player.vimeo")) {
    return true;
  }
  return false;
};
export const getMediaTypes = async (
  urls: string[] = [],
  filestackPolicy: FilestackPolicy
): Promise<{ mimetype?: string; filename?: string }[]> => {
  const fetchPromises = urls.map(async (url) => {
    return url?.match(fileStackRx)
      ? fileStackFetchMetaPromise(url, filestackPolicy)
      : { mimetype: isVimeoPlayer(url) ? MediaType.video : MediaType.image };
  });

  return Promise.all(fetchPromises);
};

export const fetchAllAuth0UserData = async (accessToken: string) => {
  return fetch(`${Config.serverUrl}/v2/users/me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "content-type": "application/json",
      Accept: "application/json"
    }
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error(`unexpected status code ${res.status} from users/me`);
    }
    return res.json();
  });
};

export const addPolicyToUrlIfFileStackMedia = (url: string, fileStackPolicy: FilestackPolicy) => {
  if (url && url.includes("filestack") && fileStackPolicy && !url.includes("?policy=")) {
    // todo dig and check who use it for making sure filestackpolicy is passed
    if (url.includes("wf://")) {
      return AddPolicyForDocResult(fileStackPolicy, url);
    }
    return `${url}?policy=${fileStackPolicy?.policy}&signature=${fileStackPolicy?.signature}`;
  }
  return url;
};

/* todo its problem because we cant send it withput policy - we need to handle it on server if this is the case. need to add the case on server side */

export const AddPolicyForDocResult = (filestackPolicy: FilestackPolicy, url: string) => {
  // eslint-disable-next-line max-len
  const apiUrl = `/${Config.filestackApi}`;
  return url.replace(
    apiUrl,
    `${apiUrl}/security=policy:${filestackPolicy?.policy},signature:${filestackPolicy?.signature}`
  );
};
export const addPolicyToUrlsIfFileStackMedia = (
  urls: string[],
  fileStackPolicy: FilestackPolicy
) => {
  return urls.map((url) => addPolicyToUrlIfFileStackMedia(url, fileStackPolicy));
};

export const fetchingStatus = {
  idle: "idle" as FetchStatus,
  loading: "loading" as FetchStatus,
  succeeded: "succeeded" as FetchStatus,
  failed: "failed" as FetchStatus
};

type Task = () => void;

export const createFunctionQueue = () => {
  const queue: Task[] = [];
  let isProcessing = false;

  const enqueue = (func: Task) => {
    queue.push(func);
    if (!isProcessing) {
      processQueue();
    }
  };

  const processQueue = async () => {
    isProcessing = true;
    while (queue.length > 0) {
      const currentFunction = queue.shift();
      if (currentFunction) {
        currentFunction();
        await delay(3000); // Wait for 3 seconds
      }
    }
    isProcessing = false;
  };

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return { enqueue };
};
const functionQueue = createFunctionQueue();
export const executeDownload = async (url: string, downloadName?: string) => {
  const task = () => {
    const link = document.createElement("a");
    link.setAttribute("target", "_self");
    link.setAttribute("href", url);
    link.setAttribute("download", "");
    if (downloadName) {
      link.setAttribute("filename", downloadName);
      link.setAttribute("download", downloadName);
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  functionQueue.enqueue(task);
};

export const blobToFile = (blob: Blob, fileName: string, fileType?: string): File => {
  const options: FilePropertyBag = { type: fileType || blob.type };
  const file = new File([blob], fileName, options);
  return file;
};

export const executeBlobDownload = async (url: string, downloadName?: string) => {
  // declare the function
  const image = await fetch(url);
  const reader = new FileReader();
  const blobed = await image.blob();
  reader.readAsDataURL(blobed);
  reader.onloadend = () => {
    executeDownload(reader.result?.toString() || url, downloadName);
  };
  reader.onerror = () => {
    executeDownload(url, downloadName);
  };
};

export async function generateThumbnail(
  file: File,
  url: string
): Promise<{ thumbnail: string; thumbnailType: MediaType }> {
  const base64 = await getBase64(file);
  const isVideo = String(file.type).includes("video");
  let thumbnailType: MediaType = MediaType.image;
  let thumbnailContent = base64;
  if (isVideo) {
    try {
      thumbnailContent = await createThumbnail(base64);
    } catch (err) {
      Sentry.captureException(err);
      thumbnailType = MediaType.video;
      thumbnailContent = url;
    }
  }
  return {
    thumbnail: thumbnailContent,
    thumbnailType
  };
}

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const base64ToFile = (base64: string, fileName: string): File => {
  const [prefix, base64Data] = base64.split(",");
  const mimeMatch = prefix.match(/:(.*?);/);
  const mimeType = mimeMatch ? mimeMatch[1] : "application/octet-stream";

  // Decode base64 string to binary data
  const binaryData = atob(base64Data);
  const arrayBuffer = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    arrayBuffer[i] = binaryData.charCodeAt(i);
  }

  // Create a File object
  return new File([arrayBuffer], fileName, { type: mimeType });
};

async function createThumbnail(base64: string): Promise<string> {
  const video = document.createElement("video");
  video.src = base64;
  const ready = new Promise<void>((resolve, reject) => {
    video.addEventListener("loadeddata", function () {
      resolve();
    });
    video.addEventListener("error", function (error) {
      reject(error);
    });
  });
  video.load();
  await ready;

  const imageReady = new Promise<string>((resolve, reject) => {
    video.addEventListener(
      "seeked",
      function () {
        try {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext("2d");
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);

          const image = context?.canvas.toDataURL("image/jpeg");
          resolve(image as string);
        } catch (err) {
          reject(err);
        }
      },
      false
    );
  });

  video.currentTime = Math.round(video.duration / 2);
  return imageReady;
}

export const dateSorter = (a: string, b: string) =>
  parseISO(a).getTime() > parseISO(b).getTime() ? -1 : 1;

export const getTimeAgo = (editedAt?: string) => {
  if (!editedAt) {
    return "";
  }
  try {
    return formatDistanceToNow(parseISO(editedAt), { addSuffix: true });
  } catch (err) {
    console.error("error", err);
    return editedAt;
  }
};

export const durationToMMSS = (duration?: number): string => {
  if (duration === undefined) {
    return "00:00";
  }
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(((duration / 60) % 1) * 60);

  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const durationToMMSSss = (duration: number): string => {
  if (duration === undefined) {
    return "";
  }
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  const milliseconds = Math.floor((duration % 1) * 100);

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  const formattedMilliseconds = milliseconds.toString().padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
};

export const numToMinutesSeconds = (num: number): string => {
  const minutes = Math.floor(num);
  const seconds = Math.floor((num % 1) * 60);
  return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
};

export const getTimeByFormat = (date: string, formatType: string) => {
  return format(parseISO(date), formatType);
};

export const getFormattedDate = (date: string) => {
  return format(parseISO(date), "MM/dd/yyyy");
};

export const discountPrice = (originalPrice: number, percentOff: number): number => {
  let price: number = originalPrice;
  if (percentOff) {
    price = parseFloat((((100 - percentOff) * price) / 100).toFixed(2));
  }

  return price;
};

export const getVideoThumbnailWithPlayCircle = async (reqId: string): Promise<string> => {
  return `https://video-portal-og.vercel.app/api/dynamic-image?id=${reqId}&env=${
    Config.env === "local" ? "dev" : Config.env
  }`;
};

export const createThumbnailBase64 = async (url: string): Promise<string> => {
  const imageData = await fetch(url);

  const reader = new FileReader();
  const blob = await imageData.blob();
  reader.readAsDataURL(blob);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
    reader.onerror = (err) => {
      reject(err);
    };
  });
};

export const isMediaPlaying = (media?: HTMLMediaElement): boolean => {
  return (
    !!media && !!(media.currentTime > 0 && !media.paused && !media.ended && media.readyState > 2)
  );
};

export const onImageError = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = fallbackPlaceholder;
};

export const onVideoError = ({ currentTarget }: SyntheticEvent<HTMLVideoElement, Event>) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = fallbackPlaceholder;
};

// eslint-disable-next-line consistent-return
export const sdPromptValidate = async (value: string) => {
  try {
    const encodedText = new URLSearchParams();
    encodedText.append("text", value);
    const phraser = await fetch(`https://api.phraser.tech/language/analyze`, {
      method: "POST",
      body: JSON.stringify({
        text: value,
        language: "EN"
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (phraser.ok) {
      const { data } = await phraser.json();
      if (data?.options?.analysis) {
        const vals = data.options.analysis;
        return {
          length: vals.is_length_ok,
          negation: vals.is_negation_ok,
          noun: vals.is_noun_ok,
          adjective: vals.is_acomp_ok,
          dependentObj: vals.is_pobj_ok
        };
      }
    } else {
      const hugface = await fetch(`https://coref.huggingface.co/coref?${encodedText.toString()}`);
      if (hugface.ok) {
        const { coreFres } = await hugface.json();
        return {
          length: true,
          negation: true,
          noun: coreFres.mentions.length > 1,
          adjective: coreFres.is_acomp_ok,
          dependentObj: coreFres.is_pobj_ok
        };
      }
      throw new Error("not valid");
    }
  } catch (e) {
    throw new Error("not valid");
  }
};

export const responsiveSlides = (maxSlides: number, width: number): Record<string, any> => {
  // This section determines the number of slides.
  // There are max of `maxSlides` slides, and each slide width include space is `width`
  const res: Record<string, any> = {};
  for (let i = 2; i < maxSlides; i += 1) {
    res[`${i * width}`] = {
      slidesPerView: i
    };
  }
  return res;
};

export const getImageMeta = (url: string, cb: (image?: HTMLImageElement, error?: any) => void) => {
  const image = new Image();
  image.onload = () => cb(image, undefined);
  image.onerror = (err) => cb(undefined, err);
  image.src = url;
};

export const cropImageDimensions = (
  originalWidth: number,
  originalHeight: number,
  targetAspectRatio: number
) => {
  if (!originalWidth || !originalHeight || !targetAspectRatio) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  let newWidth: number;
  let newHeight: number;

  const originalRatio = originalWidth / originalHeight;
  if (originalRatio > targetAspectRatio) {
    newWidth = Math.round(originalHeight * targetAspectRatio);
    newHeight = originalHeight;
  } else {
    newWidth = originalWidth;
    newHeight = Math.round(originalWidth / targetAspectRatio);
  }

  const x = (originalWidth - newWidth) / 2;
  const y = (originalHeight - newHeight) / 2;

  return {
    x,
    y,
    width: newWidth,
    height: newHeight
  };
};

export const isValidEmail = (emailAddress: string) => {
  return /\S+@\S+\.\S+/.test(emailAddress);
};

export const isValidHowDidYouHearAboutUs = (text: string): boolean => {
  const validTextRegex = /^[a-zA-Z\s.,-]+$/;

  return validTextRegex.test(text);
};

export const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const calculateTotalNumberOfGridElements = (
  width: number,
  height: number,
  widthGap: number,
  heightGap: number
) => {
  const gridElements = document.getElementsByClassName(SINGLE_GRID_ITEM_CLASSNAME);
  const gridLowerAreaElements = document.getElementsByClassName(
    SINGLE_GRID_ITEM_LOWER_AREA_CLASSNAME
  );
  if (gridElements?.length === 0 || gridLowerAreaElements?.length === 0) {
    return 0;
  }
  const singleElementWidth = gridElements[0].clientWidth;
  const singleElementWidthGap = convertRemToPixels(widthGap);
  let totalWidth = singleElementWidth;
  let counterWidth = 0;
  while (totalWidth < width) {
    totalWidth += singleElementWidth + singleElementWidthGap;
    counterWidth += 1;
  }

  const singleElementHeight = (singleElementWidth * 9) / 16 + gridLowerAreaElements[0].clientHeight;
  const singleElementHeightGap = convertRemToPixels(heightGap);
  let totalHeight = singleElementHeight;
  let counterHeight = totalHeight < height ? 0 : 1;
  while (totalHeight < height) {
    totalHeight += singleElementHeight + singleElementHeightGap;
    counterHeight += 1;
  }
  return counterHeight * counterWidth;
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};

export const checkImageExists = async (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      resolve(true);
    };

    img.onerror = function () {
      reject(new Error("failed to load"));
    };

    img.src = url;
  });
};
export const checkUrlForImg = async (url: string) => {
  try {
    await checkImageExists(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const getTextOutOfSML = (sml: SynthesisMarkupLanguage[] | undefined) => {
  if (!sml) {
    return "";
  }
  const text: string = sml.reduce((prev, cur) => {
    if (cur.type === SynthesisMarkupLanguageType.text) {
      return prev + cur.text;
    }
    if (cur.type === SynthesisMarkupLanguageType.pronunciation) {
      return prev + cur.pronounced;
    }
    return prev;
  }, "");

  return text.trim();
};

export const formatDate = (dateString?: string) => {
  if (!dateString) {
    return "";
  }
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  } as const;

  const locale = window.navigator.language;
  return new Intl.DateTimeFormat(locale, options).format(new Date(dateString));
};

export const daysUntilDate = (targetDate?: string) => {
  if (!targetDate) {
    return "";
  }
  const today = new Date();
  const target = new Date(targetDate);

  const timeDifference = target.getTime() - today.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysDifference;
};

export const retryPromiseFunction = (
  promiseFunction: () => any,
  maxAttempts: number,
  intervalSec: number,
  currentAttempt = 0
) => {
  if (currentAttempt >= maxAttempts) {
    return Promise.reject(new Error("Max retry attempts reached"));
  }

  return promiseFunction()
    .then((result: any) => {
      return result; // Resolve the promise if successful
    })
    .catch((error: any) => {
      console.warn(`Attempt ${currentAttempt + 1} failed:`, error);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            retryPromiseFunction(promiseFunction, maxAttempts, intervalSec, currentAttempt + 1)
          );
        }, intervalSec * 1000);
      });
    });
};

export const resizeImage = (file: File): Promise<{ fileResult: File; isResized: boolean }> => {
  const maxSize = 3840; // Maximum width or height for 4K resolution

  if (!file.type.startsWith("image/")) {
    return Promise.resolve({ fileResult: file, isResized: false });
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width: number = img.width;
      const height: number = img.height;
      if (width < maxSize && height < maxSize) {
        resolve({ fileResult: file, isResized: false });
      } else {
        let newWidth: number, newHeight: number;
        if (width > height) {
          if (width > maxSize) {
            newWidth = maxSize;
            newHeight = (maxSize / width) * height;
          } else {
            newWidth = width;
            newHeight = height;
          }
        } else {
          if (height > maxSize) {
            newHeight = maxSize;
            newWidth = (maxSize / height) * width;
          } else {
            newWidth = width;
            newHeight = height;
          }
        }

        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
        if (ctx) {
          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          canvas.toBlob((blob) => {
            if (blob) {
              // Create a new blob with the original filename
              const blobWithFilename = new File([blob], file.name, { type: file.type });
              resolve({ fileResult: blobWithFilename, isResized: true });
            } else {
              reject(new Error("Unable to create a blob from the canvas."));
            }
          }, file.type);
        }
      }
    };
  });
};

export const extractUserIdForPusher = (id?: string) => {
  if (id?.includes("|")) {
    return id?.split("|")[1];
  } else {
    return id;
  }
  return id;
};

export const isLocalStorageSupported = () => {
  try {
    if (!localStorage) {
      throw new Error("local storage not supported");
    }
    localStorage.setItem("test", "test");
    const value = localStorage.getItem("test");
    if (value !== "test") {
      throw new Error("failed test local storage");
    }
    localStorage.removeItem("test");
    return true;
  } catch (err) {
    console.error("local storage not supported", err);
    Sentry.captureException(err, { extra: { description: "local storage not supported" } });

    return false;
  }
};

export const extractColorsBySize = (brandColors: PaletteColor[], sample: PaletteColor[] = []) => {
  const size = sample.length;
  let result;
  if (brandColors.length === size) {
    result = [...brandColors];
  }
  if (size === 4) {
    result = [...brandColors].splice(0, 4);
  }
  if (size === 3) {
    result = [...brandColors].splice(1, 3);
  }
  if (size === 2) {
    result = [...brandColors].splice(1, 2);
  }
  if (size === 1) {
    result = [brandColors[0]];
  }

  return result?.map((paletteColor, index) => {
    return {
      color: paletteColor.color,
      key: sample[index].key as PaletteColorKey
    };
  });
};

export const getVariableColor = (str: string, colorsList?: string[]): string => {
  const colors: string[] = colorsList || [
    "#E3FCE6",
    "#FFE7CA",
    "#DCDDFF",
    "#FAD4DE",
    "#BCFAC5",
    "#FFC580",
    "#AAAAFF",
    "#F397AE",
    "yellow",
    "orange"
  ];
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }

  const index = hash % colors.length;

  return "hsl(" + ((137.5 * index) % 360) + "deg, 80%, 80%)";
};

export const getAttributeValueBySceneOrLayout = (
  scene: InternalScene | undefined,
  asset: LayoutAsset | undefined,
  attributeType: LayoutCategory,
  attribute: keyof LayoutAssetPreset | "url"
) => {
  if (!asset) {
    return "";
  }

  if (attributeType === "media") {
    switch (attribute) {
      case "url":
        return scene?.attributes?.media?.[asset.key]?.url || "";
      default:
        return "";
    }
  }

  if (attributeType === "visual") {
    switch (attribute) {
      case "url":
      case "media_url":
        return asset?.preset?.media_url === undefined
          ? ""
          : scene?.attributes?.visual?.[asset.key]?.preset_override?.media_url ||
              asset.preset?.media_url ||
              "";
      case "solid_color":
        return asset?.preset?.solid_color === undefined
          ? ""
          : scene?.attributes?.visual?.[asset.key]?.preset_override?.solid_color ||
              asset?.preset?.solid_color ||
              "";
      case "gradient_colors":
        return asset?.preset?.gradient_colors === undefined
          ? ""
          : scene?.attributes?.visual?.[asset.key]?.preset_override?.gradient_colors ||
              asset?.preset?.gradient_colors ||
              "";
      default:
        return "";
    }
  }

  if (attributeType === "text") {
    switch (attribute) {
      case "font_level":
        return (
          scene?.attributes?.text?.[asset.key]?.preset_override?.font_level ||
          asset?.preset?.font_level ||
          ""
        );
      case "alignment_horizontal":
        return (
          scene?.attributes?.text?.[asset.key]?.preset_override?.alignment_horizontal ||
          asset?.preset?.alignment_horizontal ||
          ""
        );
      case "alignment_vertical":
        return (
          scene?.attributes?.text?.[asset.key]?.preset_override?.alignment_vertical ||
          asset?.preset?.alignment_vertical ||
          ""
        );
      case "box_color":
        return asset?.preset?.box_color === undefined
          ? ""
          : scene?.attributes?.text?.[asset.key]?.preset_override?.box_color ||
              asset?.preset?.box_color ||
              "";
      case "text_color":
        return asset?.preset?.text_color === undefined
          ? ""
          : scene?.attributes?.text?.[asset.key]?.preset_override?.text_color ||
              asset?.preset?.text_color ||
              "";
      case "text_color_gradient":
        return asset?.preset?.text_color_gradient === undefined
          ? []
          : scene?.attributes?.text?.[asset.key]?.preset_override?.text_color_gradient ||
              asset?.preset?.text_color_gradient ||
              [];
      case "box_color_gradient":
        return asset?.preset?.box_color_gradient === undefined
          ? []
          : scene?.attributes?.text?.[asset.key]?.preset_override?.box_color_gradient ||
              asset?.preset?.box_color_gradient ||
              [];
      case "text_color_type":
        return asset?.preset?.text_color_type === undefined
          ? ""
          : scene?.attributes?.text?.[asset.key]?.preset_override?.text_color_type ||
              asset?.preset?.text_color_type ||
              "";
      case "box":
        return asset?.preset?.box === undefined
          ? false
          : scene?.attributes?.text?.[asset.key]?.preset_override?.box !== undefined
          ? scene?.attributes?.text?.[asset.key]?.preset_override?.box
          : asset?.preset?.box !== undefined
          ? asset?.preset?.box
          : false;
      case "box_color_type":
        return asset?.preset?.box_color_type === undefined
          ? ""
          : scene?.attributes?.text?.[asset.key]?.preset_override?.box_color_type ||
              asset?.preset?.box_color_type ||
              "";
      case "overflow":
        return (
          scene?.attributes?.text?.[asset.key]?.preset_override?.overflow ||
          asset?.preset?.overflow ||
          ""
        );
      default:
        return "";
    }
  }

  return "";
};

export const removePaletteColorKeyPrefix = (paletteColorKey: string) => {
  const parts = paletteColorKey.split("_");
  return parts.length > 1 ? parts[1] : paletteColorKey;
};

export const replaceFlagSeparator = (local?: string) => {
  return local?.split("-")[0].replace("_", "-");
};

export const removeDuplicatedStrings = (arr: string[]) => {
  const result = arr.map((strItem) => strItem.toLowerCase().trim());
  return Array.from(new Set(result).values());
};

export const hexToRgb = (hex: string): [number, number, number] => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  let r = 0,
    g = 0,
    b = 0;
  // Parse the hex color
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length >= 6) {
    // ignore alpha
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }

  return [r, g, b];
};

export const rgbToHsl = (r: number, g: number, b: number): [number, number, number] => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
};

export const hexToHsl = (hex: string): [number, number, number] => {
  const [r, g, b] = hexToRgb(hex);
  return rgbToHsl(r, g, b);
};

export const hexToHslWithAdjustedLightness = (
  hex: string,
  lightnessAdjustment: number
): [number, number, number] => {
  const [r, g, b] = hexToRgb(hex);
  // eslint-disable-next-line prefer-const
  let [h, s, l] = rgbToHsl(r, g, b);
  // Adjust lightness by the specified amount and clamp it between 0 and 100
  l = Math.min(100, Math.max(0, (l + lightnessAdjustment) % 100));
  return [h, s, l];
};

export const getAudioFileDuration = async (file: File) => {
  const url = URL.createObjectURL(file);

  return new Promise((resolve) => {
    const audio = document.createElement("audio");
    audio.muted = true;
    const source = document.createElement("source");
    source.src = url; //--> blob URL
    audio.preload = "metadata";
    audio.appendChild(source);
    audio.onloadedmetadata = () => {
      resolve(audio.duration);
    };
  });
};

export const getPaletteColorsAsDictionary = (
  paletteColors?: PaletteColor[]
): Partial<Record<PaletteColorKey, string>> => {
  const paletteObject: Partial<Record<PaletteColorKey, string>> = {};

  if (!paletteColors) {
    return {};
  }
  paletteColors.forEach((paletteColor) => {
    paletteObject[paletteColor.key] = paletteColor.color;
  });

  return paletteObject;
};

export const isSceneAssetGenerationLoading = (
  sceneAssetsGenerationStatus: Record<string, FetchStatus>,
  sceneId: string,
  key: string
): boolean => {
  return (
    sceneAssetsGenerationStatus[`${sceneId}-attributes.visual.${key}`] === fetchingStatus.loading
  );
};

export const getEmotionEmojiText = (intl: IntlShape, emotion?: string) => {
  const { formatMessage } = intl;
  if (!emotion) {
    return "";
  }
  switch (emotion) {
    case Emotion.Friendly:
    case Emotion.Smiling:
      return formatMessage(characterDrawerMessages.emojiFriendly);
    case Emotion.Neutral:
    case Emotion.Natural:
    case Emotion.Subtle:
      return formatMessage(characterDrawerMessages.emojiNeutral);
    case Emotion.Motionless:
      return formatMessage(characterDrawerMessages.emojiMotionLess);
    case Emotion.Active:
      return formatMessage(characterDrawerMessages.emojiActive);
    case Emotion.Energetic:
      return formatMessage(characterDrawerMessages.emojiEnergetic);
    case Emotion.Expressive:
      return formatMessage(characterDrawerMessages.emojiExpressive);
    default:
      return emotion;
  }
};
