import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import { ThemeMode } from "app/utils/theme";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { cgnActions } from "app/store/slices/cgn.slice";
import { CGNType, ChannelsTabs, DEFIANCE, LiveStream, LiveVideoMode, Location } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CGNHeader from "app/pages/CGN/CGNHeader";
import { SwiperSlide } from "swiper/react";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { getTHumbnail } from "./utils";
import MuxPlayerElement from "@mux/mux-player";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import useVideo from "app/hooks/useVideo";
import CGNStreamsRow from "app/pages/CGN/CGNStreamsRow";
import { Button } from "@nextui-org/react";
import useSmallScreen from "app/hooks/useSmallScreen";
import LivePlayer from "app/pages/CGN/LivePlayer";
import { isIOS, isMobile } from "react-device-detect";

const MuxFlexColumn = styled(H1_FlexColumn)<{ $hidden: boolean; $isFullScreen: boolean }>`
  & {
    display: ${({ $hidden }) => $hidden && "none"};
  }
  > div {
    height: auto;
    flex: 1 0 auto;
    justify-content: ${({ $isFullScreen }) => ($isFullScreen ? "center" : "flex-start")};
  }
  media-theme {
    display: ${({ $hidden }) => $hidden && "none"};
    justify-content: ${({ $isFullScreen }) => ($isFullScreen ? "center" : "flex-start")};
    flex: ${({ $isFullScreen }) => $isFullScreen && "1"};
    display: flex;
    media-controller {
      position: absolute;
      bottom: 0;
    }
    media-control-bar {
      margin-bottom: ${({ $isFullScreen }) => ($isFullScreen ? "70px" : "")};
    }
  }
  mux-player {
    display: ${({ $hidden }) => $hidden && "none"};
    max-height: 100vh;
    --controls: none;
  }
  mux-player::part(--controls) {
    display: none !important;
  }
`;

const AnimatedHeader = styled(H1_FlexColumn)<{ isHidden?: boolean }>`
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: ${(props) => (props.isHidden ? "translateY(-50px)" : "translateY(0)")};
  z-index: 2;
  position: fixed;
`;

const CategoryHeaderMiddle = styled(H1_TextMiddle)`
  font-weight: 500;
  color: #e5e5e5;
`;

const AnimatedFlexColumn = styled(H1_FlexColumn)<{ isHidden?: boolean }>`
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: ${(props) => (props.isHidden ? "translateY(230px)" : "translateY(0)")};
`;

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
  height: auto;
  min-height: 100vh;
`;

const TopCornerAbsoluteFlexColumn = styled(H1_FlexColumn)<{ isHidden?: boolean }>`
  position: absolute;
  top: 100px;
  right: 20px;
  z-index: 100;
  background-color: rgba(220, 220, 220, 0.6);
  border-radius: 30px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: ${(props) => (props.isHidden ? "translateX(35px)" : "translateX(0)")};
`;

const VideoBackground = styled.video`
  position: absolute;
  width: 100%;
  transform: rotateY(180deg);
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  padding-bottom: 5px;
  height: 200px;
  margin: 0 30px;
  .swiper-slide {
    width: calc(180px * 16 / 9);
  }
  .swiper-button-prev,
  .swiper-button-next {
    justify-content: center;
    top: 94px;
  }
  && .swiper-button-prev {
    left: 30px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }

  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }
`;

const LiveFlexRow = styled(H1_FlexRow)`
  border-radius: 14px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  top: 10px;
  left: 10px;
  padding: 0 10px;
  opacity: 0.7;
`;

const ViewersRow = styled(H1_FlexRow)`
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #3b3b3bcc;
`;
const SwiperContainerFlexColumn = styled(H1_FlexColumn)`
  position: relative;
  &:hover {
    ${ViewersRow} {
      opacity: 1;
      z-index: 2;
    }
  }
`;

const ThumbnailImage = styled.img`
  border-radius: 15px;
  width: calc(180px * 16 / 9);
  height: 175px;
  transition: all 0.3s ease-in-out;
  filter: brightness(90%);
  &:hover {
    cursor: pointer;
    filter: brightness(120%);
  }
`;

const RedIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f31260;
`;

const MOUSE_UNMOVE_TIME = 2000;
const CGNPage = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [videoMode, setVideoMode] = useState<LiveVideoMode>(LiveVideoMode.FullScreen);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isStartingVideo, setIsStartingVideo] = useState<boolean>(true);
  const [isMuxPlayerAvailable, setIsMuxPlayerAvailable] = useState<boolean>(false);
  const [currentVodId, setCurrentVodId] = useState<string | undefined>(undefined);
  const [isHidden, setIsHidden] = useState(false);
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);
  const [activeLiveTab, setActiveLiveTab] = useState<ChannelsTabs | DEFIANCE>(ChannelsTabs.news);
  const playerRef = useRef<MuxPlayerElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSmallScreen } = useSmallScreen();
  const vodId = searchParams.get("vod_id");
  const channelParam = searchParams.get("channel");
  const channels = useAppSelector((state) => state.cgn.channels);
  const streamersVod = useAppSelector((state) => state.cgn.streamersVod);
  const getCGNStatus = useAppSelector((state) => state.cgn.getCGNStatus);
  const getCGNStreamersVodStatus = useAppSelector((state) => state.cgn.getCGNStreamerVodStatus);
  const currentChannel = useAppSelector((state) =>
    state.cgn.channels.find((channel) => channel.name === activeLiveTab)
  );
  const CGNInternalUse = useAppSelector((state) => state.cgn.CGNInternalUse);

  const cgnType = currentChannel?.type;
  const isCGNAsset = cgnType === CGNType.asset;
  const isCGNLive = cgnType === CGNType.live;
  const isCgnLoading = getCGNStatus === fetchingStatus.loading;
  const isVodLoading = getCGNStreamersVodStatus === fetchingStatus.loading;
  const isLoading = isVodLoading || isCgnLoading;
  const isVideoModePlayer = videoMode === LiveVideoMode.Player;
  const isVideoModeFullScreen = videoMode === LiveVideoMode.FullScreen;

  const streams = useMemo(() => [...channels, ...streamersVod], [channels, streamersVod]);

  const currentStream = useMemo(
    () => streams.find((stream) => currentVodId && stream.id && stream.id === currentVodId),
    [streams, currentVodId]
  );

  const nextStream = useMemo(() => {
    let currentStreamIndex = streams.findIndex(
      (stream) => currentVodId && stream.id && stream.id === currentVodId
    );
    // If the stream wasn't found, then a channel was selected, search by channel playback id
    if (currentStreamIndex === -1) {
      currentStreamIndex = streams.findIndex(
        (stream: LiveStream) => stream.playback_id === currentChannel?.playback_id
      );
    }
    const nextStreamIndex =
      currentStreamIndex === -1 || currentStreamIndex === streams.length - 1
        ? 0
        : currentStreamIndex + 1;
    return streams[nextStreamIndex];
  }, [streams, currentVodId, currentChannel?.playback_id]);

  const currentViewPlaybackId = currentStream?.playback_id;

  const { isPlaying, isMuted, isFullScreen, handleFullScreen, handleMuteUnmute, handlePlayPause } =
    useVideo({
      videoNode: playerRef.current as HTMLVideoElement,
      visible: isMuxPlayerAvailable,
      isAutoplay: true,
      isDefaultMuted: isStartingVideo,
      isDefaultFullScreen: false,
      containerNode: containerRef.current as HTMLDivElement,
      videoId: currentViewPlaybackId || currentChannel?.playback_id
    });

  useEffect(() => {
    dispatch(cgnActions.getCGNRequest(CGNInternalUse));
    dispatch(cgnActions.getCGNStreamerVodRequest(CGNInternalUse));
  }, [CGNInternalUse]);

  useEffect(() => {
    if (channelParam) {
      setActiveLiveTab(channelParam as ChannelsTabs | DEFIANCE);
      setIsStartingVideo(false);
      setVideoMode(LiveVideoMode.FullScreen);
    }
  }, [channelParam]);
  useEffect(() => {
    if (vodId) {
      setCurrentVodId(vodId);
      setIsStartingVideo(false);
      setVideoMode(LiveVideoMode.FullScreen);
    }
  }, [vodId]);
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsLoadingFinished(true);
      }, 500);
    }
  }, [isLoading]);

  // Because the isStartingVideo is conditional, the playerRef.current doesn't load on time, and the useVideo doesn't get the videoNode
  useEffect(() => {
    if (!isStartingVideo) {
      setTimeout(() => {
        setIsMuxPlayerAvailable(true);
      }, 100);
    }
  }, [isStartingVideo]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === "Space" && !isModalOpen) {
        if (playerRef.current) {
          const player = playerRef.current;
          if (player.paused) {
            player.play();
          } else {
            player.pause();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (playerRef?.current) {
      if (currentViewPlaybackId) {
        const parsedChapters = currentStream.chapters.map((chapter) => {
          const { start_time, title } = chapter;
          return { startTime: start_time, value: title };
        });

        if (parsedChapters.length > 0) {
          // @ts-ignore - ignore endtime
          playerRef.current.addChapters(parsedChapters);
        }
      } else if (currentChannel) {
        const parsedChapters = currentChannel.chapters.map((chapter) => {
          const { start_time, title } = chapter;
          return { startTime: start_time, value: title };
        });

        if (parsedChapters.length > 0) {
          // @ts-ignore - ignore endtime
          playerRef.current.addChapters(parsedChapters);
        }
      }
    }
  }, [currentViewPlaybackId, currentChannel]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isSmallScreen) {
      return;
    }

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setIsHidden(false);
      timeoutId = setTimeout(() => {
        if (!isScrolled) {
          setIsHidden(true);
        }
      }, MOUSE_UNMOVE_TIME);
    };

    const handleMouseMove = () => {
      if (isVideoModePlayer || isScrolled) {
        return;
      }
      resetTimer();
    };

    // Start initial timer
    resetTimer();

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isVideoModePlayer, isScrolled]);

  const onPlayVideo = async (
    name: ChannelsTabs,
    currId?: string,
    shouldHandleFullScreen = true
  ) => {
    if (isVideoModePlayer) {
      if (playerRef.current) {
        playerRef.current.pause();
      }
      if (currId) {
        navigate(`${Location.Live}/${name}?vod_id=${currId}`);
      } else {
        navigate(`${Location.Live}/${name}`);
      }
    } else if (isVideoModeFullScreen) {
      setIsStartingVideo(false);
      if (currId) {
        setCurrentVodId(currId);
      } else {
        setCurrentVodId(undefined);
        setActiveLiveTab(name);
      }

      if (playerRef.current && shouldHandleFullScreen) {
        onFullScreen();
      }
    }
  };

  const onChangingTab = (name: ChannelsTabs | DEFIANCE) => {
    if (channelParam) {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.delete("vod_id");
      updatedParams.delete("channel");
      setSearchParams(updatedParams);
    }
    setIsStartingVideo(false);
    setActiveLiveTab(name);
    setCurrentVodId(undefined);
  };

  const onChangeVideoMode = (videoMode: LiveVideoMode) => {
    setVideoMode(videoMode);
    if (!isStartingVideo && videoMode === LiveVideoMode.Player) {
      navigate(`${Location.Live}/${activeLiveTab}?vod_id=${currentVodId}`);
    }
  };

  const onClickNextStream = () => {
    if (nextStream) {
      onPlayVideo(nextStream.name as ChannelsTabs, nextStream.id, false);
      if (playerRef.current && isSmallScreen) {
        playerRef.current.pause();
        setTimeout(() => {
          playerRef.current?.play();
        }, 1000);
      }
    }
  };

  const onFullScreen = async () => {
    if (isIOS && isMobile) {
      const muxPlayerEl = playerRef.current;
      if (!muxPlayerEl || !muxPlayerEl.media) {
        return;
      }

      // Access the native video element, so we will have iphone api
      const videoElement = muxPlayerEl.media?.nativeEl;
      if (videoElement) {
        try {
          await videoElement.play();
          // @ts-ignore - iphone api
          if (videoElement.webkitEnterFullscreen) {
            // @ts-ignore - iphone api
            videoElement.webkitEnterFullscreen();
          }
        } catch (error: any) {
          console.error("iOS fullscreen error:", error);
        }
      }
    } else {
      handleFullScreen();
    }
  };

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsScrolled(event.currentTarget.scrollTop > 0);
    setIsHidden(false);
  };

  return (
    <BackgroundFlexColumn
      overflow="auto"
      position="relative"
      gap="28px"
      width="100%"
      flex="1"
      onScroll={handleScroll}
    >
      <AnimatedHeader isHidden={isHidden} flex="0 0 80px" width="100%">
        <CGNHeader
          videoMode={videoMode}
          isMainPage
          activeTab={activeLiveTab}
          onChangeActiveTab={onChangingTab}
          onChangeVideoMode={onChangeVideoMode}
          setIsModalOpen={setIsModalOpen}
          isScrolled={isScrolled}
        />
      </AnimatedHeader>
      <H1_FlexColumn gap="20px" position="absolute" width="100%" overflow="hidden" height="100vh">
        <ConditionalRender condition={isStartingVideo}>
          <VideoBackground
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/tvShow_gordon_v02.mp4"
            autoPlay
            loop
            muted
            playsInline
          />
        </ConditionalRender>
        <ConditionalRender condition={!isStartingVideo}>
          <TopCornerAbsoluteFlexColumn isHidden={isHidden} gap="10px">
            <Button
              size="sm"
              className="rounded-full"
              variant="light"
              isIconOnly
              startContent={<i className={isPlaying ? "fas fa-pause" : "fas fa-play"} />}
              onClick={handlePlayPause}
            />
            <Button
              size="sm"
              variant="light"
              isIconOnly
              startContent={<i className={isMuted ? "fas fa-volume-slash" : "fas fa-volume"} />}
              onClick={handleMuteUnmute}
            />
            <Button
              size="sm"
              variant="light"
              isIconOnly
              startContent={<i className="fas fa-expand" />}
              onClick={onFullScreen}
            />
            <Button
              size="sm"
              className="rounded-full"
              variant="light"
              isIconOnly
              startContent={<i className="fas fa-forward" />}
              onClick={onClickNextStream}
            />
          </TopCornerAbsoluteFlexColumn>
        </ConditionalRender>
        <MuxFlexColumn
          $isFullScreen={isFullScreen}
          $hidden={isStartingVideo}
          ref={containerRef}
          flex="1 0 100vh"
          position="relative"
          height="100%"
        >
          <LivePlayer
            isFullScreen={isFullScreen}
            isMuted={isMuted}
            isCGNAsset={isCGNAsset}
            isCGNLive={isCGNLive}
            ref={playerRef}
            playbackId={
              currentViewPlaybackId ||
              currentChannel?.playback_id ||
              "f3uwVtnFPDHdGRrtR01RsfSUG4FO8ndEWTzvIGKdo80100" // MUST have a default id for the mux to render
            }
            onNext={onClickNextStream}
            onFullScreen={onFullScreen}
          />
        </MuxFlexColumn>
      </H1_FlexColumn>
      <H1_FlexColumn padding="0 0 30% 0" margin="0 0 20px 0" width="100%" overflow="hidden" />
      <ConditionalRender condition={!isLoadingFinished}>
        <H1_FlexRow gap="28px">
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
          <StyledSkeletonButton $width="320px" $height="180px" active block $borderRadius="5px" />
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={isLoadingFinished}>
        <AnimatedFlexColumn flex="0 0 auto" isHidden={isHidden}>
          <H1_FlexColumn flex="0 0 auto" padding="20px 0">
            <H1_FlexRow position="relative" margin="0 0 20px 30px">
              <CategoryHeaderMiddle fontSize={isSmallScreen ? "18px" : "1.4vw"}>
                Latest streams
              </CategoryHeaderMiddle>
            </H1_FlexRow>
            <H1_FlexRow position="relative" padding="0 20px 0 0" width="100%" flex="1 0 auto">
              <StyledSwiper
                allowTouchMove={false}
                navigation
                speed={700}
                spaceBetween={28}
                slidesPerView="auto"
                slidesPerGroup={isSmallScreen ? 1 : 4}
                lazy
              >
                {streams.map((stream: LiveStream) => (
                  <SwiperSlide key={stream.id || stream.playback_id}>
                    <SwiperContainerFlexColumn gap="5px">
                      <ConditionalRender condition={!!stream.thumbnail}>
                        <ThumbnailImage
                          src={stream.thumbnail}
                          onClick={() => onPlayVideo(stream.name as ChannelsTabs, stream.id)}
                        ></ThumbnailImage>
                      </ConditionalRender>
                      <ConditionalRender condition={!stream.thumbnail}>
                        <H1_FlexRow position="relative">
                          <ThumbnailImage
                            onClick={() => onPlayVideo(stream.name as ChannelsTabs, stream.id)}
                            src={getTHumbnail(stream)}
                          />
                          <LiveFlexRow height="28px" position="absolute" gap="10px" align="center">
                            <RedIcon />
                            <H1_TextSmall fontSize="14px" color="white">
                              LIVE
                            </H1_TextSmall>
                          </LiveFlexRow>
                        </H1_FlexRow>
                      </ConditionalRender>
                      <ViewersRow>
                        <ConditionalRender condition={!!stream?.viewers}>
                          <H1_TextSmall color="white">{stream?.viewers} viewers</H1_TextSmall>
                        </ConditionalRender>
                      </ViewersRow>
                      <H1_TextSmall color="white">{stream?.title}</H1_TextSmall>
                    </SwiperContainerFlexColumn>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </H1_FlexRow>
          </H1_FlexColumn>
        </AnimatedFlexColumn>
        <CGNStreamsRow
          isHidden={isHidden}
          title="News"
          onPlayVideo={onPlayVideo}
          name={ChannelsTabs.news}
        />
        <CGNStreamsRow
          isHidden={isHidden}
          title="Podcast"
          onPlayVideo={onPlayVideo}
          name={ChannelsTabs.podcast}
        />
        <CGNStreamsRow
          isHidden={isHidden}
          title="Entertainment"
          onPlayVideo={onPlayVideo}
          name={ChannelsTabs.entertainment}
        />
        <CGNStreamsRow
          isHidden={isHidden}
          title="Talk Show"
          onPlayVideo={onPlayVideo}
          name={ChannelsTabs.talk_show}
        />
        <CGNStreamsRow
          isHidden={isHidden}
          title="Technology"
          onPlayVideo={onPlayVideo}
          name={ChannelsTabs.technology}
        />
        <CGNStreamsRow
          isHidden={isHidden}
          title="Public"
          onPlayVideo={onPlayVideo}
          name={ChannelsTabs.public}
        />
      </ConditionalRender>
    </BackgroundFlexColumn>
  );
};

export default CGNPage;
