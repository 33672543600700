import { BarVisualizer, TrackReferenceOrPlaceholder } from "@livekit/components-react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";

const StyledBarVisualizer = styled(BarVisualizer)`
  > span {
    width: 4px;
    background-color: #d4d4d8;
  }
`;
const BackgroundFlexRow = styled(H1_FlexRow)<{ $backgroundColor: string }>`
  background: linear-gradient(0deg, rgba(94, 94, 246, 0) 0%, rgba(145, 145, 251, 0.4) 100%);
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 12px;
`;
export const AudioInputTile = ({
  trackRef,
  color
}: {
  trackRef: TrackReferenceOrPlaceholder;
  color: string;
}) => {
  return (
    <BackgroundFlexRow
      $backgroundColor={color}
      height="54px"
      width="100%"
      align="center"
      justify="center"
      gap="8px"
    >
      <StyledBarVisualizer
        trackRef={trackRef}
        className="h-full w-full flex flex-row gap-2.5 justify-center items-center"
        barCount={34}
        options={{ minHeight: 0 }}
      />
    </BackgroundFlexRow>
  );
};
