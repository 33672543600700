import PusherFramePreviewHandler from "app/hoc/Pusher/PusherFramePreviewHandler";
import PusherVideoProgressHandler from "app/hoc/Pusher/PusherVideoProgressHandler";
import PusherDownloadVideoFormatHandler from "app/hoc/Pusher/PusherDownloadVideoFormatHandler";
import PusherDownloadVideoScormHandler from "app/hoc/Pusher/PusherDownloadVideoScormHandler";
import PusherMobileCharacterHandler from "app/hoc/Pusher/PusherMobileCharacterHandler";
import PusherCloneVoiceHandler from "app/hoc/Pusher/PusherCloneVoiceHandler";
import PusherVideoWizardHandler from "app/hoc/Pusher/PusherVideoWizardHandler";
import PusherSceneAugmentationHandler from "app/hoc/Pusher/PusherSceneAugmentationHandler";
import AudioPreviewHandler from "app/hoc/Pusher/PusherAudioPreviewV2";
import PusherPlaygroundHandler from "app/hoc/Pusher/PusherPlayground";
import PusherTranslationHandler from "app/hoc/Pusher/PusherTranslationHandler";
import PusherAvatarSession from "app/hoc/Pusher/PusherAvatarSession";
import PusherCreditsHandler from "app/hoc/Pusher/PusherCreditsHandler";
import PusherPlanChanged from "app/hoc/Pusher/PusherPlanChanged";
import PusherDraftGenerator from "app/hoc/Pusher/PusherDraftGenerator";
import PusherSummary from "app/hoc/Pusher/PusherSummary";
import PusherCGN from "app/hoc/Pusher/PusherCGN";
import PusherCGNNextVideo from "app/hoc/Pusher/PusherCGNNextVideo";
import PusherMediaGenerator from "app/hoc/Pusher/PusherMediaGenerator";
import PusherAutoTrainHandler from "app/hoc/Pusher/PusherAutoTrainHandler";

const PusherHandler = () => {
  return (
    <>
      <PusherSceneAugmentationHandler />
      <PusherFramePreviewHandler />
      <PusherDownloadVideoFormatHandler />
      <PusherDownloadVideoScormHandler />
      <PusherVideoProgressHandler />
      <PusherMobileCharacterHandler />
      <PusherCloneVoiceHandler />
      <PusherVideoWizardHandler />
      <AudioPreviewHandler />
      <PusherPlaygroundHandler />
      <PusherTranslationHandler />
      <PusherAvatarSession />
      <PusherCreditsHandler />
      <PusherDraftGenerator />
      <PusherPlanChanged />
      <PusherSummary />
      <PusherAutoTrainHandler />
      <PusherCGN />
      <PusherCGNNextVideo />
      <PusherMediaGenerator />
    </>
  );
};

export default PusherHandler;
