import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import TitleComponent from "app/hoc/WithHeaderTitle";

import Profile from "app/pages/profile/Profile";
import pricingMessages from "app/pages/pricing/messages";
import EditingDraftPage from "app/pages/EditDraftPage/EditingDraftPage";
import editorMessages from "app/pages/EditDraftPage/messages";
import DraftsPage from "app/pages/DraftsPage/DraftsPage";
import Player from "app/pages/player/Player";
import playerMessages from "app/pages/player/messages";
import Page404 from "app/components/common/page404";
import WithPaddingContainer from "app/hoc/WithPaddingContainer";
import Pricing from "app/pages/pricing/Pricing";
import FoldersPage from "app/pages/FoldersPage/FoldersPage";
import HomePage from "app/pages/HomePage/HomePage";
import ProxyPage from "app/pages/HomePage/ProxyPage";

import { projectsMessages } from "app/pages/DraftsPage/messages";
import { SentryRoutes } from "app/services/sentry";
import { channelsMessages } from "app/pages/Channels/messages";
import { manageTeamMessages } from "app/pages/Teams/messages";
import { homePageMessages, mobileHomepage } from "app/pages/HomePage/messages";
import AuthenticationRedirect from "app/pages/HomePage/AuthenticationRedirect";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { acquisition } from "app/store/thunks/analyticsEvents.thunk";
import { Location } from "app/types";

import ChannelsPage from "app/pages/Channels/ChannelsPage";
import MembersPage from "app/pages/Teams/MembersPage";
import FullTemplatesPage from "app/pages/Templates/FullTemplatesPage";
import BriefsPage from "app/pages/Briefs/BriefsPage";
import MobileCreatePage from "app/pages/MobileCreatePage/MobileCreatePage";
import MobileFinishPage from "app/pages/MobileFinishPage/MobileFinishPage";
import PlayGroundPage from "app/pages/PlayGround/PlayGroundPage";
import PresentersPage from "app/pages/presenters/PresentersPage";
import { profileMessages } from "app/pages/profile/messages";
import CreateDigitalTwinPage from "app/pages/createDigitalTwinPage/CreateDigitalTwinPage";
import SummaryModal from "app/components/Summary/SummaryModal";
import OnBoardingV2Page from "app/pages/onBoardingV2Page/onBoardingV2Page";
import WorkflowSubmissionPage from "app/pages/WorkflowSubmissionPage/WorkflowSubmissionPage";
import WorkflowsPage from "app/pages/WorkflowsPage/WorkflowsPage";
import { integrationsMessages } from "app/pages/IntegrationsPage/messages";
import IntegrationsPage from "app/pages/IntegrationsPage/IntegrationsPage";
import PlaylistsPage from "app/pages/PlaylistsPage/PlaylistsPage";
import { playListMessages } from "app/pages/PlaylistsPage/messages";
import SinglePlaylistPage from "app/pages/PlaylistsPage/SinglePlaylistPage";
import MsLandingPage from "app/pages/MsLandingPage/MsLandingPage";
import TrashPage from "app/pages/Trash/TrashPage";
import WebhooksPage from "app/pages/webhooks/WebhooksPage";
import WebhookEditPage from "app/pages/webhooks/WebhookEditPage";
import CGNPage from "app/pages/CGN/CGNPage";
import { cgnMessages } from "app/pages/CGN/messages";
import CGNStreamPage from "app/pages/CGN/CGNStreamPage";
import RealTimePage from "app/pages/RealTime/RealTimePage";

const AuthenticatedRouterComp = () => {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector((state) => state.user.preferences);
  const showOnboardingV2 = preferences.show_on_boarding_survey_v2;
  useEffect(() => {
    if (showOnboardingV2) {
      dispatch(acquisition());
    }
  }, [showOnboardingV2]);

  return showOnboardingV2 ? (
    <SentryRoutes>
      <Route
        path="onboarding"
        element={
          <>
            <OnBoardingV2Page />
            <TitleComponent intlDef={editorMessages.limitedEditorHeadline} />
          </>
        }
      />
      <Route path={Location.Home} element={<Navigate to={Location.OnBoardingV2} />} />
      <Route path="*" element={<Navigate to={Location.OnBoardingV2} />} />
      <Route path="" element={<Navigate to={Location.OnBoardingV2} />} />
    </SentryRoutes>
  ) : (
    <SentryRoutes>
      <Route path="/">
        <Route
          path={Location.Home}
          element={
            <>
              <ProxyPage />
              <TitleComponent />
            </>
          }
        />
        <Route path="survey" element={<Navigate to="/on-boarding" />} />
        <Route
          path="authentication/redirect"
          element={
            <>
              <AuthenticationRedirect />
              <TitleComponent intlDef={homePageMessages.redirectPage} />
            </>
          }
        />
        <Route
          path="profile"
          element={
            <>
              <Profile />
              <TitleComponent intlDef={profileMessages.profileHeadline} />
            </>
          }
        />
        <Route
          path="integrations"
          element={
            <>
              <IntegrationsPage />
              <TitleComponent intlDef={integrationsMessages.headerTitle} />
            </>
          }
        />
        <Route
          path="workspaces/:workspaceId"
          element={
            <>
              <MembersPage />
              <TitleComponent intlDef={manageTeamMessages.headline} />
            </>
          }
        />
        <Route
          path="community"
          element={
            <>
              <ChannelsPage />
              <TitleComponent intlDef={channelsMessages.channelsHeadline} />
            </>
          }
        />
        <Route
          path="channels"
          element={
            <>
              <ChannelsPage />
              <TitleComponent intlDef={channelsMessages.channelsHeadline} />
            </>
          }
        />
        <Route
          path="channels/:channelName"
          element={
            <>
              <ChannelsPage />
              <TitleComponent intlDef={channelsMessages.channelsHeadline} />
            </>
          }
        />
        <Route
          path="live"
          element={
            <>
              <CGNPage />
              <TitleComponent intlDef={cgnMessages.headline} />
            </>
          }
        />
        <Route
          path="live/:id"
          element={
            <>
              <CGNStreamPage />
              <TitleComponent intlDef={cgnMessages.headline} />
            </>
          }
        />
        <Route
          path="playlists"
          element={
            <>
              <PlaylistsPage />
              <TitleComponent intlDef={playListMessages.headline} />
            </>
          }
        />
        <Route
          path="playlists/:playlistId"
          element={
            <>
              <SinglePlaylistPage />
              <TitleComponent intlDef={playListMessages.headline} />
            </>
          }
        />
        {/*<Route*/}
        {/*  path="mobile/prompt"*/}
        {/*  element={*/}
        {/*    <>*/}
        {/*      <MobilePromptPage />*/}
        {/*      <TitleComponent intlDef={mobileHomepage.mobilePromptPageHeader} />*/}
        {/*    </>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path="mobile/drafts/:draftId"
          element={
            <>
              <MobileCreatePage />
              <TitleComponent intlDef={mobileHomepage.mobilePromptPageHeader} />
            </>
          }
        />
        <Route
          path="mobile/finish"
          element={
            <>
              <MobileFinishPage />
              <TitleComponent intlDef={mobileHomepage.mobilePromptPageHeader} />
            </>
          }
        />
        <Route
          path="editor"
          element={
            <>
              <HomePage />
              <TitleComponent />
            </>
          }
        />
        <Route
          path="editor/:draftId"
          element={
            <>
              <EditingDraftPage />
              <TitleComponent intlDef={editorMessages.limitedEditorHeadline} />
            </>
          }
        />
        <Route
          path="pricing"
          element={
            <>
              <Pricing />
              <TitleComponent intlDef={pricingMessages.pricingHeadline} />
            </>
          }
        />
        <Route
          path="player/:reqId"
          element={
            <WithPaddingContainer hiddenSider>
              <Player />
              <TitleComponent intlDef={playerMessages.playerHeadline} />
            </WithPaddingContainer>
          }
        />
        <Route
          path="workflows/:workflowId"
          element={
            <>
              <WorkflowSubmissionPage />
              {/*<TitleComponent intlDef={playerMessages.playerHeadline} />*/}
            </>
          }
        />
        <Route
          path="folders"
          element={
            <>
              <FoldersPage />
              <TitleComponent intlDef={projectsMessages.headline} />
            </>
          }
        />
        <Route
          path="folders/:folderId"
          element={
            <>
              <DraftsPage />
              <TitleComponent intlDef={projectsMessages.specificProject} />
            </>
          }
        />
        <Route
          path="workflows"
          element={
            <>
              <WorkflowsPage />
              {/*<TitleComponent intlDef={projectsMessages.specificProject} />*/}
            </>
          }
        />
        <Route
          path="avatars/create"
          element={
            <>
              <CreateDigitalTwinPage />
              <TitleComponent intlDef={projectsMessages.specificProject} />
            </>
          }
        />
        <Route path="avatars" element={<PresentersPage />} />
        <Route path="templates" element={<FullTemplatesPage />} />
        <Route path="ms-marketplace" element={<MsLandingPage />} />
        <Route path="webhooks/:webhookId" element={<WebhookEditPage />} />
        <Route path="webhooks" element={<WebhooksPage />} />
        <Route path="playground" element={<PlayGroundPage />} />
        <Route path="real-time" element={<RealTimePage />} />
        <Route path="briefs" element={<BriefsPage />} />
        <Route path="trash" element={<TrashPage />} />
        <Route
          path="summary"
          element={
            <>
              <SummaryModal />
              <TitleComponent intlDef={projectsMessages.specificProject} />
            </>
          }
        />
        <Route path="webhooks/:webhookId" element={<WebhookEditPage />} />
        <Route path="webhooks" element={<WebhooksPage />} />

        <Route path="*" element={<Page404 />} />
        <Route path="" element={<Navigate to={Location.Home} />} />
      </Route>
    </SentryRoutes>
  );
};

export default AuthenticatedRouterComp;
