import React, { useEffect, useMemo } from "react";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import { AnimatePresence, motion } from "framer-motion";
import {
  useConnectionState,
  useLocalParticipant,
  useRoomInfo,
  useTracks,
  useVoiceAssistant,
  VideoTrack
} from "@livekit/components-react";
import { ConnectionState, LocalParticipant, Track } from "livekit-client";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { ConfigurationPanelItem } from "./ConfigurationPanelItem";
import { AudioInputTile } from "./AudioInputTile";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import { Location } from "app/types";
import { Link } from "react-router-dom";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import useTranscriptionMessages from "app/pages/RealTime/useTranscriptionMessages";

const fadeVariants = {
  visible: { opacity: 1, scale: 1, y: 0 },
  hidden: {
    opacity: 0,
    scale: 0.8,
    y: -20, // Moves up slightly
    transition: { duration: 1 }
  }
};

const RoundFlexColumn = styled(H1_FlexRow)`
  video {
    border-radius: 50%;
  }
`;

// @ts-ignore - weird Livekit stuff
const StyledVideoTrack = styled(VideoTrack)`
  height: 256px;
  width: 256px;
  filter: drop-shadow(12px -10px 42.57px #ababff);
`;
const FlexLink = styled(Link)`
  display: flex;
  svg {
    width: fit-content;
  }
`;

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: #18181b;
`;
const MotionFlexRow = styled(motion.div)<{ $align: string }>`
  display: flex;
  align-self: ${({ $align }) => $align};
`;
const TextBubbleContainer = styled(H1_FlexRow)<{ $isAssistant: boolean }>`
  min-height: 20px;
  max-width: 400px;
  border-radius: 12px;
  background: ${({ $isAssistant }) =>
    $isAssistant
      ? `linear-gradient(0deg, rgba(94, 94, 246, 0.00) 0%, rgba(145, 145, 251, 0.40) 100%)`
      : `linear-gradient(0deg, rgba(207, 207, 224, 0.00) 0%, rgba(63, 63, 70, 0.20) 100%)
`};
`;
const HeaderRow = styled(H1_FlexRow)`
  background-color: #18181b;
`;

const StyledHourOneLogo = styled(HourOneLogo)`
  margin-left: 5px;
  g path {
    fill: ${(props) => props.theme.gray11};
  }
  g path:last-child {
    fill: ${(props) => props.theme.blue4};
  }
`;

const NUM_OF_MESSAGES = 2;

const ActiveRoom = () => {
  const { name } = useRoomInfo();
  const roomState = useConnectionState();
  const tracks = useTracks();
  const voiceAssistant = useVoiceAssistant();
  const { localParticipant } = useLocalParticipant();
  const { messages } = useTranscriptionMessages({ agentAudioTrack: voiceAssistant.audioTrack });

  const agentVideoTrack = tracks.find(
    (trackRef) => trackRef.publication.kind === Track.Kind.Video && trackRef.participant.isAgent
  );

  const localTracks = tracks.filter(({ participant }) => participant instanceof LocalParticipant);
  const agentTracks = tracks.filter(
    ({ participant }) => !(participant instanceof LocalParticipant)
  );
  const localVideoTrack = localTracks.find(({ source }) => source === Track.Source.Camera);
  const localMicTrack = localTracks.find(({ source }) => source === Track.Source.Microphone);
  const agentMicTrack = agentTracks.find(({ source }) => source === Track.Source.Microphone);

  const lastMessages = useMemo(() => {
    const messagesLength = messages.length;
    return messages.slice(messagesLength - NUM_OF_MESSAGES, messagesLength);
  }, [messages]);

  useEffect(() => {
    if (roomState === ConnectionState.Connected) {
      localParticipant.setCameraEnabled(true);
      localParticipant.setMicrophoneEnabled(true);
    }
  }, [localParticipant, roomState]);

  const settingsTileContent = useMemo(() => {
    return (
      <H1_FlexColumn gap="20px" width="600px">
        {localMicTrack && (
          <ConfigurationPanelItem deviceSelectorKind="audioinput">
            <AudioInputTile
              trackRef={localMicTrack}
              color="linear-gradient(0deg, rgba(94, 94, 246, 0.00) 0%, rgba(145, 145, 251, 0.40) 100%)"
            />
          </ConfigurationPanelItem>
        )}
        {agentMicTrack && (
          <H1_FlexRow className="px-4">
            <AudioInputTile trackRef={agentMicTrack} color="#3F3F46" />
          </H1_FlexRow>
        )}
      </H1_FlexColumn>
    );
  }, [
    localParticipant,
    name,
    roomState,
    localVideoTrack,
    localMicTrack,
    voiceAssistant.agent,
    agentMicTrack
  ]);

  const videoTileContent = useMemo(() => {
    const videoFitClassName = `object-cover`;

    const disconnectedContent = (
      <div className="flex items-center justify-center text-gray-700 text-center w-full h-full">
        No video track. Connect to get started.
      </div>
    );

    const loadingContent = (
      <H1_FlexColumn
        align="center"
        justify="center"
        width="256px"
        height="256px"
        className="text-gray-700 text-center"
      >
        <CircleLoader />
        Waiting for video track
      </H1_FlexColumn>
    );

    const videoContent = (
      <H1_FlexRow flex="0 0 256px">
        <StyledVideoTrack
          trackRef={agentVideoTrack}
          className={`absolute top-1/2 -translate-y-1/2 ${videoFitClassName} object-position-center`}
        />
      </H1_FlexRow>
    );

    let content = null;
    if (roomState === ConnectionState.Disconnected) {
      content = disconnectedContent;
      return (
        <H1_FlexColumn flex="0 0 256px" justify="center" height="256px" width="100%" align="center">
          {content}
        </H1_FlexColumn>
      );
    } else if (agentVideoTrack) {
      content = videoContent;
    } else {
      content = loadingContent;
      return (
        <H1_FlexColumn flex="0 0 256px" justify="center" height="256px" width="100%" align="center">
          {content}
        </H1_FlexColumn>
      );
    }

    return (
      <H1_FlexColumn
        position="relative"
        justify="center"
        height="256px"
        width="100%"
        align="center"
      >
        <RoundFlexColumn
          overflow="hidden"
          justify="center"
          height="256px"
          width="256px"
          align="center"
        >
          {content}
        </RoundFlexColumn>
      </H1_FlexColumn>
    );
  }, [agentVideoTrack, roomState]);

  return (
    <BackgroundFlexColumn height="100%">
      <HeaderRow width="100%" height="80px" align="center">
        <FlexLink to={Location.Home}>
          <StyledHourOneLogo width="100%" height={38} />
        </FlexLink>
      </HeaderRow>
      <H1_FlexRow width="100%" flex="1">
        <H1_FlexColumn gap="20px" width="100%">
          <H1_FlexColumn padding="100px 0 0 0" flex="0 0 256px">
            {videoTileContent}
          </H1_FlexColumn>
          <H1_FlexColumn
            flex="1 1 calc(100vh - 256px - 80px - 146px - 36px)"
            padding="20px"
            overflow="auto"
            align="center"
            justify="center"
            width="600px"
            alignSelf="center"
            gap="18px"
          >
            <AnimatePresence>
              {Object.values(lastMessages)
                .sort((a, b) => a.timestamp - b.timestamp)
                .map((segment) => (
                  <MotionFlexRow
                    key={segment.timestamp}
                    initial="visible"
                    animate="visible"
                    exit="hidden"
                    variants={fadeVariants}
                    $align={segment.isSelf ? "flex-start" : "flex-end"}
                  >
                    <TextBubbleContainer
                      $isAssistant={segment.isSelf || false}
                      padding="8px 23px"
                      width="400px"
                      align={segment.isSelf ? "flex-start" : "flex-end"}
                    >
                      <H1_TextSmall whiteSpace="pre-wrap" lineHeight="30px" color="white">
                        {segment.message}
                      </H1_TextSmall>
                    </TextBubbleContainer>
                  </MotionFlexRow>
                ))}
            </AnimatePresence>
          </H1_FlexColumn>
          <H1_FlexColumn flex="0 0 146px" width="100%" align="center">
            {settingsTileContent}
          </H1_FlexColumn>
        </H1_FlexColumn>
      </H1_FlexRow>
    </BackgroundFlexColumn>
  );
};

export default ActiveRoom;
