import React, { useContext, useEffect, useState } from "react";
import { Button, ScrollShadow } from "@nextui-org/react";
import styled, { useTheme } from "styled-components";
import SideBar from "app/components/LeftNav/SideBar";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import { CurrentPage, FeatureFlag, Location } from "app/types";
import { Link, useNavigate } from "react-router-dom";
import WorkspacesDropdownList from "app/components/LeftNav/WorkspacesDropdownList";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import InviteMembersButton from "app/components/teams/InviteMembersButton";
import { useIntl } from "react-intl";
import { AnimatePresence, motion } from "framer-motion";
import BrandkitHub from "app/pages/BrandkitHub/BrandkitHub";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { leftNavMessages } from "app/components/LeftNav/messages";
import { useUpgradeByPlan, useUpgradeToEnterprise } from "app/hooks/useUpgradeModal";
import ConditionalRender from "app/components/common/ConditionalRender";
import { WHATS_NEW_URL } from "app/utils/urls";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import useCredits from "app/hooks/useCredits";
import { Progress } from "antd";
import Config from "app/config/Config";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import usePermissions from "app/hooks/usePermissions";
import { HeaderContext } from "app/hoc/HeaderContext";
import { ThemeMode } from "app/utils/theme";

const siderHiddenRoutePaths: string[] = [
  Location.Live,
  Location.Player,
  Location.Survey,
  Location.IneerEditor,
  Location.Pricing,
  Location.Videos,
  Location.Bio,
  Location.RealTime,
  Location.Playground,
  Location.CreateTwin,
  Location.OnBoardingV2,
  Location.WorkflowForm
];
const StyledHourOneLogo = styled(HourOneLogo)`
  margin-left: 5px;
  g path {
    fill: ${(props) => props.theme.gray11};
  }
  g path:last-child {
    fill: ${(props) => props.theme.blue4};
  }
`;

const FlexLink = styled(Link)`
  display: flex;
  svg {
    width: fit-content;
  }
`;

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
`;

const ButtonStyled = styled(Button)<{ $textColor?: string; $bgColor?: string }>`
  width: 100%;
  justify-content: flex-start;
  color: ${({ $textColor, theme }) =>
    $textColor ? $textColor : theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6};
  background-color: ${({ $bgColor }) => $bgColor};
  &:hover {
    color: ${({ theme }) => theme.gray6};
    background-color: ${({ theme }) => theme.gray3};
  }
`;

const BlackFlexColumn = styled(H1_FlexColumn)`
  z-index: 205;
  background-color: ${(props) => props.theme.gray1};
  * ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(props) => props.theme.gray2};
  }
`;

const CreditsPlanText = styled(H1_TextXs)`
  padding-top: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
`;

const CreditsProgress = styled(Progress)`
  margin-bottom: 3px;
`;

const StyledScrollShadow = styled(ScrollShadow)`
  height: 100%;
  max-height: 100%;
  padding-top: 4px;
  padding-right: 12px;
`;

const InviteButton = styled(InviteMembersButton)`
  &&&,
  &&&:focus {
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    margin: 0px 4px 10px;
    display: flex;
    align-items: center;
    border: none;
    background-color: ${(props) => props.theme.gray3};
    color: ${(props) => props.theme.gray8};
    font-size: 14px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
    border-radius: 14px;
    svg,
    i {
      font-size: 16px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      color: ${(props) => props.theme.gray8};
      fill: ${(props) => props.theme.gray11};
    }
  }
  &&:hover {
    background-color: ${({ theme }) => theme.gray4};
    border: none;
    color: ${(props) => props.theme.gray8};
    i,
    svg {
      color: ${(props) => props.theme.gray8};
      fill: ${(props) => props.theme.gray11};
    }
  }
  &&:active {
    background-color: ${({ theme }) => theme.gray2};
  }
  && {
    align-self: flex-start;
    height: 32px;
    justify-content: center;
    &.channel-route {
      padding-left: 12px;
    }
  }
`;

const LeftNavNextUI = () => {
  const [mouseHoverMinutes, setMouseHoverMinutes] = useState(false);
  const [selectedPage, setSelectedPage] = useState(CurrentPage.home);
  const [showBrandKit, setShowBrandKit] = useState(false);
  const { formatMessage } = useIntl();
  const { isOpenBrandHub, setIsOpenBrandHub } = useContext(HeaderContext);
  const theme = useTheme();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const upgradeToEnterprise = useUpgradeToEnterprise();
  const upgradeModal = useUpgradeByPlan();
  const { currentPlanText } = useCurrentPlan();
  const { usedInMinutes, videoPercentRemained, videoTotalNumberInMinutes, isUnlimited } =
    useCredits();
  const { isAllowToUpgrade } = usePermissions();

  const isNoticeableLoaded = useAppSelector((state) => state.uiV2.isNoticeableLoaded);
  const visibility = !siderHiddenRoutePaths.some((hiddenPath) =>
    String(location.pathname).startsWith(hiddenPath)
  );

  useEffect(() => {
    setShowBrandKit(isOpenBrandHub);
  }, [isOpenBrandHub]);

  useEffect(() => {
    if (
      visibility &&
      flags[FeatureFlag.announceKit] &&
      flags[FeatureFlag.whatsNew] &&
      !isNoticeableLoaded
    ) {
      if (window?.noticeable && window.noticeable.render) {
        window.noticeable.render("widget", Config.noticeable);
        dispatch(uiActionsV2.setIsNoticeableLoaded(true));
      }
    }
  }, [flags[FeatureFlag.announceKit], isNoticeableLoaded, visibility]);

  const onMouseEnter = () => {
    setMouseHoverMinutes(true);
  };

  const onMouseLeave = () => {
    setMouseHoverMinutes(false);
  };

  const onClickPlayground = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "leftNav",
        type: NewVideoType.playground
      })
    );
    navigate(Location.Playground);
  };

  const onClickIntegrations = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "leftNav",
        type: NewVideoType.integrations
      })
    );
    navigate(Location.Integrations);
    setSelectedPage(CurrentPage.integrations);
  };

  const onClickBookACall = () => {
    upgradeToEnterprise({ source: "homepage_left_nav" });
  };

  const onClickAPIAccess = () => {
    upgradeToEnterprise({ source: "homepage_left_nav_api_access" });
  };

  const onClickWhatsNew = () => {
    if (window) {
      window.open(WHATS_NEW_URL, "_blank");
    }
  };

  const onClickPricing = () => {
    upgradeModal({ source: `upgrade_leftnav` });
  };

  const onShowBrandKit = (show: boolean) => {
    setShowBrandKit(show);
  };

  const onCloseBrandKit = () => {
    setIsOpenBrandHub(false);
    setShowBrandKit(false);
  };

  if (!visibility) {
    return <></>;
  }

  return (
    <BlackFlexColumn flex="0 0 auto" width="224px" position="relative">
      <H1_FlexColumn>
        <AnimatePresence>
          {showBrandKit && (
            <motion.div
              initial={{ x: "-312px" }}
              animate={{
                x: "0"
              }}
              exit={{
                x: "-312px"
              }}
              transition={{ ease: "linear", bounce: 1, duration: 0.3 }}
            >
              <BrandkitHub onClose={onCloseBrandKit} />
            </motion.div>
          )}
        </AnimatePresence>
      </H1_FlexColumn>
      <BackgroundFlexColumn position="relative" flex="1" padding="25px 0 24px 15px">
        <FlexLink to={Location.Home}>
          <StyledHourOneLogo width="100%" height={38} />
        </FlexLink>

        <H1_FlexColumn padding="24px 15px 0 0" flex="0 0 auto">
          <WorkspacesDropdownList />
        </H1_FlexColumn>
        <StyledScrollShadow>
          <H1_FlexRow width="100%">
            <InviteButton source="left_bar_teams_invite_button" />
          </H1_FlexRow>
          <SideBar
            showBrandKit={showBrandKit}
            onShowBrandKit={onShowBrandKit}
            onSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
          />
        </StyledScrollShadow>

        {/* Upgrade & Credits */}
        <H1_FlexColumn
          flex="1 0 auto"
          justify="flex-end"
          padding="0 16px 5px 0"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <H1_FlexColumn gap="0.3125rem" padding="0 0 0.625rem 0">
            <ButtonStyled
              fullWidth
              variant="light"
              onClick={onClickPlayground}
              startContent={
                <H1_FlexRow flex="0 0 16px">
                  <i className="fa-regular fa-flask" />
                </H1_FlexRow>
              }
            >
              {formatMessage(leftNavMessages.playground)}
            </ButtonStyled>
            <ButtonStyled
              $textColor={
                selectedPage === CurrentPage.integrations
                  ? theme.gray8
                  : theme.mode === ThemeMode.Light
                  ? theme.gray7
                  : theme.gray6
              }
              $bgColor={selectedPage === CurrentPage.integrations ? theme.gray4 : "transparent"}
              fullWidth
              variant="light"
              onClick={onClickIntegrations}
              startContent={
                <H1_FlexRow flex="0 0 16px">
                  <H1_Icon
                    icon="fa-regular fa-gears"
                    className={
                      selectedPage === "integrations"
                        ? `font-normal text-small text-gray-${
                            theme.mode === ThemeMode.Light ? "6" : "4"
                          } group-data-[selected=true]:text-foreground`
                        : ""
                    }
                  />
                </H1_FlexRow>
              }
            >
              {formatMessage(leftNavMessages.integrations)}
            </ButtonStyled>
            <ButtonStyled
              fullWidth
              variant="light"
              onClick={onClickAPIAccess}
              startContent={
                <H1_FlexRow flex="0 0 16px">
                  <i className="fa-regular fa-code-simple" />
                </H1_FlexRow>
              }
            >
              {formatMessage(leftNavMessages.apiAccess)}
            </ButtonStyled>
            <ConditionalRender
              condition={flags[FeatureFlag.announceKit] && flags[FeatureFlag.whatsNew]}
            >
              <div id="noticeable-widget">
                <ButtonStyled
                  fullWidth
                  data-auto-id="left-nav-whatsnew"
                  variant="light"
                  startContent={
                    <H1_FlexRow flex="0 0 16px">
                      <i className="fa-regular fa-seal-question" />
                    </H1_FlexRow>
                  }
                >
                  {formatMessage(leftNavMessages.whatsNew)}
                </ButtonStyled>
              </div>
            </ConditionalRender>
            <ConditionalRender
              condition={!flags[FeatureFlag.announceKit] && flags[FeatureFlag.whatsNew]}
            >
              <ButtonStyled
                data-auto-id="left-nav-whatsnew"
                fullWidth
                variant="light"
                onClick={onClickWhatsNew}
                startContent={
                  <H1_FlexRow flex="0 0 16px">
                    <i className="fa-regular fa-seal-question" />
                  </H1_FlexRow>
                }
              >
                {formatMessage(leftNavMessages.whatsNew)}
              </ButtonStyled>
            </ConditionalRender>
            <ButtonStyled
              data-auto-id="left-nav-book-a-call"
              fullWidth
              variant="light"
              onClick={onClickBookACall}
              startContent={
                <H1_FlexRow flex="0 0 16px">
                  <i className="fa-regular fa-phone" />
                </H1_FlexRow>
              }
            >
              {formatMessage(leftNavMessages.bookACall)}
            </ButtonStyled>
          </H1_FlexColumn>
          <ConditionalRender condition={isAllowToUpgrade}>
            <Button
              data-auto-id="left-nav-book-a-call"
              fullWidth
              color="warning"
              startContent={
                <H1_FlexRow flex="0 0 16px">
                  <i className="fas fa-crown" />
                </H1_FlexRow>
              }
              onClick={onClickPricing}
              className="text-gray-1"
            >
              {formatMessage(leftNavMessages.upgradeButton)}
            </Button>
          </ConditionalRender>
          <CreditsPlanText color={theme.gray8}>{currentPlanText}</CreditsPlanText>
          <ConditionalRender condition={isUnlimited}>
            <H1_TextXs whiteSpace="break-spaces" color={theme.gray8}>
              {formatMessage(leftNavMessages.unlimitedMinutes, {
                minutes: usedInMinutes.toFixed()
              })}
            </H1_TextXs>
          </ConditionalRender>
          <ConditionalRender condition={!isUnlimited}>
            <CreditsProgress
              strokeColor={theme.orange4}
              trailColor={theme.gray4}
              showInfo={false}
              percent={videoPercentRemained}
              status={mouseHoverMinutes ? "active" : undefined}
            />
            <H1_TextXs whiteSpace="break-spaces" color={theme.gray8}>
              {formatMessage(leftNavMessages.minutesLeft, {
                available: usedInMinutes.toFixed(),
                totalMinutes: videoTotalNumberInMinutes.toFixed()
              })}
            </H1_TextXs>
          </ConditionalRender>
        </H1_FlexColumn>
      </BackgroundFlexColumn>
    </BlackFlexColumn>
  );
};

export default LeftNavNextUI;
