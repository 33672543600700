import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { getRealTimeSession } from "app/services/serviceV2Apis";
import { RealTimeToken } from "app/types";

const prefix = "[RealTime]";

const getSessionRequest = createAsyncThunk<
  RealTimeToken,
  { roomName: string; participantName: string; useBeta?: boolean }
>(
  `${prefix} getSessionRequest`,
  async ({ roomName, participantName, useBeta }) => {
    const res = await getRealTimeSession(roomName, participantName, useBeta);
    return res;
  },
  thunkOptions
);

export default {
  getSessionRequest
};
