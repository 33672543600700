import { ReactNode } from "react";
import { TrackToggle } from "@livekit/components-react";
import { Track } from "livekit-client";
import DeviceSelector from "app/pages/RealTime/DeviceSelector";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";

const AbsoluteFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 10px;
  right: -90px;
  z-index: 10;
`;
type ConfigurationPanelItemProps = {
  children?: ReactNode;
  deviceSelectorKind?: MediaDeviceKind;
};

export const ConfigurationPanelItem: React.FC<ConfigurationPanelItemProps> = ({
  children,
  deviceSelectorKind
}) => {
  return (
    <div className="w-full text-gray-300 relative">
      <AbsoluteFlexRow className="flex flex-row justify-between items-center px-4 text-xs uppercase tracking-wider">
        {deviceSelectorKind && (
          <span className="flex flex-row gap-2">
            {/* @ts-ignore - oof */}
            <TrackToggle
              className="px-2 bg-gray-900 text-gray-3 border border-gray-800 rounded-sm hover:bg-gray-800"
              source={
                deviceSelectorKind === "audioinput" ? Track.Source.Microphone : Track.Source.Camera
              }
            />
            <DeviceSelector kind={deviceSelectorKind} />
          </span>
        )}
      </AbsoluteFlexRow>
      <div className="px-4 text-xs text-gray-500 leading-normal">{children}</div>
    </div>
  );
};
