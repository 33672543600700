import styled from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button, Input } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";
import { playgroundActions } from "app/store/slices/playground.slice";
import { useAppDispatch } from "app/hooks";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    justify-content: space-between;
    background-color: #383544;
  }
`;

const IconContainer = styled(H1_FlexRow)`
  width: 29px;
  height: 29px;
  background-color: rgba(170, 170, 255, 0.12);
  i {
    color: white;
  }
`;

const DarkInput = styled(Input)`
  background-color: black;
  border-radius: 10px;
  &&& div[data-slot="input-wrapper"] {
    background-color: black;
  }
  &&& input {
    color: white;
    background-color: black;
  }
`;

interface PlaygroundCreateModalModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: () => void;
  videoUrl: string;
}
const PlaygroundCreateAvatarModal = ({
  visible,
  onClose,
  onFinish,
  videoUrl
}: PlaygroundCreateModalModalProps) => {
  const [avatarName, setAvatarName] = useState("");
  const dispatch = useAppDispatch();

  const onChangeAvatarName = (event: React.ChangeEvent) => {
    setAvatarName((event.target as HTMLInputElement).value);
  };

  const onCreateAvatar = () => {
    dispatch(
      playgroundActions.playgroundCreateAvatarRequest({
        video_url: videoUrl as string,
        title: avatarName
      })
    );
    onFinish();
  };

  return (
    <Modal
      width="450px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      bodyStyle={{ height: "300px" }}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexColumn gap="20px">
        <H1_FlexRow gap="10px">
          <IconContainer justify="center" align="center">
            <i className="fa-solid fa-user" />
          </IconContainer>
          <H1_TextMidHeadline color="white">Create Avatar</H1_TextMidHeadline>
        </H1_FlexRow>
        <H1_TextMiddle color="#BFBFBF" whiteSpace="normal">
          Avatar name
        </H1_TextMiddle>
        <DarkInput
          size="sm"
          labelPlacement="outside"
          onChange={onChangeAvatarName}
          placeholder="Your name"
        />
      </H1_FlexColumn>
      <H1_FlexRow alignSelf="flex-end" justify="flex-end">
        <Button color="primary" isDisabled={!avatarName} onClick={onCreateAvatar}>
          Create avatar
        </Button>
      </H1_FlexRow>
    </Modal>
  );
};

export default PlaygroundCreateAvatarModal;
