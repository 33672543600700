import { defineMessages } from "react-intl";

export const homePageMessages = defineMessages({
  headerTitle: {
    id: `home-page.headline`,
    defaultMessage: "Let's create some videos!"
  },
  headerText: {
    id: `home-page.headline-text`,
    defaultMessage: "Humanize your content at scale"
  },
  redirectPage: {
    id: `home-page.redirect-page`,
    defaultMessage: "Authentication"
  },
  bannerHeaderTitle: {
    id: `home-page.banner-header-title`,
    defaultMessage: "September Sale"
  },
  bannerHeaderText: {
    id: `home-page.banner-header-text`,
    defaultMessage: "<b>20%</b> off annual plans + <b>FREE</b> brand kit"
  },
  templatesCollection: {
    id: `home-page.templates-collection.title`,
    defaultMessage: "Check out these templates..."
  },
  socialMediaCollection: {
    id: `home-page.social-media-collection.title`,
    defaultMessage: "Let's create some videos!"
  },
  viewAll: {
    id: `home-page.templates-collection.view-all`,
    defaultMessage: "View All"
  },
  largeHeadingWorkflow: {
    id: `home-page.banner-header.large-heading-workflow`,
    defaultMessage: "Create a workflow"
  },
  largeHeadingWorkflowSubtitle: {
    id: `home-page.banner-header.large-heading-workflow-subtitle`,
    defaultMessage: "Easy video collaboration"
  },
  workflowButton: {
    id: `home-page.banner-header.workflow-button`,
    defaultMessage: "Try workflows"
  },
  largeHeading: {
    id: `home-page.banner-header.large-heading`,
    defaultMessage: "Create video from anything"
  },
  largeHeadingSales: {
    id: `home-page.banner-header.large-heading-sales`,
    defaultMessage: "Create a video"
  },
  largeHeadingCreateYourAvatar: {
    id: `home-page.banner-header.large-heading-create-your-avatar`,
    defaultMessage: "Create your avatar"
  },
  iconicWorkspaceHeadline: {
    id: `home-page.banner-header.iconic-workspace-headline`,
    defaultMessage: "Create a new Iconic video"
  },
  smallHeadingCreateYourAvatar: {
    id: `home-page.banner-header.small-heading-create-your-avatar`,
    defaultMessage: "Use it in your sales videos"
  },
  buttonCreateYourAvatar: {
    id: `home-page.banner-header.button-create-your-avatar`,
    defaultMessage: "Create avatar"
  },
  smallParagraph: {
    id: `home-page.banner-header.small-paragraph`,
    defaultMessage: "Select your starting point"
  },
  smallParagraph2: {
    id: `home-page.banner-header.small-paragraph2`,
    defaultMessage: "powered by one Operating System"
  },
  largeHeadingFtu: {
    id: `home-page.banner-header.large-heading-ftu`,
    defaultMessage: "Create your first video"
  },
  smallParagraphFtu: {
    id: `home-page.banner-header.small-paragraph-ftu`,
    defaultMessage: "Turn your ideas into videos effortlessly!"
  },
  bannerCTA: {
    id: `home-page.banner-header.cta-button`,
    defaultMessage: "Explore"
  },
  bannerNewUserCTA: {
    id: `home-page.banner-header.cta-new-user-button`,
    defaultMessage: "Start here"
  },
  bannerNewVideoCTA: {
    id: `home-page.banner-header.cta-new-video-button`,
    defaultMessage: "New video"
  },
  bannerSalesCTA: {
    id: `home-page.banner-header.cta-sales-button`,
    defaultMessage: "Try SalesMeet Summary"
  },
  bannerSalesTitle: {
    id: `home-page.banner-header.title-sales-button`,
    defaultMessage: "Summarize your meeting"
  },
  bannerSalesSubTitle: {
    id: `home-page.banner-header.subtitle-sales-button`,
    defaultMessage: "Try our SalesMeet Summary"
  },
  bannerDevelopersCTA: {
    id: `home-page.banner-header.cta-developers-button`,
    defaultMessage: "Try Playground"
  },
  bannerDevelopersTitle: {
    id: `home-page.banner-header.title-developers-button`,
    defaultMessage: "Create videos from code"
  },
  bannerDevelopersSubTitle: {
    id: `home-page.banner-header.subtitle-developers-button`,
    defaultMessage: "Using our video API"
  },
  bannerTrainingCTA: {
    id: `home-page.banner-header.cta-training-button`,
    defaultMessage: "Upload presentation"
  },
  bannerTrainingTitle: {
    id: `home-page.banner-header.title-training-button`,
    defaultMessage: "Create video from presentation"
  },
  bannerTrainingSubTitle: {
    id: `home-page.banner-header.subtitle-training-button`,
    defaultMessage: "Simply import your presentation"
  },
  edit: {
    id: `home-page.banner-header.edit`,
    defaultMessage: "Edit"
  },
  createVideoFrom: {
    id: `home-page.top-section.create-video-from`,
    defaultMessage: "Create video from"
  },
  startFromTemplate: {
    id: `home-page.top-section.start-from-template-card`,
    defaultMessage: "Start from template"
  },
  fromTemplateCard: {
    id: `home-page.top-section.from-template-card`,
    defaultMessage: "Use a pre-made template"
  },
  startFromPodcast: {
    id: `home-page.top-section.start-from-podcast-card`,
    defaultMessage: "Podcast maker"
  },
  fromPodcastCard: {
    id: `home-page.top-section.from-podcast-card`,
    defaultMessage: "Create video from podcast audio"
  },
  fromVideoWizardCard: {
    id: `home-page.top-section.from-video-wizard-card`,
    defaultMessage: "Video Wizard"
  },
  fromVideoWizardCardDesc: {
    id: `home-page.top-section.from-video-wizard-card-description`,
    defaultMessage: "Generate video with AI"
  },
  startFromPresentation: {
    id: `home-page.top-section.start-from-presentation`,
    defaultMessage: "Upload presentation"
  },
  fromPresentationCard: {
    id: `home-page.top-section.from-presentation-card`,
    defaultMessage: "Convert slides to video"
  },
  fromSelfieCard: {
    id: `home-page.top-section.from-selfie-card`,
    defaultMessage: "From Video Input"
  },
  fromZoomCard: {
    id: `home-page.top-section.from-zoom-card`,
    defaultMessage: "SalesMeet Summary"
  },
  fromZoomCardDesc: {
    id: `home-page.top-section.from-zoom-card-description`,
    defaultMessage: "Summarize your meeting with our bot"
  },
  startFromDocument: {
    id: `home-page.top-section.start-from-document`,
    defaultMessage: "Start from document"
  },
  fromDocument: {
    id: `home-page.top-section.from-document`,
    defaultMessage: "Convert a document to video"
  },
  fromUrl: {
    id: `home-page.top-section.start-from-url`,
    defaultMessage: "Start from URL"
  },
  fromUrlDesc: {
    id: `home-page.top-section.start-from-url-description`,
    defaultMessage: "Convert URL to video"
  },
  fromScript: {
    id: `home-page.top-section.start-from-script`,
    defaultMessage: "Start from script"
  },
  fromScriptDesc: {
    id: `home-page.top-section.start-from-script-description`,
    defaultMessage: "Build a video from your script"
  },
  zoomModalUpgrade: {
    id: `home-page.top-section.zoom-modal-upgrade`,
    defaultMessage: "Upgrade to include a Salesmeet in your meetings"
  },
  zoomModalTitle: {
    id: `home-page.top-section.zoom-modal-title`,
    defaultMessage: "Put the meeting link to create the meeting bot"
  },
  zoomModalSubtitle: {
    id: `home-page.top-section.zoom-modal-subtitle`,
    defaultMessage: "Get meeting highlights recap and next steps videos"
  },
  zoomModalPlaceholder: {
    id: `home-page.top-section.zoom-modal-placeholder`,
    defaultMessage: "https://us06web.zoom.us/j/XXXXXXXXXX"
  },
  zoomModalNotValidUrl: {
    id: `home-page.top-section.zoom-modal-not-valid-url`,
    defaultMessage: "Not a valid Zoom meeting URL"
  },
  zoomModalCta: {
    id: `home-page.top-section.zoom-modal-cta`,
    defaultMessage: "Create meeting bot"
  },
  newVideo: {
    id: `home-page.top-section.new-video`,
    defaultMessage: "New video"
  },
  fromOwnCharacter: {
    id: `home-page.top-section.from-own-character`,
    defaultMessage: "Create avatar"
  },
  fromOwnCharacterDesc: {
    id: `home-page.top-section.from-own-character-description`,
    defaultMessage: "Create your personalized avatar"
  },
  realTime: {
    id: `home-page.top-section.real-time`,
    defaultMessage: "Real-Time"
  },
  realTimeDesc: {
    id: `home-page.top-section.real-time-description`,
    defaultMessage: "Real time video chat"
  },
  twitter: {
    id: `home-page.top-section.twitter`,
    defaultMessage: "Twitter Tweet"
  },
  instagram: {
    id: `home-page.top-section.instagram`,
    defaultMessage: "L&D Teams"
  },
  facebook: {
    id: `home-page.top-section.facebook`,
    defaultMessage: "Developers"
  },
  linkedin: {
    id: `home-page.top-section.linkedin`,
    defaultMessage: "LinkedIn Post"
  },
  tiktok: {
    id: `home-page.top-section.tiktok`,
    defaultMessage: "Sales Teams"
  },
  youtube: {
    id: `home-page.top-section.youtube`,
    defaultMessage: "Product Marketing"
  },
  selectUseCaseTitle: {
    id: `home-page.top-section.select-use-case-title`,
    defaultMessage: `Select Your Team`
  },
  selectUseCaseSubTitle: {
    id: `home-page.use-case-section.select-use-case-subtitle`,
    defaultMessage: `Personalize the platform for your needs`
  },
  lnd: {
    id: `home-page.use-case-section.lnd`,
    defaultMessage: `Learning & Development`
  },
  sales: {
    id: `home-page.use-case-section.sales`,
    defaultMessage: `Sales`
  },
  marketing: {
    id: `home-page.use-case-section.marketing`,
    defaultMessage: `Marketing`
  },
  developers: {
    id: `home-page.use-case-section.developers`,
    defaultMessage: `Developers`
  },
  mediaEntertainment: {
    id: `home-page.use-case-section.media-entertainment`,
    defaultMessage: `Media & Entertainment`
  },
  bannerMainText: {
    id: `home-page.banner.main-text`,
    defaultMessage: `Elevate your content`
  },
  bannerSubText: {
    id: `home-page.banner.sub-text`,
    defaultMessage: `Innovative video templates for everyday storytelling`
  },
  getStarted: {
    id: `home-page.banner.get-started`,
    defaultMessage: `Get started`
  },
  watchLive: {
    id: `home-page.banner.watch-live`,
    defaultMessage: `Watch Live`
  }
});

export const mobileHomepage = defineMessages({
  headerTitle: {
    id: "mobile-homepage.header-title",
    defaultMessage: "Create a video from prompt"
  },
  promptPlaceholder: {
    id: "mobile-homepage.prompt-placeholder",
    defaultMessage:
      "Hey there! I'm your AI avatar, ready for any role you imagine. From sales to marketing, let's unleash creativity and make magic together in this AI avatar revolution!"
  },
  createVideoButton: {
    id: "mobile-homepage.create-video-button",
    defaultMessage: "Create Video"
  },
  creatingVideo: {
    id: "mobile-homepage.creating-video",
    defaultMessage: "Creating video..."
  },
  loading: {
    id: "mobile-homepage.loading",
    defaultMessage: "Loading..."
  },
  mayTake: {
    id: "mobile-homepage.may-take",
    defaultMessage: "This may take a few minutes"
  },
  checkVideo: {
    id: "mobile-homepage.check-video",
    defaultMessage: "Check out your video"
  },
  moreExamples: {
    id: "mobile-homepage.more-examples",
    defaultMessage: "Find more examples here:"
  },
  mobilePromptPageHeader: {
    id: "mobile-homepage.mobile-create-page-header",
    defaultMessage: "Create video via Mobile"
  },
  personalizedButton: {
    id: `mobile-homepage.personalized-button`,
    defaultMessage: "Personalize video"
  },
  tryDesktop: {
    id: `mobile-homepage.try-desktop`,
    defaultMessage: "For better experience, please try via desktop"
  }
});

export const mobilePromptFinishMessages = defineMessages({
  finishTitle: {
    id: `mobile-prompt.finish.title`,
    defaultMessage: `WE'RE CREATING YOUR VIDEO!`
  },
  finishTitleBig: {
    id: `mobile-prompt.finish.title-big`,
    defaultMessage: `We'll email it to you when it's done`
  },
  finishSubTitle: {
    id: `mobile-prompt.finish.subtitle`,
    defaultMessage: `Your video should arrive to your inbox. If you don't see it, please check your spam folder.`
  },
  finishLowerTitle: {
    id: `mobile-prompt.finish.lower-title`,
    defaultMessage: `Want to create videos with even more features?`
  },
  finishBookDemoButton: {
    id: `mobile-prompt.finish.book-demo-button`,
    defaultMessage: `Book a demo`
  },
  finishPricingButton: {
    id: `mobile-prompt.finish.pricing-button`,
    defaultMessage: `See pricing`
  }
});

export const homePageWorkspaceMessages = defineMessages({
  numOfMembers: {
    id: `homepage-workflow.num-of-members`,
    defaultMessage: `{number} members`
  },
  manageWorkspace: {
    id: `homepage-workflow.manage-workspace`,
    defaultMessage: `Manage workspace`
  },
  addBrandKit: {
    id: `homepage-workflow.add-brand-kit`,
    defaultMessage: `Add brand kit`
  },
  publishedVideos: {
    id: `homepage-workflow.published-videos`,
    defaultMessage: "Published videos"
  },
  loadMore: {
    id: `homepage-workflow.load-more`,
    defaultMessage: "Load more"
  }
});
