import VideoPlayer from "app/pages/PlayGround/VideoPlayer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { playgroundActions } from "app/store/slices/playground.slice";
import { playgroundGlobalSelectors } from "app/store/adapters/adapters";
import { PlaygroundStatus } from "app/types/playground";
import React, { useEffect } from "react";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

interface PlaygroundSingleVideoProps {
  videoId: string;
}
const PlaygroundSingleVideo = React.memo(({ videoId }: PlaygroundSingleVideoProps) => {
  const dispatch = useAppDispatch();
  const video = useAppSelector((state) => playgroundGlobalSelectors.selectById(state, videoId));
  const videos = useAppSelector((state) => playgroundGlobalSelectors.selectAll(state));

  const renderingLoading =
    video?.status === PlaygroundStatus.started ||
    video?.status === PlaygroundStatus.processing ||
    video?.status === PlaygroundStatus.submitted_preprocessing;

  useEffect(() => {
    if (videos.length === 0) {
      dispatch(playgroundActions.playgroundGetVideosRequest());
    }
  }, [videos.length]);

  if (!video) {
    return null;
  }

  return (
    <H1_FlexColumn flex="1 0 auto" height="100%" position="relative" align="center">
      <H1_FlexColumn width="100%" flex="1" justify={"center"} gap={"30px"}>
        <ConditionalRender condition={!!(renderingLoading || video?.playback_id)}>
          <H1_FlexColumn justify="center" align="center" flex={"0 0 100%"} gap="20px">
            <VideoPlayer
              videoId={videoId}
              loading={renderingLoading}
              progress={video?.progress}
              playbackId={video?.playback_id}
            />
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={video.status === PlaygroundStatus.failed}>
          <H1_FlexColumn justify="center" align="center" flex="0 0 100%" gap="20px">
            <H1_Icon icon="fas fa-video-slash" size="20px" color="#aaa" />
            <H1_TextMiddle color="#aaa" fontWeight={700}>
              Video failed
            </H1_TextMiddle>
          </H1_FlexColumn>
        </ConditionalRender>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
});

PlaygroundSingleVideo.displayName = "PlaygroundSingleVideo";

export default PlaygroundSingleVideo;
