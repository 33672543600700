/* eslint-disable camelcase */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addOnPayment,
  cancelSubscription,
  createPaymentSession,
  createSetup,
  createSubscription,
  executeSession,
  getCredits,
  getFeaturesPricing,
  getMinutesAnalytics,
  getPricingAndPlans,
  getSubscription,
  getAnalyticsMetadata,
  updateCreditCard,
  verifyCoupon,
  redeemPartnershipCode
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import {
  CreditsData,
  FeaturePrice,
  PlanAndPriceModel,
  SubscriptionPlanEnum,
  ThunkApi
} from "app/types";
import {
  CancelResponse,
  MinuteAnalytic,
  SubscriptionStatus,
  VideoUsageAnalytic
} from "app/types/payments";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { retryPromiseFunction } from "app/utils/helpers";
import { recipesActions } from "app/store/slices/recipes.slice";

const prefix = "[Payments]";

const createPaymentSessionRequest = createAsyncThunk<any, string>(
  `${prefix} createPaymentSessionRequest`,
  async (plan) => {
    const res = await createPaymentSession(plan);
    const result = res.data;
    return result;
  },
  thunkOptions
);
const executeSessionRequest = createAsyncThunk<
  any,
  { subscriptionId?: string; sessionId?: string; invoiceId?: string },
  ThunkApi
>(
  `${prefix} executeSessionRequest`,
  async ({ subscriptionId, sessionId, invoiceId }, thunkApi) => {
    await executeSession({ subscriptionId, sessionId, invoiceId });
    thunkApi.dispatch(recipesActions.setUseCache(false));
  },
  thunkOptions
);

const createSubscriptionRequest = createAsyncThunk<
  any,
  { planId: string; paymentMethod?: string; coupon?: string }
>(
  `${prefix} createSubscriptionRequest`,
  async ({ planId, paymentMethod, coupon }) => {
    const res = await createSubscription({ planId, paymentMethod, coupon });
    const result = res.data;
    return result;
  },
  thunkOptions
);

const setupRequest = createAsyncThunk<{ secret: string }, void>(
  `${prefix} setupRequest`,
  async () => {
    const res = await createSetup();
    const result = res.data;
    return result;
  },
  thunkOptions
);

const verifyCouponRequest = createAsyncThunk<
  { is_valid: boolean; percent_off?: number },
  { coupon: string; planId: string }
>(
  `${prefix} verifyCouponRequest`,
  async ({ coupon, planId }) => {
    const res = await verifyCoupon({ coupon, planId });
    const result = res.data;
    return result;
  },
  thunkOptions
);

const getPricingAndPlansRequest = createAsyncThunk<{
  plans: PlanAndPriceModel[];
  subscription_plans: Partial<Record<SubscriptionPlanEnum, PlanAndPriceModel>>;
}>(
  `${prefix} getPricingAndPlansRequest`,
  async () => {
    const res = await getPricingAndPlans();
    const result = res.data;
    return result;
  },
  thunkOptions
);

const getFeaturesPricingRequest = createAsyncThunk<FeaturePrice[]>(
  `${prefix} getFeaturesPricingRequest`,
  async () => {
    const res = await getFeaturesPricing();
    const result = res.data;
    return result;
  },
  thunkOptions
);

const addOnPaymentRequest = createAsyncThunk<
  { secret: string; invoice_id: string },
  { featureId: string; paymentMethod: string; videoId?: string }
>(
  `${prefix} addOnPaymentRequest`,
  async ({ featureId, paymentMethod, videoId }) => {
    const res = await addOnPayment({ featureId, paymentMethod, videoId });
    const result = res.data;
    return result;
  },
  thunkOptions
);

const getCreditsRequest = createAsyncThunk<CreditsData[], void>(
  `${prefix} getCreditsRequest`,
  async () => {
    const result = await getCredits();
    return result.data;
  },
  thunkOptions
);
const getSubscriptionStatusRequest = createAsyncThunk<SubscriptionStatus[], boolean | undefined>(
  `${prefix} getSubscriptionStatusRequest`,
  async (isRetry = false) => {
    let result;
    if (isRetry) {
      const execute = async () => {
        const res = await getSubscription();
        if (res.length === 0) {
          throw new Error("Empty subscription");
        }
        return res;
      };
      result = await retryPromiseFunction(execute, 3, 5);
    } else {
      result = await getSubscription();
    }
    return result;
  },
  thunkOptions
);
const cancelSubscriptionRequest = createAsyncThunk<CancelResponse, void>(
  `${prefix} cancelSubscriptionRequest`,
  async (_, thunkApi) => {
    const result = await cancelSubscription();
    if (result.action_occurred) {
      thunkApi.dispatch(workspacesActions.upsertWorkspace(result.workspace));
    }

    return result;
  },
  thunkOptions
);

const updateCreditCardRequest = createAsyncThunk<{ url: string }, string>(
  `${prefix} updateCreditCardRequest`,
  async (subscriptionId) => {
    const result = await updateCreditCard(subscriptionId);

    return result;
  },
  thunkOptions
);

const getMinutesAnalyticsRequest = createAsyncThunk<
  MinuteAnalytic[],
  { year: number; month: number }
>(
  `${prefix} getMinutesAnalytics`,
  async ({ year, month }) => {
    const result = await getMinutesAnalytics(year, month);

    return result;
  },
  thunkOptions
);

const getAnalyticsMetadataRequest = createAsyncThunk<VideoUsageAnalytic[], void>(
  `${prefix} getAnalyticsMetadataRequest`,
  async () => {
    const result = await getAnalyticsMetadata();

    return result.activity_months;
  },
  thunkOptions
);

const redeemPartnershipCodeRequest = createAsyncThunk<void, string>(
  `${prefix} redeemPartnershipCodeRequest`,
  async (code: string) => {
    await redeemPartnershipCode(code);
  },
  thunkOptions
);

export default {
  createPaymentSessionRequest,
  executeSessionRequest,
  createSubscriptionRequest,
  setupRequest,
  verifyCouponRequest,
  getPricingAndPlansRequest,
  getFeaturesPricingRequest,
  addOnPaymentRequest,
  getCreditsRequest,
  getSubscriptionStatusRequest,
  cancelSubscriptionRequest,
  updateCreditCardRequest,
  getMinutesAnalyticsRequest,
  getAnalyticsMetadataRequest,
  redeemPartnershipCodeRequest
};
