import React, { useEffect, useState } from "react";
import { defineMessage, useIntl } from "react-intl";
import * as ld from "launchdarkly-js-client-sdk";
import { Layout } from "antd";
import Lottie from "lottie-react";
import animationData from "app/assets/lottie/hourone-main-loader.json";
import AuthenticatedRouter from "app/router/AuthenticatedRouter";

import NotAuthenticatedRouter from "app/router/NotAuthenticatedRouter";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useAuth } from "app/auth/useAuth";
import { useAuthReduxReduce } from "app/auth/useAuthReudxReduce";

import "app/Main.scss";
import "app/input.css";
import "app/output.css";
import { useLDClient } from "launchdarkly-react-client-sdk";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled from "styled-components";
import useQueryParamReducer from "app/hooks/useQueryParamReducer";
import { useLocation } from "react-router-dom";
import { Location } from "app/types";
import RootModalsContainer from "app/hoc/RootModalsContainer";
import RootModalsContainerNotAuthenticated from "app/hoc/RootModalsContainerNotAuthenticated";
import HeaderContextWrapper from "app/hoc/HeaderContextWrapper";
import { page } from "app/utils/segment";
import uiActions from "app/store/actions/ui.actions";
import { initSwiper } from "app/services/swiperConfig";
import useSmallScreen from "app/hooks/useSmallScreen";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

import HeaderContainer from "app/components/common/Layout/HeaderContainer";
import ErrorPage from "app/components/authentication/ErrorPage";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { paymentsActions } from "app/store/slices/payments.slice";
import useGlobalKeyboardShortcut from "app/hooks/useGlobalKeyboardShortcut";
import Tray from "app/hoc/Tray";
import { useTheme } from "next-themes";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import LeftNavNextUI from "app/components/LeftNav/LeftNavNextUI";
import { useTheme as useThemeProvider } from "app/ThemeProvider";
import Config from "app/config/Config";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";

initSwiper();
const restrictDesktopMessage = defineMessage({
  id: "global.restrict-desktop",
  defaultMessage: "This service is only available on desktop"
});

const LottieStyled = styled(Lottie)`
  width: 15%;
  height: 100vh;
  display: block;
  margin: auto;
`;

const liveMode = Config.liveMode;
const API_KEY = Config.adobeSdkApiKey;

// eslint-disable-next-line react/display-name
const Main = React.memo(() => {
  const [loadingAuth0Display, setLoadingAuth0Display] = useState<boolean>(true);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const { isQueryParamReduced } = useQueryParamReducer();
  const { error: authError, appReady } = useAuthReduxReduce();
  const client = useLDClient();
  const location = useLocation();
  const { isSmallScreen } = useSmallScreen();
  const { currentPlan } = useCurrentPlan();
  const nextTheme = useTheme();
  const { toggleTheme } = useThemeProvider();
  const preferencesDarkMode = useAppSelector((state) => state.user.preferences).dark_mode;

  useGlobalKeyboardShortcut();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  useEffect(() => {
    dispatch(uiActions.setIntl(intl));
    nextTheme.setTheme(preferencesDarkMode ? "ho-dark" : "ho-light");

    const loadSDK = async () => {
      try {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://cc-embed.adobe.com/sdk/v4/CCEverywhere.js";
        script.async = false;
        script.defer = false;

        script.onload = async () => {
          try {
            const CCEverywhereModule = window.CCEverywhere || window.exports.CCEverywhere;
            console.log("CCEverywhereModule", CCEverywhereModule);

            if (CCEverywhereModule) {
              window.CCEverywhere = CCEverywhereModule;

              try {
                const result = await CCEverywhereModule.initialize(
                  {
                    clientId: API_KEY,
                    appName: "HourOne"
                  },
                  {
                    loginMode: "delayed"
                  }
                );
                if (result) {
                  dispatch(uiActionsV2.setAdobeSdkEditor(result));
                } else {
                  console.error("No editor in result");
                }
              } catch (initError) {
                console.error("Initialization error:", initError);
              }
            }
          } catch (error) {
            console.error("Script load error:", error);
          }
        };

        script.onerror = (error) => {
          console.error("Script error:", error);
        };

        document.head.appendChild(script);
      } catch (error) {
        console.error("SDK load error:", error);
      }
    };

    loadSDK();
  }, []);

  useEffect(() => {
    if (appReady && !preferencesDarkMode) {
      toggleTheme();
    }
  }, [appReady]);

  useEffect(() => {
    page();
    const hubspotContainer = document.getElementById("hubspot-messages-iframe-container");

    if (hubspotContainer) {
      if (location.pathname.includes(Location.Live) || liveMode) {
        hubspotContainer.style.zIndex = "-1"; // Hide the element
      } else {
        hubspotContainer.style.zIndex = ""; // Reset the style when not on /live
      }
    }
  }, [location]);

  useEffect(() => {
    if (currentWorkspace?.id) {
      dispatch(paymentsActions.getCreditsRequest());
      dispatch(mediaActions.invalidMediaRootCache());
    }
  }, [currentWorkspace?.id]);

  useEffect(() => {
    if (currentWorkspace?.id) {
      const authUser = auth.user;
      if (authUser) {
        const user: ld.LDUser = {
          key: authUser.sub,
          custom: {
            plan: currentPlan
          }
          // email,
          // name
        };
        // Set the user to use LaunchDarkly feature flags
        client?.identify(user);
      }
    }
  }, [currentWorkspace?.id, currentPlan]);
  useEffect(() => {
    if (appReady) {
      setTimeout(() => {
        setLoadingAuth0Display(false);
      }, 200);
    }
  }, [appReady]);

  const renderAuthLoader = (isLoading: boolean) => {
    return (
      <H1_FlexColumn
        className={`loading-auth ${!isLoading && "fadeOut"}`}
        justify="flex-start"
        align="end"
      >
        <LottieStyled loop autoPlay animationData={animationData} />
      </H1_FlexColumn>
    );
  };

  const { Content } = Layout;

  const showLeftNavAndHeader =
    location.pathname === Location.Templates || (auth.isAuthenticated && !loadingAuth0Display);
  const showTopBar =
    !location.pathname.startsWith(Location.Live) &&
    !location.pathname.startsWith(Location.RealTime) &&
    !location.pathname.startsWith(Location.Playground) &&
    !location.pathname.startsWith(Location.CreateTwin) &&
    !location.pathname.startsWith(Location.WorkflowForm) &&
    showLeftNavAndHeader;
  // TODO: add a layout comp that mounts all routes:
  // https://github.com/remix-run/react-router/blob/dev/examples/basic/src/App.tsx
  if (authError) {
    return (
      <div className="App auth-error">
        <Layout style={{ minHeight: "100vh", display: "flex" }} className="App not-allowed">
          <Content>
            <ErrorPage />
          </Content>
        </Layout>
      </div>
    );
  }
  if (appReady && isQueryParamReduced) {
    return (
      <div className={`App auth-not-error allowed ${nextTheme.theme}`}>
        <div className="no-responsive">{intl.formatMessage(restrictDesktopMessage)}</div>
        <H1_FlexRow flex="0 0 100vw" width="100vw" height="100vh" overflow="hidden">
          <HeaderContextWrapper>
            <ConditionalRender condition={showLeftNavAndHeader && !isSmallScreen}>
              <LeftNavNextUI />
            </ConditionalRender>
            <H1_FlexColumn width="100%" flex="1 1 auto" overflow="auto">
              <ConditionalRender condition={showTopBar}>
                <HeaderContainer key={currentWorkspace?.id} />
              </ConditionalRender>
              <H1_FlexColumn overflow="auto" flex="1 1 auto">
                <ConditionalRender condition={auth.isInitialized && !auth.isAuthenticated}>
                  <RootModalsContainerNotAuthenticated />
                  <NotAuthenticatedRouter />
                </ConditionalRender>
                <ConditionalRender condition={auth.isInitialized && auth.isAuthenticated}>
                  <RootModalsContainer />
                  <AuthenticatedRouter />
                  <Tray />
                </ConditionalRender>
              </H1_FlexColumn>
            </H1_FlexColumn>
          </HeaderContextWrapper>
        </H1_FlexRow>
      </div>
    );
  }

  return renderAuthLoader(loadingAuth0Display);
});
export default Main;
