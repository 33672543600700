import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFolder,
  createMedia,
  createMediaBulk,
  createSDMediaGeneration,
  deleteFolder,
  deleteMedia,
  deleteMultipleMediaAndFolders,
  editFolder,
  getFilesFolders,
  getFolderContent,
  getHourneMedia,
  getMediaPolicy,
  mediaGenerator,
  moveToFolder,
  moveToFolderMultiple,
  updateMediaItem
} from "app/services/serviceV2Apis";
import { CreateMedia, StockMedia, MediaUpdateRequest, ServerFolderMedia } from "app/types/media";
import { ThunkApi, thunkOptions } from "app/store/thunks/thunkCommon";
import * as filestack from "app/services/filestackClient";
import { CreateFolder, FilestackPolicy, FoldersContext, FolderType } from "app/types";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";

const prefix = "[Media]";

const filterMediaResults = (result: ServerFolderMedia[]) => {
  return result.filter(
    (media) =>
      media.data?.media_type?.includes("video") ||
      media.data?.media_type?.includes("image") ||
      media.type === FolderType.folder
  );
};
const getMediaListRequest = createAsyncThunk<
  { parentId?: string; files: ServerFolderMedia[] },
  { context?: FoldersContext },
  ThunkApi
>(
  `${prefix} getMediaListRequest`,
  async ({ context = FoldersContext.media }, thunkAPI) => {
    const { cachedRootFolder, rootFolderId } = thunkAPI.getState().media;
    if (cachedRootFolder && context === FoldersContext.media) {
      //   use cached only for regular media library.
      return {
        files: cachedRootFolder as ServerFolderMedia[],
        parentId: rootFolderId as string
      };
    }
    const serverResponse = await getFilesFolders(context);
    let filtered = { files: filterMediaResults(serverResponse.data), parentId: undefined };
    const parentId = serverResponse.headers["parent-id"];
    if (parentId) {
      filtered = { ...filtered, parentId };
    }
    return filtered;
  },
  thunkOptions
);
const getFolderMediaContentRequest = createAsyncThunk<
  { content: ServerFolderMedia[]; metadata: { id: string; name: string } },
  { folderId: string },
  ThunkApi
>(
  `${prefix} getFolderMediaContentRequest`,
  async ({ folderId }) => {
    const serverResponse = await getFolderContent(folderId, undefined, FoldersContext.media);
    const content = filterMediaResults(serverResponse.data.content);
    return { content, metadata: serverResponse.data.metadata };
  },
  thunkOptions
);

const updateMediaRequest = createAsyncThunk<
  void,
  { mediaId: string; fileId: string; mediaUpdate: MediaUpdateRequest },
  ThunkApi
>(
  `${prefix} updateMediaRequest`,
  async ({ mediaId, mediaUpdate }) => {
    await updateMediaItem(mediaId, mediaUpdate);
  },
  thunkOptions
);

const updateMediaFolderRequest = createAsyncThunk<
  void,
  { folderId: string; name: string },
  ThunkApi
>(
  `${prefix} updateMediaFolderRequest`,
  async ({ folderId, name }) => {
    await editFolder(folderId, name, FoldersContext.media);
  },
  thunkOptions
);

const deleteMediaFolderRequest = createAsyncThunk<void, string, ThunkApi>(
  `${prefix} deleteMediaFolderRequest`,
  async (folderId) => {
    await deleteFolder(folderId, FoldersContext.media);
  },
  thunkOptions
);

const deleteMultipleMediaRequest = createAsyncThunk<void, { files: string[] }, ThunkApi>(
  `${prefix} deleteMultipleMediaRequest`,
  async ({ files }) => {
    await deleteMultipleMediaAndFolders(files, FoldersContext.media);
  },
  thunkOptions
);

const moveToFolderMultipleRequest = createAsyncThunk<
  void,
  { selectedFolderId: string; selectedFiles: string[] },
  ThunkApi
>(
  `${prefix} moveToFolderMultipleRequest`,
  async ({ selectedFolderId, selectedFiles }, thunkAPI) => {
    const arr = selectedFiles.map((file) => ({
      id: file,
      parent_id: selectedFolderId
    }));
    await moveToFolderMultiple(arr, FoldersContext.media);
    const state = thunkAPI.getState();
    const { media } = state;
    const currFolderIndex =
      selectedFolderId === media.rootFolderId
        ? -1
        : media.foldersStack.findIndex((folder) => folder.id === selectedFolderId);
    const allMedia = mediaGlobalSelectors.selectAll(state);
    selectedFiles.forEach((mediaFileId) => {
      const mediaFile = allMedia.find((currFile) => currFile.id === mediaFileId);
      if (mediaFile) {
        thunkAPI.dispatch(
          mediaActions.pushToFolderStack({ index: currFolderIndex + 1, file: mediaFile })
        );
      }
    });
  },
  thunkOptions
);

const createMediaSDGenerationRequest = createAsyncThunk<void, { txt: string; orderId: string }>(
  `${prefix} createMediaSDGenerationRequest`,
  async ({ txt, orderId }) => {
    await createSDMediaGeneration(txt, orderId);
  },
  thunkOptions
);

const createMediaRequest = createAsyncThunk<
  ServerFolderMedia,
  { request: CreateMedia; context: FoldersContext },
  ThunkApi
>(
  `${prefix} createMediaRequest`,
  async ({ request, context }, thunkAPI) => {
    const { url, handle } = request;
    const currentFolderId = thunkAPI.getState().media.currentFolderId;
    const result = await createMedia(url as string, handle as string, currentFolderId, context);
    return {
      id: result?.metadata?.file_id,
      name: result.name,
      type: FolderType.file,
      created_at: result?.metadata?.created_at,
      data: result
    };
  },
  thunkOptions
);
const createMediaBulkRequest = createAsyncThunk<
  ServerFolderMedia[],
  { bulk: CreateMedia[]; context: FoldersContext },
  ThunkApi
>(
  `${prefix} createMediaBulkRequest`,
  async (options, thunkAPI) => {
    const request = options.bulk;
    const context = options.context;
    const { currentFolderId } = thunkAPI.getState().media;
    const requestWithParentId = request.map((item) => {
      return { ...item, parent_id: currentFolderId };
    });
    const result = await createMediaBulk(requestWithParentId, context);
    return result.map((d) => {
      return {
        id: d?.metadata?.file_id as string,
        name: d.name,
        type: FolderType.file,
        created_at: new Date().toISOString(),
        data: d
      };
    });
  },
  thunkOptions
);
const deleteMediaRequest = createAsyncThunk<void, { fileId: string; mediaId: string }>(
  `${prefix} deleteMediaRequest`,
  async ({ mediaId }) => {
    await deleteMedia(mediaId);
  },
  thunkOptions
);

const getMediaPolicyRequest = createAsyncThunk<FilestackPolicy, "read" | "upload" | undefined>(
  `${prefix} getMediaPolicyRequest`,
  async (type) => {
    const result = await getMediaPolicy(type);
    if (type !== "upload") {
      filestack.init(result);
    } else {
      filestack.init(result, "upload");
    }
    return result;
  },
  thunkOptions
);

const createFolderRequest = createAsyncThunk<ServerFolderMedia, CreateFolder, ThunkApi>(
  `${prefix} createFolderRequest`,
  async (body, thunkAPI) => {
    const currentFolderId = thunkAPI.getState().media.currentFolderId;
    body.parent_id = currentFolderId;
    const result = await createFolder(body, FoldersContext.media);
    return result.data;
  },
  thunkOptions
);
const moveToFolderRequest = createAsyncThunk<void, { pathId: string; parentId: string }, ThunkApi>(
  `${prefix} moveToFolderRequest`,
  async ({ pathId, parentId }) => {
    await moveToFolder(pathId, parentId, FoldersContext.media);
  },
  thunkOptions
);

const getHouroneMediaRequest = createAsyncThunk<StockMedia[], void, ThunkApi>(
  `${prefix} getHouroneMediaRequest`,
  async () => {
    const result = await getHourneMedia();
    return result;
  },
  thunkOptions
);

const mediaGeneratorRequest = createAsyncThunk<
  void,
  {
    prompt: string;
    type: "image_generation" | "video_transformation";
    orderId: string;
    videoUrl?: string;
  },
  ThunkApi
>(
  `${prefix} mediaGeneratorRequest`,
  async ({ prompt, orderId, type, videoUrl }) => {
    await mediaGenerator({
      prompt,
      orderId,
      type,
      videoUrl
    });
  },
  thunkOptions
);

export default {
  getMediaListRequest,
  updateMediaRequest,
  createMediaRequest,
  deleteMediaRequest,
  deleteMultipleMediaRequest,
  moveToFolderMultipleRequest,
  createMediaSDGenerationRequest,
  getMediaPolicyRequest,
  createMediaBulkRequest,
  createFolderRequest,
  getFolderMediaContentRequest,
  moveToFolderRequest,
  updateMediaFolderRequest,
  deleteMediaFolderRequest,
  getHouroneMediaRequest,
  mediaGeneratorRequest
};
