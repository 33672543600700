import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  becomeCharacter,
  deleteCharacter,
  getCharactersList,
  setFavoritesBulk,
  updateAttributesOnAllScenes,
  updateCharacterTitle
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { BecomeCharacter, Character } from "app/types/character";
import {
  ChangeAttribute,
  FavoriteType,
  FeatureFlag,
  PatchOperation,
  Scene,
  ThunkApi
} from "app/types";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import { charactersActions } from "app/store/slices/characters.slice";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { scenesActions } from "app/store/slices/scenes.slice";
import { v4 as uuidv4 } from "uuid";

const prefix = "[Characters]";

export const addSourceId = (character: Character) => ({
  ...character,
  sourceId: `${character.character_source || uuidv4().toString()}_${
    character.character_outfit || uuidv4().toString()
  }_${character.type}_${character.shot_type?.toLowerCase() || ""}`
});

const getCharactersListRequest = createAsyncThunk<Character[], void>(
  `${prefix} getCharactersListRequest`,
  async () => {
    const result = await getCharactersList();
    return result.map(addSourceId);
  },
  thunkOptions
);

const getMobileCharactersListRequest = createAsyncThunk<Character[], void, ThunkApi>(
  `${prefix} getMobileCharactersListRequest`,
  async () => {
    const result = await getCharactersList({ type: "mobile", scope: "me" });
    return result;
  },
  thunkOptions
);

const becomeCharacterRequest = createAsyncThunk<void, BecomeCharacter>(
  `${prefix} becomeCharacterRequest`,
  async (data) => {
    await becomeCharacter(data);
  },
  thunkOptions
);

const setFavoritesBulkRequest = createAsyncThunk<void, Character, ThunkApi>(
  `${prefix} setFavoritesBulkRequest`,
  async (character: Character, thunkApi) => {
    const charactersByOutfitAndSource = CharacterSelectors.getCharactersBucketByOutfitAndSource(
      thunkApi.getState()
    );
    const favoritesCharacters: Character[] =
      charactersByOutfitAndSource[`${character.sourceId || character.character_source}`];
    const favoritesAssets = favoritesCharacters.map((char) => ({
      asset_id: char.id,
      asset_type: FavoriteType.character,
      active: !character.favorite
    }));
    thunkApi.dispatch(charactersActions.setFavoritesBulk(favoritesAssets));
    await setFavoritesBulk(favoritesAssets);
  },
  thunkOptions
);

const updateCharacterTitleRequest = createAsyncThunk<
  void,
  { id: string; character_id: string; operations: PatchOperation[]; title: string }
>(
  `${prefix} updateCharacterTitleRequest`,
  async ({ character_id, operations }) => {
    await updateCharacterTitle(character_id, operations);
  },
  thunkOptions
);

const deleteCharacterRequest = createAsyncThunk<void, { id: string; character_id: string }>(
  `${prefix} deleteCharacterRequest`,
  async ({ character_id }) => {
    await deleteCharacter(character_id);
  },
  thunkOptions
);

const handleTalkingCharacterChanging = createAsyncThunk<
  void,
  { assetKey: string; draftId: string },
  ThunkApi
>(
  `${prefix} handleTalkingCharacterChanging`,
  async ({ assetKey: targetIsTakingAssetKey, draftId }, thunkApi) => {
    const state = thunkApi.getState();
    const sceneId = state.scenes.selectedSceneId as string;
    const scene = state.scenes.entities[sceneId] as Scene;
    const charactersAssets = scene?.layout.assets.character || [];
    const allCharacters = charactersGlobalSelectors.selectAll(state) || [];

    let operations: PatchOperation[] = [];
    charactersAssets.forEach((character) => {
      const currentCharacterId = scene.attributes.character?.[character.key]?.character_id;
      const theCharacter = allCharacters.find(
        (char) => char.character_id === currentCharacterId
      ) as Character;
      const isTalking = character.key === targetIsTakingAssetKey;
      const targetCharacter = allCharacters.find(
        (currentCharacter) =>
          currentCharacter.sourceId.toLowerCase() === theCharacter.sourceId.toLowerCase() &&
          ((isTalking && currentCharacter.is_talking) ||
            (!isTalking && currentCharacter.is_reaction))
      ) as Character;

      operations = [
        ...operations,
        {
          op: "replace",
          path: `attributes.character.${character.key}.preset_override.is_talking`,
          value: isTalking
        },
        {
          op: "replace",
          path: `attributes.character.${character.key}.character_id`,
          value: targetCharacter.character_id
        }
      ] as PatchOperation[];
    });
    thunkApi.dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  },
  thunkOptions
);

export const updateAttributesOnAllScenesRequest = createAsyncThunk<
  void,
  { attributes: ChangeAttribute },
  ThunkApi
>(
  `${prefix} updateAttributesOnAllScenesRequest`,
  async ({ attributes }, thunkAPI) => {
    const {
      user: { featureFlags },
      drafts: { currentDraft }
    } = thunkAPI.getState();
    const draftId = currentDraft?.id as string;
    const res = await updateAttributesOnAllScenes(draftId, attributes);
    if (res?.length) {
      thunkAPI.dispatch(scenesActions.updateAllScenes(res));
    }
    if (featureFlags[FeatureFlag.framePreviewFeature]) {
      thunkAPI.dispatch(
        scenesActions.scenePreviewRequest({ draftId, sceneIds: res.map(({ id }) => id) })
      );
    }
  },
  thunkOptions
);

export default {
  getCharactersListRequest,
  getMobileCharactersListRequest,
  becomeCharacterRequest,
  setFavoritesBulkRequest,
  updateCharacterTitleRequest,
  deleteCharacterRequest,
  handleTalkingCharacterChanging,
  updateAttributesOnAllScenesRequest
};
