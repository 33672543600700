import { CGNType, FetchStatus, LiveStream } from "app/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/cgn.thunk";
import { fetchingStatus } from "app/utils/helpers";

interface CGNSliceState {
  channels: LiveStream[];
  streamersVod: LiveStream[];
  getCGNStatus: FetchStatus;
  getCGNStreamerVodStatus: FetchStatus;
  uploadVodStatus: FetchStatus;
  sendPublicLivePromptStatus: FetchStatus;
  CGNInternalUse: boolean;
}

const INITIAL_STATE: CGNSliceState = {
  channels: [],
  streamersVod: [],
  getCGNStatus: fetchingStatus.idle,
  getCGNStreamerVodStatus: fetchingStatus.idle,
  uploadVodStatus: fetchingStatus.idle,
  sendPublicLivePromptStatus: fetchingStatus.idle,
  CGNInternalUse: false
};

export const cgnSlice = createSlice({
  name: "CGN",
  initialState: INITIAL_STATE,
  reducers: {
    updateChannelNextVideo(state, action: PayloadAction<{ channelName: string; title: string }>) {
      const { channelName, title } = action.payload;
      state.channels = state.channels.map((channel) =>
        channel.name === channelName ? { ...channel, title } : channel
      );
      return state;
    },
    setCGNProperties(
      state,
      action: PayloadAction<{ name: "entertainment" | "news"; playback_id: string; type: CGNType }>
    ) {
      state.channels = state.channels.map((channel) =>
        channel.name === action.payload.name
          ? { ...channel, playback_id: action.payload.playback_id, type: action.payload.type }
          : channel
      );
      return state;
    },
    updateUploadVodStatusToIdle(state) {
      state.uploadVodStatus = fetchingStatus.idle;
      return state;
    },
    setCGNInternalUse(state, action) {
      state.CGNInternalUse = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getCGNRequest.fulfilled, (state, action) => {
      state.channels = action.payload;
      state.getCGNStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNRequest.pending, (state) => {
      state.getCGNStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNRequest.rejected, (state) => {
      state.getCGNStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNStreamerVodRequest.fulfilled, (state, action) => {
      state.streamersVod = action.payload;
      state.getCGNStreamerVodStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNStreamerVodRequest.pending, (state) => {
      state.getCGNStreamerVodStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNStreamerVodRequest.rejected, (state) => {
      state.getCGNStreamerVodStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.uploadVodRequest.fulfilled, (state) => {
      state.uploadVodStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.uploadVodRequest.pending, (state) => {
      state.uploadVodStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.uploadVodRequest.rejected, (state) => {
      state.uploadVodStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.sendPublicLivePromptRequest.fulfilled, (state) => {
      state.sendPublicLivePromptStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.sendPublicLivePromptRequest.pending, (state) => {
      state.sendPublicLivePromptStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.sendPublicLivePromptRequest.rejected, (state) => {
      state.sendPublicLivePromptStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.sendLiveWatchNextRequest.fulfilled, (state) => {
      state.sendPublicLivePromptStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.sendLiveWatchNextRequest.pending, (state) => {
      state.sendPublicLivePromptStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.sendLiveWatchNextRequest.rejected, (state) => {
      state.sendPublicLivePromptStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});
export default cgnSlice.reducer;

export const cgnActions = {
  getCGNRequest: asyncThunks.getCGNRequest,
  getCGNStreamerVodRequest: asyncThunks.getCGNStreamerVodRequest,
  uploadVodRequest: asyncThunks.uploadVodRequest,
  sendPublicLivePromptRequest: asyncThunks.sendPublicLivePromptRequest,
  sendLiveWatchNextRequest: asyncThunks.sendLiveWatchNextRequest,
  ...cgnSlice.actions
};
