/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/playground.thunk";
import { executeDownload, fetchingStatus } from "app/utils/helpers";
import { CustomVoice, FetchStatus, Gender } from "app/types";
import { playgroundAdapter } from "app/store/adapters/adapters";
import {
  PlaygroundMedia,
  PlaygroundModel,
  PlayGroundResponse,
  PlaygroundStatus
} from "app/types/playground";

interface PlaygroundState {
  generateStatus: FetchStatus;
  getVideosStatus: FetchStatus;
  shareVideoStatus: FetchStatus;
  downloadStatus: FetchStatus;
  createAvatarStatus: FetchStatus;
  trainAvatarStatus: FetchStatus;
  previewTranscriptStatus: FetchStatus;
  transcript?: string;
  selectedPresenterId?: string;
  selectedVoiceId?: string;
  selectedGender?: string;
  playbackId?: string;
  videoId?: string;
  gender?: Gender;
  generateResult?: PlayGroundResponse;
  livePortrait?: PlaygroundMedia;
  customPresenter?: PlaygroundMedia;
  customVoice?: Partial<CustomVoice>;
  voicePreviewUrl?: string;
  voicePreviewOrderId?: string;
  modelsList: PlaygroundModel[];
}
const DEFAULT_TRANSCRIPT = "I am your virtual human";
export const playgroundSlice = createSlice({
  name: "Playground",
  initialState: playgroundAdapter.getInitialState<PlaygroundState>({
    transcript: DEFAULT_TRANSCRIPT,
    generateStatus: fetchingStatus.idle,
    shareVideoStatus: fetchingStatus.idle,
    previewTranscriptStatus: fetchingStatus.idle,
    downloadStatus: fetchingStatus.idle,
    getVideosStatus: fetchingStatus.idle,
    createAvatarStatus: fetchingStatus.idle,
    trainAvatarStatus: fetchingStatus.idle,
    modelsList: []
  }),
  reducers: {
    setGenerateToIdle(state) {
      state.generateStatus = fetchingStatus.idle;
    },
    setCreateAvatarToIdle(state) {
      state.createAvatarStatus = fetchingStatus.idle;
    },
    updatePreviewTranscriptStatusToIdle(state) {
      state.previewTranscriptStatus = fetchingStatus.idle;
    },
    removePlayGroundLivePortrait(state) {
      state.livePortrait = undefined;
    },
    removePlayGroundCustomPresenter(state) {
      state.customPresenter = undefined;
    },
    updatePlayGroundDetails(state, action) {
      if (action.payload.selectedPresenter) {
        state.selectedPresenterId = action.payload.selectedPresenter;
      }
      if (action.payload.selectedVoice) {
        state.selectedVoiceId = action.payload.selectedVoice;
      }
      if (action.payload.selectedGender) {
        state.selectedGender = action.payload.selectedGender;
      }
      if (action.payload.transcript !== undefined) {
        state.transcript = action.payload.transcript;
      }
      if (action.payload.livePortrait) {
        state.livePortrait = action.payload.livePortrait;
      }
      if (action.payload.customPresenter) {
        state.customPresenter = action.payload.customPresenter;
      }
      if (action.payload.customVoice) {
        state.customVoice = action.payload.customVoice;
      }
      return state;
    },
    updateProgressAndStatus(state, action) {
      const { status, progress, playbackId, videoId, reqId } = action.payload;
      playgroundAdapter.updateOne(state, {
        id: videoId,
        changes: { progress: progress, status: status, playback_id: playbackId, req_id: reqId }
      });

      return state;
    },
    updateCurrentFormStateByVideo(state, action) {
      const video = action.payload;
      state.selectedVoiceId = video.voice_id;
      state.transcript = video.transcript || DEFAULT_TRANSCRIPT;
      state.selectedPresenterId = video.character_id;
      state.videoId = video.id;
      if (video.voice_url) {
        state.selectedVoiceId = "custom";
        state.customVoice = {
          id: "custom",
          display_name: "User audio",
          url: video.voice_url,
          image: "https://flagcdn.com/us.svg",
          sample_url: ""
        };
      }
      if (video.source_video) {
        state.selectedPresenterId = "custom";
        state.customPresenter = {
          id: "custom",
          name: "User video",
          url: video.source_video,
          image: "",
          gender: Gender.male
        };
      }
    },
    setVoicePreviewV2(state, action) {
      const { url, status, orderId } = action.payload;
      if (orderId === state.voicePreviewOrderId) {
        state.voicePreviewUrl = url;
        state.previewTranscriptStatus = status;
      }

      return state;
    },
    cleanPlaygroundAudio(state) {
      state.voicePreviewOrderId = undefined;
      state.voicePreviewUrl = undefined;
      state.previewTranscriptStatus = fetchingStatus.idle;

      return state;
    },
    updateShareStatusToIdle(state) {
      state.shareVideoStatus = fetchingStatus.idle;

      return state;
    },
    updateTrainStatusToIdle(state) {
      state.trainAvatarStatus = fetchingStatus.idle;

      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.playgroundGetVideosRequest.pending, (state) => {
      state.getVideosStatus = fetchingStatus.loading;
    });

    builder.addCase(asyncThunks.playgroundGetVideosRequest.fulfilled, (state, action) => {
      state.getVideosStatus = fetchingStatus.succeeded;
      playgroundAdapter.setAll(state, action.payload);
    });

    builder.addCase(asyncThunks.playgroundGetVideosRequest.rejected, (state) => {
      state.getVideosStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.playgroundShareVideoRequest.pending, (state) => {
      state.shareVideoStatus = fetchingStatus.loading;
    });

    builder.addCase(asyncThunks.playgroundShareVideoRequest.fulfilled, (state, action) => {
      state.shareVideoStatus = fetchingStatus.succeeded;
      playgroundAdapter.updateOne(state, {
        id: action.meta.arg.videoId,
        changes: { status: PlaygroundStatus.published }
      });
    });

    builder.addCase(asyncThunks.playgroundShareVideoRequest.rejected, (state) => {
      state.shareVideoStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.generateRequest.fulfilled, (state, action) => {
      playgroundAdapter.addOne(state, action.payload);
      state.generateResult = action.payload;
      state.generateStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.generateRequest.rejected, (state) => {
      state.generateStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.generateRequest.pending, (state) => {
      state.generateStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.previewTranscriptRequest.pending, (state, action) => {
      state.previewTranscriptStatus = fetchingStatus.loading;
      state.voicePreviewOrderId = action.meta.arg.orderId;
    });
    builder.addCase(asyncThunks.previewTranscriptRequest.rejected, (state) => {
      state.previewTranscriptStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.playgroundDownloadVideoRequest.fulfilled, (state, action) => {
      state.downloadStatus = fetchingStatus.succeeded;
      executeDownload(action.payload.url as string, "playground.mp4");
    });
    builder.addCase(asyncThunks.playgroundDownloadVideoRequest.pending, (state) => {
      state.downloadStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.playgroundDownloadVideoRequest.rejected, (state) => {
      state.downloadStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.playgroundGetModelsListRequest.fulfilled, (state, action) => {
      state.modelsList = action.payload;
    });

    builder.addCase(asyncThunks.playgroundCreateAvatarRequest.pending, (state) => {
      state.createAvatarStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.playgroundCreateAvatarRequest.rejected, (state) => {
      state.createAvatarStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.playgroundCreateAvatarRequest.fulfilled, (state) => {
      state.createAvatarStatus = fetchingStatus.succeeded;
    });

    builder.addCase(asyncThunks.trainAvatarRequest.pending, (state) => {
      state.trainAvatarStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.trainAvatarRequest.rejected, (state) => {
      state.trainAvatarStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.trainAvatarRequest.fulfilled, (state) => {
      state.trainAvatarStatus = fetchingStatus.succeeded;
    });
  }
});

export default playgroundSlice.reducer;

export const playgroundActions = {
  playgroundGetVideosRequest: asyncThunks.playgroundGetVideosRequest,
  playgroundShareVideoRequest: asyncThunks.playgroundShareVideoRequest,
  previewTranscriptRequest: asyncThunks.previewTranscriptRequest,
  generateRequest: asyncThunks.generateRequest,
  playgroundDownloadVideoRequest: asyncThunks.playgroundDownloadVideoRequest,
  playgroundGetModelsListRequest: asyncThunks.playgroundGetModelsListRequest,
  playgroundCreateAvatarRequest: asyncThunks.playgroundCreateAvatarRequest,
  trainAvatarRequest: asyncThunks.trainAvatarRequest,
  ...playgroundSlice.actions
};
