import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { LayoutAsset, LayoutAssetTypeOptions, LayoutColor } from "app/types/layout";
import Preview from "app/components/editor/sideDrawers/Preview";

import styled, { css, useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import { Collapse, Popover, Tooltip } from "antd";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import useModal, { ModalName } from "app/hooks/useModal";
import { AssetKeyType, VideoAction, VideoFit } from "app/types/media";
import ConditionalRender from "app/components/common/ConditionalRender";
import {
  Draft,
  FeatureFlag,
  FetchStatus,
  MediaAssetProperties,
  PaletteColor,
  PaletteColorKey,
  PatchOperation
} from "app/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import WandLoader from "app/components/common/Layout/WandLoader";
import { scenesActions } from "app/store/slices/scenes.slice";
import {
  fetchingStatus,
  getAttributeValueBySceneOrLayout,
  getPaletteColorsAsDictionary,
  removePaletteColorKeyPrefix
} from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import DevModePopover from "app/components/DevModePopover";
import VideoSettingsModal from "app/components/editor/sideDrawers/SceneDrawer/VideoSettingsModal";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import { customColorPickerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import ScreenCaptureButton from "app/components/editor/scene/ScreenCaptureButton";
import { ReactComponent as RecordIcon } from "app/assets/editor/record-icon.svg";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledRecordIcon = styled(RecordIcon)`
  flex: 1 0 16px;
  fill: ${({ theme }) => theme.gray7};
`;
const StyledScreenCaptureButton = styled(ScreenCaptureButton)`
  width: 23px;
  height: 23px;
  background-color: transparent;
  box-shadow: none;
  i {
    color: ${({ theme }) => theme.gray7};
    font-size: 15px;
  }
  &:hover {
    i {
      color: ${({ theme }) => theme.blue4};
    }
    background-color: ${({ theme }) => theme.gray2};
    filter: none;
  }
`;
const StyledGradientIcon = styled(H1_Icon)`
  background: ${({ theme }) => theme.gray5};
  background: linear-gradient(to bottom, black, white);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
`;
const IconContainer = styled(H1_FlexRow)<{ $selected?: boolean }>`
  cursor: pointer;
  i {
    cursor: pointer;
  }
  width: 23px;
  height: 23px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
  &:active {
    background-color: ${({ theme }) => theme.gray4};
  }
  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) => theme.gray4};
    `};
`;

const CustomCollapse = styled(Collapse)`
  &.ant-collapse {
    width: 231px;
    background-color: white;
    border: none;
    .ant-collapse-content {
      border: none;
    }
    .ant-collapse-content-box {
      padding-left: 0;
      padding-top: 0;
    }
    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        justify-content: space-between;
        position: relative;
        padding: 0 0 12px 0;
        .ant-collapse-expand-icon {
          right: 0;
          position: absolute;
        }
      }
    }

    .ant-collapse-header-text {
      color: ${({ theme }) => theme.gray7};
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      font-weight: 600;
      font-size: 12px;
    }
  }
  &.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    padding: 0;
  }
`;

const ColorCircle = styled(H1_FlexRow)<{ $color: string; $selected: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: white;
    box-shadow: 0 0 12px 0 rgba(0, 0, 255, 0.15);
  }
  border: 2px ${(props) => props.theme.gray3} solid;
  ${({ $selected }) =>
    $selected &&
    css`
      filter: drop-shadow(0px 0px 4px #aaf);
    `};
`;

const UnderlineButton = styled(Button)`
  && {
    padding: unset;
    background-color: transparent;
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2)};
  }
  &:hover {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue3 : theme.blue3)};
  }
  font-size: 12px;
`;

const ColorDot = styled(H1_FlexRow)<{ $color?: string }>`
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  cursor: pointer;
  border: 2px ${(props) => props.theme.gray3} solid;
  position: relative;
  filter: drop-shadow(0px 0px 4px #aaf);
`;
const GradientColorFlexRow = styled(H1_FlexRow)<{
  $fromColor: string;
  $secondColor: string;
  $thirdColor?: string;
  $fourthColor?: string;
  $height: string;
}>`
  background: ${({ $fromColor, $secondColor, $thirdColor, $fourthColor }) => {
    // Build the gradient string by starting with the required colors
    let colors = `${$fromColor}, ${$secondColor}`;

    // Append optional colors if they exist
    if ($thirdColor) {
      colors += `, ${$thirdColor}`;
    }
    if ($fourthColor) {
      colors += `, ${$fourthColor}`;
    }

    // Return the full linear-gradient CSS rule
    return `linear-gradient(to right, ${colors})`;
  }};
  border: 2px solid ${(props) => props.theme.gray3};
  border-radius: 5px;
  min-height: ${({ $height }) => $height};
  cursor: pointer;
  opacity: 1;
`;
const ColorFlexRow = styled(H1_FlexRow)<{ $color?: string }>`
  background-color: ${({ $color }) => $color};
  border: 2px solid ${(props) => props.theme.gray3};
  border-radius: 5px;
  min-height: ${({ height }) => height};
  cursor: pointer;
  opacity: 1;
`;
const BackgroundImageFlexRow = styled(H1_FlexRow)<{
  $isPreviewVisible: boolean;
}>`
  background-color: ${(props) => props.theme.gray2};
  border: none;
  border-radius: 5px;
  min-height: ${({ height }) => height};
  cursor: pointer;
  opacity: 1;
  ${({ $isPreviewVisible }) =>
    $isPreviewVisible &&
    css`
      &:hover {
        img {
          transition: opacity 0.3s;
          opacity: 0.8;
        }
      }
    `};
`;

const StyledPreview = styled(Preview)<{ $height: string; $width: string }>`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  img,
  video {
    height: ${({ $height }) => $height};
    width: ${({ $width }) => $width};
  }
  &:hover {
    background-color: ${(props) => props.theme.gray1};
    img {
      opacity: 0.5;
    }
  }
`;

const StyledIcon = styled(Button)`
  min-width: 23px;
  max-width: 23px;
  height: 23px;
  i {
    font-size: 15px;
    color: ${({ theme }) => theme.gray7};
  }
`;

export interface ImageTextsSectionProps {
  media: LayoutAsset;
  mediaUrl: string;
  loading?: boolean;
  isCardMode?: boolean;
  height?: string;
  width?: string;
}

const SingleMediaSection = ({
  media,
  mediaUrl,
  loading = false,
  isCardMode = false,
  height = "73px",
  width = "123px"
}: ImageTextsSectionProps) => {
  const [videoSettingsModalOpen, setVideoSettingsModalOpen] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isWriteWorkspaceAllow } = usePermissions();
  const { openModal } = useModal();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();
  const flags = useFlags();
  const { openDrawer } = useDrawer();
  const theme = useTheme();

  const appliedPalette = useAppSelector(templatesSelectors.getAppliedPalette);
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const sceneAssetsGenerationStatus: Record<string, FetchStatus> = useAppSelector(
    (state) => state.scenes.genrateSceneAssetStatus
  );
  const draftId = draft?.id as string;
  const sceneTranscript = scene?.attributes?.text?.transcript?.text;
  const assetKey = media.key || "";
  const visualAsset = scene?.layout?.assets?.visual;
  const attributeType = visualAsset && visualAsset.length > 0 ? "visual" : "media";
  const currentLayoutMedia = media;
  const currentValue = getAttributeValueBySceneOrLayout(
    scene,
    currentLayoutMedia,
    attributeType,
    "url"
  );

  const resolvedPreviewUrl = currentValue as string;
  const { displayUrls } = useDisplayUrls([resolvedPreviewUrl]);
  const sceneName = scene?.name as string;
  const isFileStack = mediaUrl?.toLowerCase().indexOf("filestack") > -1;
  const isNoVisualLayout = !currentLayoutMedia.preset?.type || !currentLayoutMedia.preset;
  const mediaTypeOptions = currentLayoutMedia.restrictions?.type_options || [];
  const isMediaTypeOptionsMedia = mediaTypeOptions.includes(LayoutAssetTypeOptions.media);
  const isMediaTypeOptionsSolid = mediaTypeOptions.includes(LayoutAssetTypeOptions.solid);
  const isMediaTypeOptionsGradient = mediaTypeOptions.includes(LayoutAssetTypeOptions.gradient);
  const currentPresetOverrideType =
    scene?.attributes?.[attributeType]?.[assetKey]?.preset_override?.type;

  const isCurrentPresetOverrideTypeAvailable = currentPresetOverrideType
    ? mediaTypeOptions.includes(currentPresetOverrideType as LayoutAssetTypeOptions)
    : false;

  const isTypeMedia =
    (currentLayoutMedia.preset?.type === LayoutAssetTypeOptions.media &&
      !scene?.attributes?.[attributeType]?.[assetKey]?.preset_override) ||
    isNoVisualLayout ||
    (!isCurrentPresetOverrideTypeAvailable &&
      currentLayoutMedia.preset?.type === LayoutAssetTypeOptions.media) ||
    scene?.attributes?.[attributeType]?.[assetKey]?.preset_override?.type ===
      LayoutAssetTypeOptions.media;

  const isTypeSolid =
    isMediaTypeOptionsSolid &&
    ((currentLayoutMedia.preset?.type === LayoutAssetTypeOptions.solid &&
      !scene?.attributes?.[attributeType]?.[assetKey]?.preset_override) ||
      (!isCurrentPresetOverrideTypeAvailable &&
        currentLayoutMedia.preset?.type === LayoutAssetTypeOptions.solid) ||
      scene?.attributes?.[attributeType]?.[assetKey]?.preset_override?.type ===
        LayoutAssetTypeOptions.solid);

  const hasGradientColors =
    currentLayoutMedia.preset?.gradient_colors &&
    currentLayoutMedia.preset?.gradient_colors.length > 0;
  const isTypeGradient =
    isMediaTypeOptionsGradient &&
    ((hasGradientColors &&
      currentLayoutMedia.preset?.type === LayoutAssetTypeOptions.gradient &&
      !scene?.attributes?.[attributeType]?.[assetKey]?.preset_override) ||
      (!isCurrentPresetOverrideTypeAvailable &&
        currentLayoutMedia.preset?.type === LayoutAssetTypeOptions.gradient) ||
      scene?.attributes?.[attributeType]?.[assetKey]?.preset_override?.type ===
        LayoutAssetTypeOptions.gradient);

  const previewUrls = useMemo(() => {
    return displayUrls[resolvedPreviewUrl] ? [displayUrls[resolvedPreviewUrl]] : [];
  }, [displayUrls, resolvedPreviewUrl]);

  const appliedPaletteDictionary = useMemo(
    () => getPaletteColorsAsDictionary(appliedPalette),
    [appliedPalette]
  );

  const currentSolidColor: PaletteColor | undefined = useMemo(() => {
    if (currentLayoutMedia.preset?.solid_color && !!appliedPalette?.length) {
      const color = appliedPalette.find(
        (paletteColor) =>
          paletteColor.key?.split("_")[1] ===
          getAttributeValueBySceneOrLayout(scene, currentLayoutMedia, attributeType, "solid_color")
      );

      return color;
    }
    return undefined;
  }, [
    currentLayoutMedia.preset?.solid_color,
    appliedPalette,
    scene?.attributes?.[attributeType]?.[assetKey]?.preset_override?.solid_color
  ]);

  const initialVideoFit: VideoFit = useMemo(() => {
    const videoAdjust = scene?.attributes?.[attributeType]?.[assetKey]?.video_adjustments?.find(
      (adjust) =>
        adjust.action === VideoAction.CROP ||
        adjust.action === VideoAction.SCALE ||
        adjust.action === VideoAction.PAD_FIT
    );
    switch (videoAdjust?.action) {
      case VideoAction.PAD_FIT:
        return VideoFit.Pad;
      case VideoAction.CROP:
        return VideoFit.Crop;
      case VideoAction.SCALE:
        return VideoFit.Scale;
      default:
        return VideoFit.Scale;
    }
  }, [scene?.attributes?.[attributeType]?.[assetKey]?.video_adjustments]);
  const isPreviewDisplayed = previewUrls.length > 0;
  const isImageFitDisplayed =
    displayUrls[resolvedPreviewUrl]?.assetType === "image" &&
    flags[FeatureFlag.imageFit] &&
    isFileStack &&
    currentLayoutMedia?.restrictions?.ratio_w;
  const isVideoEditDisplayed =
    displayUrls[resolvedPreviewUrl]?.assetType === "video" &&
    flags[FeatureFlag.videoActions] &&
    isFileStack &&
    currentLayoutMedia?.restrictions?.ratio_w;

  const updateGradientColor = (index: number, colorKey: LayoutColor) => {
    const colors: string[] = getAttributeValueBySceneOrLayout(
      scene,
      currentLayoutMedia,
      attributeType,
      "gradient_colors"
    ) as LayoutColor[];
    const clonedColors = JSON.parse(JSON.stringify(colors));
    if (clonedColors[index]) {
      clonedColors[index] = colorKey.toString();
    }

    return clonedColors;
  };

  const onSolidColorClick = (colorKey: string | undefined, index?: number) => {
    if (colorKey) {
      const shortColorKey = removePaletteColorKeyPrefix(colorKey);
      const operations: PatchOperation[] = [];
      const colorKeyValue =
        index !== undefined
          ? updateGradientColor(index, shortColorKey as LayoutColor)
          : shortColorKey;
      operations.push({
        op: "replace",
        path: `attributes.${attributeType}.${assetKey}.preset_override.${
          index !== undefined ? "gradient_colors" : "solid_color"
        }`,
        value: colorKeyValue
      });
      operations.push({
        op: "replace",
        path: `attributes.${attributeType}.${assetKey}.preset_override.type`,
        value: index !== undefined ? LayoutAssetTypeOptions.gradient : LayoutAssetTypeOptions.solid
      });
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId,
          operations
        })
      );
    }
  };
  const onImageClick = () => {
    if (!isWriteWorkspaceAllow || isCardMode) {
      return;
    }
    dispatch(
      analyticsEvents.chooseImage({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        },
        origin: "SceneDrawer"
      })
    );

    openModal(ModalName.mediaLibraryModal, {
      assetKey,
      assetKeyType: AssetKeyType.scene,
      attributeType
    });
  };

  const onSettingsClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    openDrawer(Drawer.ImageFit, { backDrawer: Drawer.Scene, media, mediaUrl, attributeType });
  };

  const onVideoSettingsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setVideoSettingsModalOpen(true);
    dispatch(
      analyticsEvents.videoActions({
        status: "open",
        draftId,
        sceneNumber: selectedSceneIndex,
        origin: "SceneDrawer"
      })
    );
  };

  const onClickGenerateMedia = (e: React.MouseEvent, key: string) => {
    e.stopPropagation();
    const operations: PatchOperation[] = [
      { op: "generate", path: `attributes.${attributeType}.${key}` }
    ];
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
    dispatch(
      analyticsEvents.generateMedia({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        }
      })
    );
  };

  const onCloseVideoSettingsModal = () => {
    setVideoSettingsModalOpen(false);
    dispatch(
      analyticsEvents.videoActions({
        status: "close",
        draftId,
        sceneNumber: selectedSceneIndex,
        origin: "SceneDrawer"
      })
    );
  };

  const onChooseTypeMedia = () => {
    const operations: PatchOperation[] = [];
    operations.push({
      op: "replace",
      path: `attributes.${attributeType}.${assetKey}.preset_override.type`,
      value: "media"
    });

    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const onChooseTypeGradient = () => {
    const operations: PatchOperation[] = [];
    operations.push({
      op: "replace",
      path: `attributes.${attributeType}.${assetKey}.preset_override.type`,
      value: "gradient"
    });

    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const onChooseTypeSolid = () => {
    const operations: PatchOperation[] = [];
    operations.push({
      op: "replace",
      path: `attributes.${attributeType}.${assetKey}.preset_override.type`,
      value: "solid"
    });

    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const colorsPopoverContent = (gradientColor?: string, index?: number) => {
    const colorTooltip = (color: PaletteColor) => {
      switch (color.key) {
        case PaletteColorKey.color_A1:
          return intl.formatMessage(customColorPickerMessages.a1Color);
        case PaletteColorKey.color_A2:
          return intl.formatMessage(customColorPickerMessages.a2Color);
        case PaletteColorKey.color_A3:
          return intl.formatMessage(customColorPickerMessages.a3Color);
        case PaletteColorKey.color_W:
          return intl.formatMessage(customColorPickerMessages.wColor);
        case PaletteColorKey.color_B:
          return intl.formatMessage(customColorPickerMessages.bColor);
        default:
          return "";
      }
    };
    return (
      <H1_FlexColumn padding="8px 4px" gap="10px">
        <CustomCollapse accordion defaultActiveKey={["1"]}>
          <Collapse.Panel
            header={intl.formatMessage(sceneDrawerMessages.background)}
            key="1"
            showArrow={false}
            collapsible="disabled"
          >
            <H1_FlexRow padding="10px 0 0 0" gap="10px" align="center">
              {appliedPalette?.map((paletteColor: PaletteColor) => (
                <Tooltip title={colorTooltip(paletteColor)} key={paletteColor.key}>
                  <ColorCircle
                    onClick={() => onSolidColorClick(paletteColor.key, index)}
                    $color={paletteColor.color}
                    $selected={
                      (gradientColor ? `color_${gradientColor}` : currentSolidColor?.key) ===
                      paletteColor.key
                    }
                  />
                </Tooltip>
              ))}
            </H1_FlexRow>
          </Collapse.Panel>
        </CustomCollapse>
      </H1_FlexColumn>
    );
  };

  return (
    <H1_FlexColumn
      padding={isCardMode ? "0" : "0 0 10px 0"}
      flex={isCardMode ? `0 0 ${width}` : "1 0 auto"}
      position="relative"
      gap="11px"
    >
      {!!previewUrls.length && (
        <VideoSettingsModal
          source="SceneDrawer"
          sceneIdx={selectedSceneIndex}
          initialVideoFit={initialVideoFit}
          visible={videoSettingsModalOpen}
          onClose={onCloseVideoSettingsModal}
          url={videoSettingsModalOpen ? previewUrls[0].url : ""}
          ratioX={currentLayoutMedia?.restrictions?.ratio_w as number}
          ratioH={currentLayoutMedia?.restrictions?.ratio_h as number}
          assetProperties={scene?.attributes?.[attributeType]?.[assetKey] as MediaAssetProperties}
          assetKey={assetKey}
          attributeType={attributeType}
          draftId={draftId}
          sceneId={sceneId}
        />
      )}
      <ConditionalRender condition={isTypeMedia}>
        <H1_FlexRow align="center" gap="10px">
          <BackgroundImageFlexRow
            $isPreviewVisible={isPreviewDisplayed}
            justify="center"
            align="center"
            height={height}
            flex={`0 0 ${width}`}
            overflow="hidden"
            onClick={onImageClick}
            position="relative"
          >
            <ConditionalRender condition={!isCardMode}>
              <DevModePopover
                value={{ id: media.key, url: mediaUrl || "{{URL}}" }}
                path={"scenes[#].media_elements[#]"}
                placement={"top"}
                top={"0"}
                left={"0"}
              />
            </ConditionalRender>
            <ConditionalRender condition={loading}>
              <WandLoader size="50%" />
            </ConditionalRender>
            <ConditionalRender condition={!loading}>
              <StyledPreview
                isFill
                autoplay
                previewUrls={previewUrls}
                $height={height}
                $width={width}
              />
            </ConditionalRender>
          </BackgroundImageFlexRow>
          <ConditionalRender condition={!isCardMode}>
            <UnderlineButton
              startContent={<H1_Icon icon="fa-regular fa-arrows-rotate" isCursorPointer />}
              variant="light"
              color="primary"
              size="sm"
              onClick={onImageClick}
            >
              {intl.formatMessage(sceneDrawerMessages.editMedia)}
            </UnderlineButton>
          </ConditionalRender>
          <ConditionalRender condition={!isCardMode}>
            <H1_FlexRow flex="1" gap="7px" align="center" justify="flex-end">
              <ConditionalRender condition={isImageFitDisplayed}>
                <Tooltip
                  title={intl.formatMessage(sceneDrawerMessages.adobeTooltip)}
                  placement="top"
                >
                  <H1_FlexRow>
                    <StyledIcon
                      variant="light"
                      isIconOnly
                      data-auto-id="image-fit-button"
                      onClick={onSettingsClick}
                      startContent={<H1_Icon icon="far fa-pencil" isCursorPointer />}
                    />
                  </H1_FlexRow>
                </Tooltip>
              </ConditionalRender>
              <ConditionalRender condition={!!isVideoEditDisplayed && !isCardMode}>
                <Tooltip
                  title={intl.formatMessage(sceneDrawerMessages.imageFitTooltip)}
                  placement="top"
                >
                  <H1_FlexRow data-auto-id="video-fit-button">
                    <StyledIcon
                      variant="light"
                      isIconOnly
                      data-auto-id="image-fit-button"
                      onClick={onVideoSettingsClick}
                      startContent={
                        <H1_Icon
                          isCursorPointer
                          color={theme.gray7}
                          size="11px"
                          icon="fal fa-sliders"
                        />
                      }
                    />
                  </H1_FlexRow>
                </Tooltip>
              </ConditionalRender>
              <ConditionalRender condition={!isCardMode}>
                <Tooltip
                  title={intl.formatMessage(sceneDrawerMessages.screenRecordingToolTip)}
                  placement="top"
                >
                  <>
                    <StyledScreenCaptureButton
                      icon={<StyledRecordIcon />}
                      sceneId={sceneId}
                      attributeType={attributeType}
                      assetKey={assetKey}
                      disabled={false}
                      hideText
                      variant="light"
                    />
                  </>
                </Tooltip>
              </ConditionalRender>
              <ConditionalRender condition={!!sceneTranscript && !isCardMode}>
                <Tooltip
                  title={intl.formatMessage(sceneDrawerMessages.mediaGenerationToolTip)}
                  placement="top"
                >
                  <>
                    <StyledIcon
                      variant="light"
                      isIconOnly
                      data-auto-id="image-wand-button"
                      isDisabled={
                        sceneAssetsGenerationStatus[
                          `${sceneId}-attributes.${attributeType}.${media.key}`
                        ] === fetchingStatus.loading
                      }
                      onClick={(e) => onClickGenerateMedia(e, media.key)}
                      startContent={
                        <H1_Icon
                          isCursorPointer
                          color={theme.gray7}
                          size="11px"
                          icon="fal fa-wand-magic-sparkles"
                        />
                      }
                    />
                  </>
                </Tooltip>
              </ConditionalRender>
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexRow>
      </ConditionalRender>

      {/* Solid type */}
      <ConditionalRender condition={isTypeSolid}>
        <H1_FlexRow align="center" gap="10px" flex="1 1 auto">
          <ConditionalRender condition={isCardMode}>
            <ColorFlexRow
              $color={currentSolidColor?.color}
              height={height}
              flex={`0 0 ${width}`}
              overflow="hidden"
              position="relative"
            />
          </ConditionalRender>
          <ConditionalRender condition={!isCardMode}>
            <Popover
              trigger={["click"]}
              placement="left"
              align={{ offset: [0, -50] }}
              showArrow={false}
              content={() => !isCardMode && colorsPopoverContent()}
            >
              <ColorFlexRow
                $color={currentSolidColor?.color}
                height={height}
                flex={`0 0 ${width}`}
                overflow="hidden"
                position="relative"
              >
                {/* Todo - add DevModePopover for solid color*/}
                {/*<DevModePopover*/}
                {/*  value={{ id: media.key, url: mediaUrl || "{{URL}}" }}*/}
                {/*  path={"scenes[#].media_elements[#]"}*/}
                {/*  placement={"top"}*/}
                {/*  top={"0"}*/}
                {/*  left={"0"}*/}
                {/*/>*/}
              </ColorFlexRow>
            </Popover>
          </ConditionalRender>
          <ConditionalRender condition={!isCardMode}>
            <Popover
              trigger={["click"]}
              placement="left"
              align={{ offset: [0, -50] }}
              showArrow={false}
              content={() => colorsPopoverContent()}
            >
              <UnderlineButton variant="light" color="primary" size="sm">
                {intl.formatMessage(sceneDrawerMessages.replaceColor)}
              </UnderlineButton>
            </Popover>
            <Popover
              trigger={["click"]}
              placement="left"
              align={{ offset: [0, -50] }}
              showArrow={false}
              content={() => colorsPopoverContent()}
            >
              <ColorDot
                margin="0 0 0 10px"
                flex="0 0 15px"
                width="15px"
                height="15px"
                $color={currentSolidColor?.color}
              />
            </Popover>
          </ConditionalRender>
        </H1_FlexRow>
      </ConditionalRender>
      {/* Gradient type */}
      {isTypeGradient && (
        <H1_FlexRow align="center" gap="10px">
          <GradientColorFlexRow
            $height={height}
            $fromColor={
              appliedPaletteDictionary[
                `color_${
                  (
                    getAttributeValueBySceneOrLayout(
                      scene,
                      currentLayoutMedia,
                      attributeType,
                      "gradient_colors"
                    ) as LayoutColor[]
                  )[0]
                }` as PaletteColorKey
              ] as string
            }
            $secondColor={
              appliedPaletteDictionary[
                `color_${
                  (
                    getAttributeValueBySceneOrLayout(
                      scene,
                      currentLayoutMedia,
                      attributeType,
                      "gradient_colors"
                    ) as LayoutColor[]
                  )[1]
                }` as PaletteColorKey
              ] as string
            }
            $thirdColor={
              (currentLayoutMedia.preset?.gradient_colors?.length || 0) > 2
                ? appliedPaletteDictionary[
                    `color_${
                      (
                        getAttributeValueBySceneOrLayout(
                          scene,
                          currentLayoutMedia,
                          attributeType,
                          "gradient_colors"
                        ) as LayoutColor[]
                      )[2]
                    }` as PaletteColorKey
                  ]
                : ""
            }
            $fourthColor={
              (currentLayoutMedia.preset?.gradient_colors?.length || 0) > 3
                ? appliedPaletteDictionary[
                    `color_${
                      (
                        getAttributeValueBySceneOrLayout(
                          scene,
                          currentLayoutMedia,
                          attributeType,
                          "gradient_colors"
                        ) as LayoutColor[]
                      )[3]
                    }` as PaletteColorKey
                  ]
                : ""
            }
            height={height}
            flex={`0 0 ${width}`}
            overflow="hidden"
            position="relative"
          />
          {!isCardMode &&
            (
              getAttributeValueBySceneOrLayout(
                scene,
                currentLayoutMedia,
                attributeType,
                "gradient_colors"
              ) as LayoutColor[]
            ).map((gradientColor: string, index: number) => (
              <Popover
                key={gradientColor}
                trigger={["click"]}
                placement="left"
                align={{ offset: [0, -50] }}
                showArrow={false}
                content={() => colorsPopoverContent(gradientColor, index)}
              >
                <ColorDot
                  margin="0 0 0 10px"
                  flex="0 0 15px"
                  width="15px"
                  height="15px"
                  $color={appliedPaletteDictionary[`color_${gradientColor}` as PaletteColorKey]}
                />
              </Popover>
            ))}
        </H1_FlexRow>
      )}
      <ConditionalRender condition={mediaTypeOptions.length > 1 && !isCardMode}>
        <H1_FlexRow align="center" gap="7px">
          <ConditionalRender condition={isMediaTypeOptionsMedia}>
            <Tooltip title={intl.formatMessage(sceneDrawerMessages.mediaColor)}>
              <IconContainer $selected={isTypeMedia} onClick={onChooseTypeMedia}>
                <H1_Icon color={theme.gray11} icon="fal fa-photo-film" size="11px" />
              </IconContainer>
            </Tooltip>
          </ConditionalRender>
          <ConditionalRender condition={isMediaTypeOptionsSolid}>
            <Tooltip title={intl.formatMessage(sceneDrawerMessages.solidColor)}>
              <IconContainer $selected={isTypeSolid} onClick={onChooseTypeSolid}>
                <H1_Icon color={theme.gray11} icon="fal fa-palette" size="11px" />
              </IconContainer>
            </Tooltip>
          </ConditionalRender>
          <ConditionalRender condition={isMediaTypeOptionsGradient}>
            <Tooltip title={intl.formatMessage(sceneDrawerMessages.gradientColor)}>
              <IconContainer $selected={isTypeGradient} onClick={onChooseTypeGradient}>
                <StyledGradientIcon color={theme.gray11} icon="fas fa-square" size="11px" />
              </IconContainer>
            </Tooltip>
          </ConditionalRender>
        </H1_FlexRow>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default SingleMediaSection;
