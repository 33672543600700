import { useMediaDeviceSelect } from "@livekit/components-react";
import { useEffect, useMemo, useState } from "react";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { Button } from "@nextui-org/react";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

interface DeviceSelectorProps {
  kind: MediaDeviceKind;
}

const DeviceSelector = ({ kind }: DeviceSelectorProps) => {
  const deviceSelect = useMediaDeviceSelect({ kind: kind });
  const [selectedDeviceName, setSelectedDeviceName] = useState("");

  useEffect(() => {
    deviceSelect.devices.forEach((device) => {
      if (device.deviceId === deviceSelect.activeDeviceId) {
        setSelectedDeviceName(device.label);
      }
    });
  }, [deviceSelect.activeDeviceId, deviceSelect.devices, selectedDeviceName]);

  const onClickDevice = (device: MediaDeviceInfo) => {
    deviceSelect.setActiveMediaDevice(device.deviceId);
  };

  const items = useMemo(() => {
    return deviceSelect.devices.map((device: MediaDeviceInfo, index) => ({
      label: <H1_TextSmall>{device.label}</H1_TextSmall>,
      key: index,
      onClick: () => onClickDevice(device)
    }));
  }, [deviceSelect.devices]);

  return (
    <NextDropdown items={items} withWhiteBackground>
      <Button
        variant="light"
        size="sm"
        endContent={<H1_Icon icon="far fa-chevron-down" color="white" />}
        isIconOnly
      ></Button>
    </NextDropdown>
  );
};

export default DeviceSelector;
